"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ICON_SIZES = void 0;
exports.ICON_SIZES = {
    xs: 12,
    sm: 18,
    md: 24,
    lg: 32,
    xl: 64,
};
