import styled from "styled-components";
import {space} from "@scriba/ui-lib";

export const ColumnMarginRight = styled.span`
  display: flex;      
  flex-direction: column;
  margin-right:${space('xxl')}px;
`;

export const Column = styled.span`
  display: flex;      
  flex-direction: column;
`;