import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserDetailsFragmentFragment } from "../generated/graphql";
import type { User } from "@scriba/common";

declare global {
  interface Window {
    pendo: pendo.Pendo;
  }
}

type InitPendoParams = UserDetailsFragmentFragment & {
  language: string;
};

const initPendo = ({ id, email, firstName, lastName, language }: InitPendoParams) => {
  window.pendo.initialize({
    visitor: {
      id, // Required if user is logged in
      email, // Recommended if using Pendo Feedback, or NPS Email
      full_name: `${firstName} ${lastName}`, // Recommended if using Pendo Feedback
      language,
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: process.env.NODE_ENV, // Required if using Pendo Feedback
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });
};

export const usePendo = ({ authenticated, user }: { authenticated: boolean; user?: User }) => {
  const pendo = window.pendo;
  const [isPendoInitialized, setIsPendoInitialized] = useState(false);
  const { i18n, ready } = useTranslation();

  useEffect(() => {
    if (!isPendoInitialized && authenticated && user && ready) {
      const { language } = i18n;
      initPendo({ ...user, language });
      setIsPendoInitialized(true);
    }
  }, [authenticated, user, isPendoInitialized, ready, i18n]);

  return {
    isPendoInitialized,
    pendo,
  };
};
