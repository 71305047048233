"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncomePeriodicity = exports.IncomeType = void 0;
var IncomeType;
(function (IncomeType) {
    IncomeType["professional"] = "professional";
    IncomeType["rent"] = "rent";
    IncomeType["financial"] = "financial";
    IncomeType["allowances"] = "allowances";
    IncomeType["other"] = "other";
})(IncomeType = exports.IncomeType || (exports.IncomeType = {}));
var IncomePeriodicity;
(function (IncomePeriodicity) {
    IncomePeriodicity["month"] = "month";
    IncomePeriodicity["year"] = "year";
})(IncomePeriodicity = exports.IncomePeriodicity || (exports.IncomePeriodicity = {}));
