import React, { useContext, createContext, useState } from "react";
import {useEffect} from "react";

import {StocksDicType, getStocksValues} from "@scriba/common";

interface StocksContextType {
  loading: boolean,
  stocks?: StocksDicType,
}

const stockContext = createContext<StocksContextType>({
  loading: true,
});

export const StocksProvider: React.FunctionComponent = ({ children }) => {
  const [stocks, setStocks] = useState<StocksDicType>();
  useEffect(() => {
    // Create an scoped async function in the hook
    async function anyNameFunction() {
      const res = await getStocksValues();
      setStocks(res) ;
    }
    // Execute the created function directly
    anyNameFunction();
  }, []);

  const value = stocks !== undefined ? {loading: true, stocks: stocks}  : {loading: false}
  return (
    <stockContext.Provider value={value}>
      {children}
    </stockContext.Provider>
  );
}

export function useStocks() {
  return useContext(stockContext);
}
