import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import { sum } from "lodash";
import styled from "styled-components";

import {Button, Card, Title, space, ProgressBar} from "@scriba/ui-lib";

import {objectiveIconName} from "../services/objectives";
import {ObjectiveDetailsFragmentFragment} from "../generated/graphql";
import { formatMonetaryAmount } from "../services/format";

type MyObjectiveCardProps = {
  objective: ObjectiveDetailsFragmentFragment;
}

const ProgressWrapper = styled('div')`
  padding-top: ${space('md')}px;
`;

const CardContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
`

const CardTitle = styled(Title)`
  margin-bottom: ${space('lg')}px;
`

const MainTitle = styled(Title)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
`;

const MainSubTitle = styled(Title)`
  padding-top: ${space('md')}px;
`;

const MyObjectiveCard = ({objective}: MyObjectiveCardProps) => {
  const {t} = useTranslation('objectives');
  const history = useHistory();
  const currentAmount = objective.futureAvailableCash + sum(objective.scenarios?.map(s => s.amount));
  const fees = objective.taxesPerc || 0
  const totalAmount = objective.amount*(fees + 1);
  const loanAmount = objective.amount*(1-objective.ownFundsPercentage);
  const objectivePercentage = Math.min(Math.floor((currentAmount/(totalAmount - loanAmount))*100), 100);


  if (! objective.subType) return null;
  return (
    <Card
      onClick={() => history.push(`/objectives/${objective.id}/scenarios`)}
      footer={<Button label={t('objective.card.edit.label')} />}
      iconName={objectiveIconName({subType: objective.subType})}
    >
      <CardContent>
        <CardTitle title={t(`objective.card.title.${objective.subType}`)} level={6}/>
        <div>
          <MainTitle color="main" level={1} title={objective.name}/>
          <ProgressWrapper>
            <MainSubTitle title={formatMonetaryAmount(objective.amount, objective.currency.code)} level={4}/>
            <p>{t('objective.card.objectivePercentage', {percentage: objectivePercentage})}</p>
            <ProgressBar percentage={objectivePercentage} />
          </ProgressWrapper>
        </div>
      </CardContent>
    </Card>
  )
}

export default MyObjectiveCard;
