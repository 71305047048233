import React, {FunctionComponent} from 'react';
import {useHistory} from 'react-router-dom';

import SignUpForm from "../components/forms/SignUpForm";
import { BigBox, Container ,SmallBox, StyledLogo, } from './LoginPage';

export const SignUpPage:FunctionComponent = () => {
  const history = useHistory();

  return (
    <Container>
      <BigBox>
        <StyledLogo />
      </BigBox>
      <SmallBox>
        <SignUpForm onSignUp={async () => history.replace('/')} />
      </SmallBox>
    </Container>
  )
}
