import { FunctionComponent } from "react";
import styled from "styled-components";

import {getGridSize} from "@scriba/ui-lib";

const StyledLogoImage = styled.img`
  display: block;
  box-sizing: border-box;
  padding: ${getGridSize(4)};
  width: 100%;
  height: auto;
`
export type LogoProps = {
  className?: string;
}
const Logo: FunctionComponent<LogoProps> = ({className}) => (
  <StyledLogoImage className={className} src={process.env.PUBLIC_URL + '/full-logo.png'} />
)


export default Logo;
