import {FunctionComponent} from 'react';
import styled from 'styled-components';

import {Title} from "@scriba/ui-lib";

import {useTranslation} from "react-i18next";
import {AssetsList} from "../components/AssetsList";
import { Section } from '../components/Section';
import { MovementsList } from '../components/MovementsList';

const Page = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AssetsListPage: FunctionComponent = () => {
  const {t} = useTranslation(['assets', 'movements', 'common']);
  return (
    <Page>
      <Section>
        <Title uppercase level={2} title={t("assets:other.list.title")} />
        <AssetsList />
      </Section>
      <Section>
        <Title uppercase level={2} title={t("movements:other.list.title")} />
        <MovementsList />
      </Section>
    </Page>
  );
}
