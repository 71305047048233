"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colorMaps = void 0;
exports.colorMaps = {
    teal: {
        lightest: '#c9fff5',
        lighter: '#94e2d4',
        light: '#66c5b4',
        base: '#47a896',
        dark: '#2d8b7a',
        darker: '#125146',
        darkest: '#041714',
    },
    charcoal: {
        lightest: '#607080',
        lighter: '#556474',
        light: '#4a5968',
        base: '#414e5c',
        dark: '#374450',
        darker: '#263039',
        darkest: '#161c21',
    },
    grey: {
        lightest: '#e6e6e6',
        lighter: '#d9d9d9',
        light: '#ccc',
        base: '#bfbfbf',
        dark: '#b3b3b3',
        darker: '#999',
        darkest: '#808080',
    },
};
