import type { FunctionComponent, CSSProperties } from "react";
import type { TooltipProps } from "recharts/types/component/Tooltip";
import type { ValueType, NameType } from "recharts/types/component/DefaultTooltipContent";
import { isValidElement } from "react";
import { isArray, isString, isNumber, isNaN, isNil } from "lodash";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colorMaps } from "@scriba/ui-lib/dist/themes/colors/colorMaps";
import { space } from "@scriba/ui-lib";

const TooltipFooter = styled("p")`
  padding-top: ${space("md")}px;
  font-weight: 600;
`;

/*
 * Kind of duplicated Rechart's DefaultTooltipContent and utility functions
 * because this component is not exported from the package ¯\_(ツ)_/¯
 */

function defaultFormatter<T extends ValueType>(value: T): T {
  return value;
}

function isNumOrStr(value: any) {
  return isString(value) || (isNumber(value) && !isNaN(value));
}

// converts a Recharts `ValueType` type to a number
function getValueNumber(value: ValueType): number {
  if (isString(value)) {
    return parseInt(value, 10);
  }
  if (isNumber(value) && !isNaN(value)) {
    return value;
  }
  if (isArray(value)) {
    return value.map((item) => getValueNumber(item)).reduce(sumItems, 0);
  }
  return 0;
}

function sumItems(total: number, currentValue: ValueType) {
  const value = getValueNumber(currentValue);
  return total + value;
}

export const Tooltip: FunctionComponent<Omit<TooltipProps<ValueType, NameType>, "itemSorter">> = ({
  payload,
  formatter,
  active,
  separator,
  itemStyle,
  // itemSorter, // disabled for now, might reimpliment it if needed
  wrapperClassName,
  labelClassName,
  label,
  labelFormatter,
  labelStyle,
  contentStyle,
}) => {
  const { t } = useTranslation("home");
  if (!active || !payload) {
    return null;
  }
  const wrapperCN = ["recharts-default-tooltip", wrapperClassName].join(" ");
  const labelCN = ["recharts-tooltip-label", labelClassName].join(" ");
  const hasLabel = !isNil(label);
  let finalLabel = hasLabel ? label : "";

  if (hasLabel && labelFormatter) {
    finalLabel = labelFormatter(label, payload);
  }

  const finalStyle: CSSProperties = {
    margin: 0,
    padding: 10,
    backgroundColor: colorMaps.charcoal.base,
    border: "1px solid #ccc",
    whiteSpace: "nowrap",
    borderRadius: 20,
    ...contentStyle,
  };

  const finalLabelStyle = {
    margin: 0,
    ...labelStyle,
  };

  const total = payload.map((item) => item.value ?? 0).reduce(sumItems, 0);
  const formattedTotal = formatter ? formatter(total)[0] : defaultFormatter(total);

  const renderContent = () => {
    if (payload && payload.length) {
      const listStyle = { padding: 0, margin: 0 };

      const items = payload.map((entry, i) => {
        if (entry.type === "none") {
          return null;
        }

        const finalItemStyle = {
          display: "block",
          paddingTop: 4,
          paddingBottom: 4,
          color: entry.color || "#000",
          ...itemStyle,
        };
        const finalFormatter = entry.formatter || formatter || defaultFormatter;
        let { name, value } = entry;
        if (finalFormatter) {
          const formatted = finalFormatter(value, name, entry, i, payload);
          if (Array.isArray(formatted)) {
            [value, name] = formatted;
          } else {
            value = formatted;
          }
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <li className="recharts-tooltip-item" key={`tooltip-item-${i}`} style={finalItemStyle}>
            {isNumOrStr(name) ? <span className="recharts-tooltip-item-name">{name}</span> : null}
            {isNumOrStr(name) ? <span className="recharts-tooltip-item-separator">{separator}</span> : null}
            <span className="recharts-tooltip-item-value">{value}</span>
            <span className="recharts-tooltip-item-unit">{entry.unit || ""}</span>
          </li>
        );
      });

      return (
        <ul className="recharts-tooltip-item-list" style={listStyle}>
          {items}
        </ul>
      );
    }

    return null;
  };

  return (
    <div className={wrapperCN} style={finalStyle}>
      <p className={labelCN} style={finalLabelStyle}>
        {isValidElement(finalLabel) ? finalLabel : `${finalLabel}`}
      </p>
      {renderContent()}
      <TooltipFooter className={labelCN} style={finalLabelStyle}>
        {t("chart.tooltip.footer.label", { amount: formattedTotal })}
      </TooltipFooter>
    </div>
  );
};
