import { ReactElement } from "react";
import styled from "styled-components";
import {getGridSize} from "@scriba/ui-lib";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
`;

const ButtonContainer = styled('div')`
  min-height: ${getGridSize(8)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`


type FieldsAndButtonProps = {
  fields: ReactElement;
  button: ReactElement;
}

const FieldsAndButton = ({fields, button}: FieldsAndButtonProps) => {

  return (
    <Container>
      {fields}

      <ButtonContainer>
        {button}
      </ButtonContainer>
    </Container>
  )
}

export default FieldsAndButton;
