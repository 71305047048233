import React, {ReactNode} from 'react';

import {Button, Dialog} from '@scriba/ui-lib';
import {useTranslation} from "react-i18next";

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  title: string;
  content: ReactNode;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
}

interface ConfirmDialogProps extends ConfirmationOptions{
  dismiss: () => void;
  proceed: () => void;
  show: boolean;
}

export function ConfirmDialog({show, proceed, dismiss, content, confirmButtonLabel, cancelButtonLabel, title}: ConfirmDialogProps) {
  const {t} = useTranslation('common');
  return (
    <Dialog
      show={show}
      dismiss={dismiss}
      title={title}
      content={content}
      buttons={<>
        <Button onClick={() => proceed()} label={confirmButtonLabel || t('modal.confirm.button.ok')} />
        <Button onClick={() => dismiss()} label={cancelButtonLabel || t('modal.confirm.button.cancel')} appearance='outline' />
      </>}
      icon="error"
    />
  )
}

export default ConfirmDialog;
