"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Normalizer = void 0;
var styled_components_1 = require("styled-components");
exports.Normalizer = styled_components_1.createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  body {\n    &,\n    & *,\n    & *::before,\n    & *::after {\n      box-sizing: border-box;\n    }\n  }\n"], ["\n  body {\n    &,\n    & *,\n    & *::before,\n    & *::after {\n      box-sizing: border-box;\n    }\n  }\n"])));
var templateObject_1;
