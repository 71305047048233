import React, {FunctionComponent} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

import {Title} from "@scriba/ui-lib";

import OtherAssetInputForm from "../components/forms/OtherAssetInputForm";
import others from "../queries/assets/others";
import assets from "../queries/assets";
import {
  GetAssetQuery,
  GetAssetQueryVariables,
  UpdateOtherAssetMutation,
  UpdateOtherAssetMutationVariables
} from "../generated/graphql";

export const EditOtherAssetPage: FunctionComponent = () => {
  const {t} = useTranslation(['assets', 'common']);
  const history = useHistory();
  let { assetId } = useParams<{assetId: string}>();
  const { data } = useQuery<GetAssetQuery, GetAssetQueryVariables>(assets.get, {variables: { id: assetId }});

  const [updateOtherAsset] = useMutation<UpdateOtherAssetMutation, UpdateOtherAssetMutationVariables>(
    others.update,
    {
      onError: (err) => {
        console.error(err);
        toast.error(t('assets:edit.error.toast'));
      }
    }
  )

  if (!data?.asset) return null;
  const asset = data.asset;

  return (
    <>
      <Title uppercase title={t('assets:other.edit.page.title')} level={3} />
      <OtherAssetInputForm
        asset={asset}
        onSubmit={async ({loans, ...asset}) => {
          const res = await updateOtherAsset({
            variables: {
              input: {
                ...asset,
                id: assetId,
              }
            }
          });
          if(!res.data || !res.data.updateOtherAsset.data) {
            toast.error(t('assets:edit.error.toast'));
          } else {
            toast.success(t('assets:edit.success.toast'));
            history.push('/');
          }
        }}
      />
    </>
  )
}
