import React, {FunctionComponent} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import styled from "styled-components";

import Logo from "../components/Logo";

import LoginForm from "../components/forms/LoginForm";

type LoginState = {
  from?: string;
}

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

export const BigBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 3;
`

export const SmallBox = styled.div`
  flex: 1;
  padding-right: 20%;
`

export const StyledLogo = styled(Logo)`
  width: min(320px, 38vw);
`;

export const LoginPage:FunctionComponent = () => {
  const { state } = useLocation<LoginState>();
  const [ redirect, setRedirect ] = React.useState(false)

  if (redirect) {
    return <Redirect to={state?.from || '/'} />
  }

  return (
    <Container>
      <BigBox>
        <StyledLogo/>
      </BigBox>
      <SmallBox>
        <LoginForm onLogin={async () => setRedirect(true)} />
      </SmallBox>
    </Container>
  )
}
