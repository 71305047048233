import styled from "styled-components";
import { space } from "@scriba/ui-lib";

export const TableWrapper = styled("div")`
  padding-top: ${space("md")}px;
  padding-bottom: ${space("md")}px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
