import { useMutation, useQuery } from "@apollo/client";
import { computeNetGain, computeSellingPrice, computeSRD, monthsBetween, ScenarioType } from "@scriba/common";
import { Button, Fieldset, InputRow, Sheet, Title } from "@scriba/ui-lib";
import Decimal from "decimal.js-light";
import { pmt } from 'financial';
import React, { FunctionComponent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import DecimalInputField from "../components/fields/DecimalInputField";
import MonetaryAmountInputField from "../components/fields/MonetaryAmountInputField";
import PercentageInputField from "../components/fields/PercentageInputField";
import FieldsAndButton from "../components/FieldsAndButton";
import Group, { GroupDiv } from "../components/Group";
import HighlightedElement from "../components/HighlightedElement";
import HighlightedText from "../components/HighlightedText";
import {ColumnMarginRight} from "../components/Column";
import HighlightedTextBorder from "../components/HighlightedTextBorder";
import { ObjectiveOverviewBar } from "../components/ObjectiveOverviewBar";
import OneTextTwoNumbers from "../components/OneTextTwoNumbers";
import {HelpTooltip} from "@scriba/ui-lib/dist/components/HelpTooltip";
import {
  BuyRealEstateScenarioFragmentFragment, GetObjectiveQuery,
  GetObjectiveQueryVariables,
  GetScenarioQuery,
  GetScenarioQueryVariables, UpdateScenarioMutation, UpdateScenarioMutationVariables
} from "../generated/graphql";
import objective from "../queries/objective";
import scenarios from "../queries/scenarios";
import { formatMonetaryAmount } from "../services/format";

type BuyRealEstateInputs = {
  investedOwnFunds: number,
  ownFundsPerc: number,
  taxesPerc: number,
  rentingRentability: number,
  occupationRate: number,
  loanDuration: number,
  interestRate: number,
  precompte: number,
  agencyFees: number,
  upkeep: number,
  otherFees: number,
  evolutionRate: number,
  sellTaxes: number,
  agencySellFees: number,
}

const GridWrapper = styled.div`
    display: grid;
    width: 55%;
    grid-template-columns: 22% 28% 22% 28%;
    align-items: center
  `;

const Item = styled.div`
    text-align: left;
  `;

export const ObjectiveScenariosBuyRealEstatePage: FunctionComponent = () => {
  const {t} = useTranslation('objectives');
  const history = useHistory();
  let { scenarioId, objectiveId } = useParams<{scenarioId: string, objectiveId: string}>();
  const { data } = useQuery<GetScenarioQuery, GetScenarioQueryVariables>(scenarios.get, {variables: { id: scenarioId }});
  const { data: objectiveData } = useQuery<GetObjectiveQuery, GetObjectiveQueryVariables>(objective.get, {variables: { id: objectiveId }});
  const [updateScenario] = useMutation<UpdateScenarioMutation, UpdateScenarioMutationVariables>(
    scenarios.update,
    {
      onError: (error) => {
        console.error(error)
        toast.error(t('objectives:scenario.update.error.toast'));
      },
    }
  );

  const currency = {code: 'EUR', rateToEuro: 1}; //TODO to replace with a picker when we handle multiple currencies

  const defaultInvestedOwnFunds = 0;
  const defaultOwnFundsPerc = 0.2;
  const defaultTaxesPerc = 0.15;
  const defaultRentingRentability = 0.02;
  const defaultOccupationRate = 0.9;
  const defaultLoanDuration = 20;
  const defaultInterestRate = 0;
  const defaultPrecompte = 0;
  const defaultAgencyFees = 0;
  const defaultUpkeep = 0;
  const defaultOtherFees = 0;
  const defaultEvolutionRate = 0.025;
  const defaultSellTaxes = 0;
  const defaultAgencySellFees = 0.03;

  const formMethods = useForm<BuyRealEstateInputs>({
    defaultValues: {
      investedOwnFunds: objectiveData?.objective?.futureAvailableCash || defaultInvestedOwnFunds,
      ownFundsPerc: defaultOwnFundsPerc,
      taxesPerc: defaultTaxesPerc,
      rentingRentability: defaultRentingRentability,
      occupationRate: defaultOccupationRate,
      loanDuration: defaultLoanDuration,
      interestRate: defaultInterestRate,
      precompte: defaultPrecompte,
      agencyFees: defaultAgencyFees,
      upkeep: defaultUpkeep,
      otherFees: defaultOtherFees,
      evolutionRate: defaultEvolutionRate,
      sellTaxes: defaultSellTaxes,
      agencySellFees: defaultAgencySellFees,
    }
  });

  const watchInvestedOwnFunds = formMethods.watch('investedOwnFunds');
  const watchOwnFundsPerc = formMethods.watch('ownFundsPerc');
  const watchTaxesPerc = formMethods.watch('taxesPerc');
  const watchRentingRentability = formMethods.watch('rentingRentability');
  const watchOccupationRate = formMethods.watch('occupationRate');
  const watchLoanDuration = formMethods.watch('loanDuration');
  const watchInterestRate = formMethods.watch('interestRate');
  const watchPrecompte = formMethods.watch('precompte');
  const watchAgencyFees = formMethods.watch('agencyFees');
  const watchUpkeep = formMethods.watch('upkeep');
  const watchOtherFees = formMethods.watch('otherFees');
  const watchEvolutionRate = formMethods.watch('evolutionRate');
  const watchAgencySellFees = formMethods.watch('agencySellFees');
  const watchSellTaxes = formMethods.watch('sellTaxes');

  const {reset, getValues} = formMethods;
  useEffect(() => {
    if (data?.scenario?.scenarioType !== ScenarioType.buyRealEstate)
      return;
    if (!data?.scenario) return; // loading
    if (!objectiveData?.objective) return; // loading
    const scen = data.scenario as BuyRealEstateScenarioFragmentFragment;
    reset({
      ...getValues(),
      investedOwnFunds: scen.investedOwnFunds || objectiveData.objective.futureAvailableCash || defaultInvestedOwnFunds,
      ownFundsPerc: scen.ownFundsPerc || defaultOwnFundsPerc,
      taxesPerc: scen.taxesPerc || defaultTaxesPerc,
      rentingRentability: scen.rentingRentability || defaultRentingRentability,
      occupationRate: scen.occupationRate || defaultOccupationRate,
      loanDuration: scen.loanDuration || defaultLoanDuration,
      interestRate: scen.interestRate || defaultInterestRate,
      precompte: scen.precompte || defaultPrecompte,
      agencyFees: scen.agencyFees || defaultAgencyFees,
      upkeep: scen.upkeep || defaultUpkeep,
      otherFees: scen.otherFees || defaultOtherFees,
      evolutionRate: scen.evolutionRate || defaultEvolutionRate,
      sellTaxes: scen.sellTaxes || defaultSellTaxes,
      agencySellFees: scen.agencySellFees || defaultAgencySellFees,
    });
  }, [reset, getValues, data?.scenario, objectiveData?.objective]);

  if (!data?.scenario) return null;
  if (!objectiveData?.objective) return null;
  const obj = objectiveData.objective;
  const objectiveDelay = monthsBetween(new Date(), obj.objectiveDate);

  const priceExclTax = watchOwnFundsPerc ? watchInvestedOwnFunds / (watchOwnFundsPerc + watchTaxesPerc) : 0;
  const priceInclTax = priceExclTax * (1 + watchTaxesPerc);
  const rent = priceExclTax * watchRentingRentability / 12;
  const monthly = watchLoanDuration ? -pmt(watchInterestRate/12, watchLoanDuration*12, priceInclTax-watchInvestedOwnFunds) : priceInclTax-watchInvestedOwnFunds;

  const fpi = watchInvestedOwnFunds;
  const va = priceExclTax;
  const vaTTC = priceInclTax;
  const tloc = watchOccupationRate;
  const lmens = rent;
  const precompte = watchPrecompte;
  const agence = watchAgencyFees
  const entretien = watchUpkeep;
  const autresFrais = watchOtherFees;
  const varE = watchEvolutionRate;
  const fav = watchAgencySellFees;
  const tr = watchSellTaxes;
  const varI = watchInterestRate;
  const mens = monthly;
  const varN = objectiveDelay;

  const pv = computeSellingPrice(va, varE, varN)
  const sellingPrice = pv;
  const soldeRestantDu = computeSRD(mens, priceInclTax-fpi, new Decimal((1+ varI) ** (1/12) - 1), varN).toNumber();
  const plusValueNette = computeNetGain(fpi, pv, fav, soldeRestantDu, precompte, agence, entretien, autresFrais, varN, mens, lmens, tloc, tr, vaTTC);

  const text1 = (
    <span>
      {t('scenario.buyRealEstate.page.ableToBuy')}
    </span>
  );
  const number1 = (
    <>
      <HighlightedElement>
        <HighlightedText>
          {formatMonetaryAmount(priceInclTax, obj.currency.code)}
        </HighlightedText>
      </HighlightedElement>

      <span>
        {t('scenario.buyRealEstate.page.inclTaxes')}
      </span>
    </>
  );
  const number2 =(
    <>
      <HighlightedElement>
        <HighlightedText>
          {formatMonetaryAmount(priceExclTax, obj.currency.code)}
        </HighlightedText>
      </HighlightedElement>

      <span>
        {t('scenario.buyRealEstate.page.exclTaxes')}
      </span>
    </>
  )

  const group1 = (
    <>
      <Title title={t('scenario.buyRealEstate.page.subtitle')} level={4} />

      <GroupDiv>
        {t('scenario.buyRealEstate.page.amountOf')}
        <HighlightedElement big>
          <MonetaryAmountInputField
            currency={currency}
            name="investedOwnFunds"
            label={""}
            formMethods={formMethods}
            required={true}
            inline={true}
          />
        </HighlightedElement>
        {t('scenario.buyRealEstate.page.investedOwnFunds')}
      </GroupDiv>

      <GroupDiv>
        {t('scenario.buyRealEstate.page.ownFundsPercOf')}
        <HighlightedElement>
          <PercentageInputField
            name="ownFundsPerc"
            label={""}
            formMethods={formMethods}
            required={true}
            inline={true}
          />
        </HighlightedElement>

        <HelpTooltip text={t('scenario.buyRealEstate.page.ownFundsPercOf.tooltip')}/>

        {t('scenario.buyRealEstate.page.and')}

        <HighlightedElement>
          <PercentageInputField
            name="taxesPerc"
            label={""}
            formMethods={formMethods}
            required={true}
            inline={true}
          />
        </HighlightedElement>

        {t('scenario.buyRealEstate.page.ofTaxes')}
        <HelpTooltip text={t('scenario.buyRealEstate.page.ofTaxes.tooltip')}/>

      </GroupDiv>

      <GroupDiv>
        <OneTextTwoNumbers text1={text1} number1={number1} number2={number2}/>
      </GroupDiv>
    </>
  );

  const group2 = (
    <>
     <GroupDiv>
      {t('scenario.buyRealEstate.page.rentingRentability')}

      <HighlightedElement>
       <PercentageInputField
         name="rentingRentability"
         label={""}
         formMethods={formMethods}
         required={true}
         inline={true}
       />
      </HighlightedElement>
      <HelpTooltip text={t('scenario.buyRealEstate.page.rentingRentability.tooltip')}/>

      {t('scenario.buyRealEstate.page.occupationRate')}

      <HighlightedElement>
        <PercentageInputField
          name="occupationRate"
          label={""}
          required={true}
          inline={true}
          formMethods={formMethods}
        />
      </HighlightedElement>
      <HelpTooltip text={t('scenario.buyRealEstate.page.occupationRate.tooltip')}/>

     </GroupDiv>

     <GroupDiv>
      {t('scenario.buyRealEstate.page.realEstateLikeThis')}
        <HighlightedText>
          {formatMonetaryAmount(rent, obj.currency.code)}
        </HighlightedText>
      {t('scenario.buyRealEstate.page.perMonth')}.
     </GroupDiv>
    </>
  );

  const group3 = (
    <GroupDiv>
      {t('scenario.buyRealEstate.page.monthly')}

      <HighlightedElement>
        <PercentageInputField
          name="interestRate"
          label={""}
          formMethods={formMethods}
          inline={true}
          required={true}
        />
      </HighlightedElement>

      {t('scenario.buyRealEstate.page.on')}

      <HighlightedElement>
        <DecimalInputField
          decimalPlaces={0}
          name="loanDuration"
          label={""}
          inline={true}
          required={true}
          formMethods={formMethods}
        />
      </HighlightedElement>

      {t('scenario.buyRealEstate.page.reimburse')}

      <HighlightedText>
        {formatMonetaryAmount(monthly, obj.currency.code)}
      </HighlightedText>

      {t('scenario.buyRealEstate.page.perMonth')}.
    </GroupDiv>
  );


  const group4 = (
    <>
      <span> {t('scenario.buyRealEstate.page.fees')} </span>

      <GridWrapper>
        <Item>
          <div>
            {t('scenario.buyRealEstate.page.precompte')}
            <HelpTooltip text={t('scenario.buyRealEstate.page.precompte.tooltip')}/>
          </div>
        </Item>

        <Item>
          <HighlightedElement>
            <MonetaryAmountInputField
              currency={currency}
              name="precompte"
              label={""}
              inline={true}
              required={true}
              formMethods={formMethods}
            />
          </HighlightedElement>
        </Item>

        <Item>
          <div>
            {t('scenario.buyRealEstate.page.agencyFees')}
            <HelpTooltip text={t('scenario.buyRealEstate.page.agencyFees.tooltip')}/>
          </div>
        </Item>

        <Item>
          <HighlightedElement>
            <MonetaryAmountInputField
              currency={currency}
              name="agencyFees"
              label={""}
              inline={true}
              required={true}
              formMethods={formMethods}
            />
          </HighlightedElement>
        </Item>

        <Item>
          {t('scenario.buyRealEstate.page.upkeep')}
          <HelpTooltip text={t('scenario.buyRealEstate.page.upkeep.tooltip')}/>
        </Item>

        <Item>
        <HighlightedElement>
          <MonetaryAmountInputField
            currency={currency}
            name="upkeep"
            label={""}
            inline={true}
            required={true}
            formMethods={formMethods}
          />
        </HighlightedElement>
        </Item>

        <Item>
          {t('scenario.buyRealEstate.page.otherFees')}
          <HelpTooltip text={t('scenario.buyRealEstate.page.otherFees.tooltip')}/>
        </Item>

        <Item>
          <HighlightedElement>
            <MonetaryAmountInputField
              currency={currency}
              name="otherFees"
              label={""}
              inline={true}
              required={true}
              formMethods={formMethods}
            />
          </HighlightedElement>
        </Item>
      </GridWrapper>
    </>
  );

  const group5 = (
    <GroupDiv>
      {t('scenario.buyRealEstate.page.evolutionRate')}
      <HighlightedElement>
        <PercentageInputField
          name="evolutionRate"
          label={""}
          formMethods={formMethods}
          required={true}
          inline={true}
        />
      </HighlightedElement>
      {t('scenario.buyRealEstate.page.sellTaxes')}
      <HighlightedElement>
        <PercentageInputField
          name="sellTaxes"
          label={""}
          formMethods={formMethods}
          required={true}
          inline={true}
        />
      </HighlightedElement>
      <HelpTooltip text={t('scenario.buyRealEstate.page.sellTaxes.tooltip')}/>
      {t('scenario.buyRealEstate.page.agencySellFees')}
      <HighlightedElement>
        <PercentageInputField
          name="agencySellFees"
          label={""}
          formMethods={formMethods}
          required={true}
          inline={true}
        />
      </HighlightedElement>
    </GroupDiv>
  );

  const group6 = (
    <GroupDiv>
      <ColumnMarginRight>
        <Fieldset>
          <GroupDiv>
            {t('scenario.buyRealEstate.page.sellingPrice')}
          </GroupDiv>
          <GroupDiv>
            {t('scenario.buyRealEstate.page.remainingBalance')}
            <HelpTooltip text={t('scenario.buyRealEstate.page.remainingBalance.tooltip')}/>
          </GroupDiv>
          <GroupDiv>
            {t('scenario.buyRealEstate.page.netCapitalGain')}
          </GroupDiv>
        </Fieldset>
      </ColumnMarginRight>

      <ColumnMarginRight>
        <Fieldset>
          <HighlightedElement>
              <HighlightedText>
                {formatMonetaryAmount(sellingPrice, obj.currency.code)}
              </HighlightedText>
          </HighlightedElement>
          <HighlightedElement>
              <HighlightedText>
                {formatMonetaryAmount(soldeRestantDu, obj.currency.code)}
              </HighlightedText>
          </HighlightedElement>
          <HighlightedElement>
              <HighlightedTextBorder>
                {formatMonetaryAmount(plusValueNette, obj.currency.code)}
              </HighlightedTextBorder>
          </HighlightedElement>
        </Fieldset>
      </ColumnMarginRight>
    </GroupDiv>
  );

  const fields = (
    <Fieldset>
      <Group>
        <Fieldset>
          {group1}
        </Fieldset>
      </Group>
      <Group>
        <Fieldset>
          {group2}
        </Fieldset>
      </Group>
      <Group>
        <Fieldset>
          {group3}
        </Fieldset>
      </Group>
      <Group>
        <Fieldset>
          {group4}
        </Fieldset>
      </Group>
      <Group>
        <Fieldset>
          {group5}
        </Fieldset>
      </Group>
      <Group>
        <Fieldset>
          {group6}
        </Fieldset>
      </Group>
    </Fieldset>
  );

  const bottomButton = (
        <InputRow
          label={t('common:other.requiredFields')}
          required={false}
          renderInput={() => (
            <Button type="submit" label={t('objectives:scenario.button.save.label')}/>
          )}
        />
  );

  return (
    <form onSubmit={formMethods.handleSubmit(async(values) => {
          await updateScenario({variables: {input: {id: scenarioId,
            amount: plusValueNette,
            investedOwnFunds: values.investedOwnFunds,
            ownFundsPerc: values.ownFundsPerc,
            taxesPerc: values.taxesPerc,
            rentingRentability: values.rentingRentability,
            occupationRate: values.occupationRate,
            loanDuration: values.loanDuration,
            interestRate: values.interestRate,
            precompte: values.precompte,
            agencyFees: values.agencyFees,
            upkeep: values.upkeep,
            otherFees: values.otherFees,
            evolutionRate: values.evolutionRate,
            sellTaxes: values.sellTaxes,
            agencySellFees: values.agencySellFees}}});
          history.push(`/objectives/${objectiveId}/scenarios`);
        })}>
      <Fieldset>
        <Title title={t('scenario.overview.page.title')} level={3} />
        <Sheet>
          <Fieldset>
            <ObjectiveOverviewBar objective={obj}  />
            <FieldsAndButton fields={fields} button={bottomButton}/>
          </Fieldset>
        </Sheet>
      </Fieldset>
    </form>
  );
}
