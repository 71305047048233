"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontFamilies = exports.systemStack = void 0;
exports.systemStack = [
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'sans-serif',
];
exports.fontFamilies = {
    sansSerif: exports.systemStack,
};
