import { useEffect, useState } from "react";
import { UserDetailsFragmentFragment } from "../generated/graphql";
import { useAuth } from "./AuthProvider";
import { useTranslation } from "react-i18next";

declare global {
  interface Window {
    tidioChatApi: {
      open: () => void;
      on: (eventName: string, callback: () => void) => void;
      setColorPalette: (color: string) => void;
    };
  }
  interface Document {
    tidioIdentify: {
      distinct_id?: string;
      email?: string;
      name?: string;
      phone?: string;
    };
    tidioChatLang: string;
  }
}

const initTidio = async ({ id, email, firstName, lastName }: UserDetailsFragmentFragment) => {
  const tidioScript = document.createElement("script");
  await new Promise<void>((resolve) => {
    tidioScript.src = `//code.tidio.co/${process.env.REACT_APP_TIDIO_API_KEY}.js`;
    document.body.appendChild(tidioScript);
    document.tidioIdentify = {
      distinct_id: id,
      email,
      name: `${firstName} ${lastName}`,
    };

    const onTidioChatApiReady = () => {
      resolve();
    };

    if (window.tidioChatApi) {
      window.tidioChatApi.on("ready", onTidioChatApiReady);
    } else {
      document.addEventListener("tidioChat-ready", onTidioChatApiReady);
    }
  });
};

export const useTidio = () => {
  const [isTidioInitialized, setIsTidioInitialized] = useState(false);
  const { authenticated, user } = useAuth();
  const { i18n, ready } = useTranslation();

  useEffect(() => {
    const init = async (user: UserDetailsFragmentFragment, lang: string) => {
      await initTidio(user);
      window.tidioChatApi.setColorPalette("#47a896");
      document.tidioChatLang = lang;
      setIsTidioInitialized(true);
    };
    if (!isTidioInitialized && authenticated && user && i18n && ready) {
      init(user, i18n.language);
    }
  }, [isTidioInitialized, authenticated, user, i18n, ready]);

  return {
    isTidioInitialized,
  };
};
