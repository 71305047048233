import { FunctionComponent, MouseEvent, PropsWithChildren } from "react";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import styled from "styled-components";
import { Icon, fontSize, space } from "@scriba/ui-lib";

export type LegendItemEvents = {
  onMouseEnter?: (event: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;
  onClick?: (event: MouseEvent) => void;
};

export type LegendItemProps = Payload &
  LegendItemEvents & {
    index: number;
    isActive: boolean;
  };

export type StyledLiProps = PropsWithChildren<{ isActive: boolean }>;

const StyledLi = styled("li")<StyledLiProps>`
  color: ${({ color }) => color};
  cursor: pointer;
  list-style-type: none;
  padding: 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`;

const StyledButton = styled("button")`
  background-color: transparent;
  border-width: 0;
  color: inherit;
  cursor: pointer;
  padding: ${space("xs")}px ${space("sm")}px;
`;

const StyledLabel = styled("span")`
  color: inherit;
  font-size: ${fontSize("label")}px;
  margin-left: ${space("sm")}px;
`;

export const LegendItem: FunctionComponent<LegendItemProps> = (props) => {
  const { color, value, onClick, onMouseEnter, onMouseLeave, formatter, index, isActive } = props;
  const label = formatter ? formatter(value, props, index) : value;

  return (
    <StyledLi
      color={color}
      onClick={(event) => onClick?.(event)}
      onMouseEnter={(event) => onMouseEnter?.(event)}
      onMouseLeave={(event) => onMouseLeave?.(event)}
      isActive={isActive}
    >
      <StyledButton type="button">
        <Icon fill="currentColor" name={isActive ? "checkbox" : "checkbox-outline-blank"} />
        <StyledLabel>{label}</StyledLabel>
      </StyledButton>
    </StyledLi>
  );
};
