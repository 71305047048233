import styled from "styled-components";
import {Text, textColor} from "@scriba/ui-lib";

const HighlightedTextBorder = styled(Text)`
  color: ${textColor('main')};
  font-weight: bold;
  border: solid 1px;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -10px;
  margin-bottom: -10px;
`;

export const HighlightedTextBorderCentered = styled(Text)`
  display: block;
  text-align: center;
  color: ${textColor('main')};
  font-weight: bold;
  border: solid 1px;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -10px;
  margin-bottom: -10px;
`;

export default HighlightedTextBorder;

