import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import rg4js from "raygun4js";

import {raygunRum} from "../config";

export default function RouteLogger() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (raygunRum) {
      rg4js('trackEvent', { type: 'pageView', path: pathname });
    }
  }, [pathname]);

  return null;
}
