import { FunctionComponent } from "react";
import styled from "styled-components";
import {space} from "@scriba/ui-lib";

const Container = styled('span')<HighlightedElementProps>`
  margin-left: ${space('md')}px;
  margin-right: ${space('md')}px;
  width: ${props => props.big ? '10em' : '8em'};
  > input {
    width: inherit;
    text-align: center;  
  };
  span {
    text-align: center;
  };
`;

type HighlightedElementProps = {
  big?: boolean
}

const HighlightedElement: FunctionComponent<HighlightedElementProps> = ({big=false, children}) => {

  return (
    <Container big={big}>
      {children}
    </Container>
  )
}
export default HighlightedElement;