import React, {FunctionComponent} from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";

import {useAuth} from "../providers/AuthProvider";
import { usePendo } from "../providers/Pendo";

export const AuthenticatedRoute:FunctionComponent<RouteProps> = ({children, ...rest}) => {
  const { authenticated, user } = useAuth();
  usePendo({authenticated, user});

  return (
    <Route {...rest} render={({ location }) => {
      return authenticated
        ? children
        : <Redirect to={{
            pathname: '/login',
            state: {from: location}
          }}
        />
    }} />
  )
}
