import {ApolloCache, gql} from "@apollo/client";

import {fragments as otherFragments} from './others';
import {TypedDocumentNode} from "@graphql-typed-document-node/core";
import {typify} from "@scriba/common/dist/services/helpers";

export const fragments = {
  details: gql`
    fragment AllAssetsDetailsFragment on Asset {
      ...OtherAssetDetailsFragment
    }
    ${otherFragments.details}
  `,
}

const queries = {
  get: gql`
    query GetAsset($id: ID!) {
      asset(id: $id) {
        ...OtherAssetDetailsFragment
      }
    }
    ${otherFragments.details}
  `,
  list: gql`
    query ListAssets {
      assets {
        ...OtherAssetDetailsFragment
      }
    }
    ${otherFragments.details}
  `,
}

export default queries;

export const removeAssetFromQuery = <TObj extends { id: string }, TQuery, TData extends { assets: TObj[] }, TVariables>(cache: ApolloCache<TQuery>, query: TypedDocumentNode<TData, TVariables>, obj: TObj) => {
  try {
    const data = cache.readQuery({query});
    cache.writeQuery({
      query,
      data: data && {
        assets: data.assets.filter(a => a.id !== obj.id),
      },
    });
  } catch (err) {
    // Ignore error
  }
};

export const addAssetToQuery = <TObj extends { id: string }, TQuery, TData extends { assets: TObj[] }, TVariables>(cache: ApolloCache<TQuery>, query: TypedDocumentNode<TData, TVariables>, obj: TObj, sort: (assets: TObj[]) => TObj[]) => {
  try {
    const data = cache.readQuery({query});
    const cacheId = cache.identify(typify(obj))
    cache.writeFragment({
      id: cacheId,
      fragment: fragments.details,
      fragmentName: "AllAssetsDetailsFragment",
      data: obj,
    });
    cache.writeQuery({
      query,
      data: data && {
        assets: sort([...data.assets, obj]),
      },
    });
  } catch (err) {
    // Ignore error
  }
};
