import {IconNames} from "@scriba/ui-lib";

import {Objective, ObjectiveType, Scenario, ScenarioType} from "../generated/graphql";

export const objectiveIconName = (objective: Pick<Objective, 'subType'>): IconNames => {
  switch (objective.subType) {
    case ObjectiveType.building: return "home"
    case ObjectiveType.amount: return "euro"
  }
}

export const scenarioIconName = (scenario: Pick<Scenario, 'scenarioType'>): IconNames => {
  switch (scenario.scenarioType) {
    case ScenarioType.buyRealEstate:
      return "home"
    case ScenarioType.askForMoney:
      return "people"
    case ScenarioType.buyStocks:
      return "show_chart"
    case ScenarioType.saveMore:
      return "euro"
  }
}

const scenarioOrderFromType:{[T in ScenarioType]: number} = {
  [ScenarioType.saveMore]: 0,
  [ScenarioType.askForMoney]: 1,
  [ScenarioType.buyStocks]: 2,
  [ScenarioType.buyRealEstate]: 3,
}

export const sortScenarios = (scenario1: Pick<Scenario, 'scenarioType'>, scenario2: Pick<Scenario, 'scenarioType'>):number => {
  return scenarioOrderFromType[scenario1.scenarioType] - scenarioOrderFromType[scenario2.scenarioType]
}
