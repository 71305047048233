import { FunctionComponent, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { Button, Card, Title } from "@scriba/ui-lib";

export type PlaceHolderObjectiveCardProps = PropsWithChildren<{
  title: string;
}>;

const PlaceHolderObjectiveCard: FunctionComponent<PlaceHolderObjectiveCardProps> = ({ title }) => {
  const {t} = useTranslation('objectives');
  const disabled = true;

  return (
    <Card
      footer={
        <Button
          label={t("objective.card.chooseButtonPlaceholder.label")}
          disabled={disabled}
          color="main"
          appearance="transparent"
        />
      }
      iconName="help"
      disabled={disabled}
      color="secondary"
    >
      <Title title={title} level={3} />
    </Card>
  );
};

export default PlaceHolderObjectiveCard;
