import { FunctionComponent, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ListAssetsQuery } from "../../generated/graphql";
import styled from "styled-components";
import { otherAssetSubTypesList, netValue, sortBySubType } from "@scriba/common";
import { StackedAreaChart, ChartData } from "./StackedAreaChart";
import { ChartEmptySpace } from "./ChartEmptySpace";
import { Tooltip } from "./Tooltip";
import { DataKey } from "recharts/types/util/types";
import { Formatter } from "recharts/types/component/DefaultLegendContent";
import Group from "../Group";
import assets from "../../queries/assets";
import { formatMonetaryAmount } from "../../services/format";

const ChartWrapper = styled(Group)`
  position: relative;
  height: 33vh;
  min-height: 320px;
`;


export const AreaChartHome: FunctionComponent = () => {
  const { t } = useTranslation(["assets"]);
  const { data } = useQuery<ListAssetsQuery>(assets.list);
  const date = dayjs();
  const [chartData, setChartData] = useState<Record<string, string | number | null>[]>([]);
  const xAxis = [...Array(11)].map((_, i) => {
    const currentDate = date.add(i, "y");
    return currentDate;
  });
  const [typeFilters, setTypeFilters] = useState<DataKey<string>[]>([]);

  const onLegendClick: (data: {dataKey: DataKey<string>}) => void = (data) => {
    const { dataKey } = data;
    if(!dataKey) {
      console.warn(`no dataKey on legend ${data}`);
    }
    else if (typeFilters.includes(dataKey)) {
      setTypeFilters(typeFilters.filter((typeFilter) => typeFilter !== dataKey));
    } else {
      setTypeFilters([...typeFilters, dataKey]);
    }
  };

  const onAreaClick: (chartData: ChartData) => (areaName: string) => void = (chartData) => (areaName) => {
    const filtered = otherAssetSubTypesList.filter((name) => name !== areaName);
    setTypeFilters(filtered);
  };

  const legendFormatter: Formatter = (value) => {
    return t(`assets:other.subType.${value}`);
  };

  const tooltipFormatter: Formatter = (value, name) => {
    const formattedValue = formatMonetaryAmount(value, "EUR", 0);
    const formattedName = t(`assets:other.subType.${name}`);
    return [formattedValue, formattedName];
  };

  const tickFormatter: (value: any, index: number) => string = (value) => {
    const formattedValue = formatMonetaryAmount(value, "EUR", 0);
    return formattedValue;
  }

  useEffect(() => {
    if (data) {
      const { assets } = data;
      const formattedData: ChartData = xAxis.map((date) => {
        const values = [...assets] //shallow copy to be able to mutate array (sort)
          .sort(sortBySubType)
          .reduce<Record<string, number | null>>((assetValues, asset) => {
            const value = netValue(asset, date.toDate()) * asset.currency.rateToEuro;
            const subtype = asset.subType;
            const assetSubTypeValue = assetValues[subtype] ?? 0;
            const isVisible = !typeFilters.includes(asset.subType);

            return {
              ...assetValues,
              [asset.subType]: isVisible ? assetSubTypeValue + value : null, // setting area we want to hide to null hides it from chart but leaves it in the legend
            };
          }, {});

        return {
          name: date.format("YYYY"),
          ...values,
        };
      });
      setChartData(formattedData);
    }
  }, [data, typeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ChartWrapper>
      {chartData.length > 0 && (
        <StackedAreaChart
          data={chartData}
          onAreaClick={onAreaClick(chartData)}
          onLegendClick={onLegendClick}
          legendFormatter={legendFormatter}
          tooltipFormatter={tooltipFormatter}
          tickFormatterY={tickFormatter}
          filters={typeFilters}
          CustomTooltip={Tooltip}
        />
      )}
      {!data?.assets?.length && (
        <ChartEmptySpace />
      )}
    </ChartWrapper>
  );
};
