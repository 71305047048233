import {gql} from "@apollo/client";

export const fragments = {
  details: gql`
    fragment UserDetailsFragment on User {
      id
      email
      firstName
      lastName
      maxDebtRatio
    }
  `,
  recoverPasswordToken: gql`
    fragment RecoverPasswordTokenFragment on RecoverPasswordToken {
      id
      email
      expirationDate
      consumptionDate
    }
  `
}

const queries = {
  getUser: gql`
    query User {
      user {
        ...UserDetailsFragment
      }
    }
    ${fragments.details}
  `,
  getRecoverPasswordToken: gql`
    query RecoverPasswordToken($id: ID!) {
      recoverPasswordToken(id: $id) {
        ...RecoverPasswordTokenFragment
      }
    }
    ${fragments.recoverPasswordToken}
  `,
  signup: gql`
    mutation SignUp($firstName: String!, $lastName: String!, $email: String!, $password: String!) {
      signup(firstName: $firstName, lastName: $lastName, email: $email, password: $password) {
        data {
          ...UserDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  login: gql`
    mutation Login($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        data {
          ...UserDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  logout: gql`
    mutation Logout {
      logout
    }
  `,
  initiateRecoverPassword: gql`
    mutation InitiateRecoverPassword($email: String!){
      initiateRecoverPassword(email: $email) {
        error
        data {
          ...RecoverPasswordTokenFragment
        }
      }
    }
    ${fragments.recoverPasswordToken}
  `,
  changePassword: gql`
    mutation ChangePassword($password: String!, $tokenId: ID!){
      changePassword(password: $password, tokenId: $tokenId) {
        error
        data {
          ...UserDetailsFragment
        }
      }
    }
    ${fragments.details}
  `,
  update: gql`
    mutation UpdateUser($input: UserUpdate!) {
      updateUser(input: $input) {
        error
        data {
          ...UserDetailsFragment
        }
      }
    }
    ${fragments.details}
  `
}

export default queries;
