import {ApolloCache, gql, useMutation} from "@apollo/client";
import {ApolloError} from "@apollo/client/errors";
import {useCallback} from "react";

import {
  ListExpensesQuery,
  ExpenseDetailsFragmentFragment,
  RemoveExpenseMutation,
  CreateExpenseMutationMutation,
  ExpenseCreate,
} from "../generated/graphql";
import {typify} from "@scriba/common/dist/services/helpers";

export const fragments = {
  details: gql`
    fragment ExpenseDetailsFragment on Expense {
      id
      expenseType
      amount
      currency {
        code
        rateToEuro
      }
      periodicity
    }
  `,
}

const queries = {
  list: gql`
    query ListExpenses {
      expenses {
        ...ExpenseDetailsFragment
      }
    }
    ${fragments.details}
  `,
  create: gql`
    mutation CreateExpenseMutation($input: ExpenseCreate!) {
      createExpense(input: $input) {
        data {
          ...ExpenseDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  update: gql`
    mutation UpdateExpense($input: ExpenseUpdate!) {
      updateExpense(input: $input) {
        data {
          ...ExpenseDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  remove: gql`
    mutation RemoveExpense($id: ID!) {
      removeExpense(id: $id) {
        data {
          ...ExpenseDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
}

export default queries;

export const removeExpenseFromList = <T>(cache: ApolloCache<T>, filter: (expense: ExpenseDetailsFragmentFragment) => boolean) => {
  try {
    const data = cache.readQuery<ListExpensesQuery>({query: queries.list});
    cache.writeQuery({
      query: queries.list,
      data: data && {
        expenses: data.expenses.filter(filter),
      },
    });
  } catch (err) {
    console.error({err})
  }
};

export function useRemoveExpense({onError, onCompleted}:{onError: (error: ApolloError) => void, onCompleted: (data: RemoveExpenseMutation) => void}) {
  const [ removeExpense ] = useMutation(queries.remove, {
    onError,
    update: (cache, res) => {
      if (res?.data?.removeExpense?.data) {
        const objToRemove = res.data.removeExpense.data;
        removeExpenseFromList(cache, a => a.id !== objToRemove.id);
      }
    },
    onCompleted,
  });
  return useCallback((id) => {
    return removeExpense({
      variables: {id},
    });
  }, [removeExpense]);
}

export function useCreateExpense({onError, onCompleted}:{onError: (error: ApolloError) => void, onCompleted?: (data: CreateExpenseMutationMutation) => void}) {
  const [ createExpense ] = useMutation<CreateExpenseMutationMutation>(queries.create, {
    onError,
    update: (cache, res) => {
      if (res?.data?.createExpense?.data) {
        const objToAdd = res.data.createExpense.data;
        try {
          const data = cache.readQuery<ListExpensesQuery>({query: queries.list});
          const cacheId = cache.identify(typify(objToAdd))
          cache.writeFragment({
            id: cacheId,
            fragment: fragments.details,
            fragmentName: "ExpenseDetailsFragment",
            data: objToAdd,
          });
          cache.writeQuery({
            query: queries.list,
            data: data && {
              expenses: [...data.expenses, objToAdd],
            },
          });
        } catch (err) {
          console.error({err})
        }
      }
    },
    onCompleted,
  });
  return useCallback((expense: ExpenseCreate) => {
    return createExpense({
      variables: {input: expense},
    });
  }, [createExpense]);
}
