import {gql, useMutation} from "@apollo/client";
import {ApolloError} from "@apollo/client/errors";
import {fragments as assetFragments} from "./assets";
import {useCallback} from "react";
import {typify} from "@scriba/common/dist/services/helpers";
import {
  AllAssetsDetailsFragmentFragment,
  CreateLoanMutation,
  LoanCreate,
  RemoveLoanMutation
} from "../generated/graphql";

export const fragments = {
  details: gql`
    fragment LoanDetailsFragment on Loan {
      id
      currency {
        code
        rateToEuro
      }
      asset {
        id
        name
      }
      amount
      yearlyRate
      monthlyAmount
      subscriptionDate
      sharePercentage
      loanSource
      loanType
      repayment
      loanWarranty
      loanWarrantyAsset
    }
  `,
}

const queries = {
  list: gql`
    query ListLoans {
      loans {
        ...LoanDetailsFragment
      }
    }
    ${fragments.details}
  `,
  remove: gql`
    mutation RemoveLoan($id: ID!) {
      removeLoan(id: $id) {
        data {
          ...LoanDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  create: gql`
    mutation CreateLoan($input: LoanCreate!) {
      createLoan(input: $input) {
        data {
          ...LoanDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  update: gql`
    mutation UpdateLoan($input: LoanUpdate!) {
      updateLoan(input: $input) {
        data {
          ...LoanDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
}

export default queries;

export function useRemoveLoan({onError, onCompleted}:{onError: (error: ApolloError) => void, onCompleted: (data: RemoveLoanMutation) => void}) {
  const [ removeLoan ] = useMutation(queries.remove, {
    onError,
    update: (cache, res) => {
      if (res?.data?.removeLoan?.data) {
        const objToRemove = res.data.removeLoan.data;
        const assetCacheId = cache.identify(typify(objToRemove.asset))
        try {
          const data = cache.readFragment<AllAssetsDetailsFragmentFragment>({
            id: assetCacheId,
            fragmentName: "AllAssetsDetailsFragment",
            fragment: assetFragments.details,
          });
          cache.writeFragment({
            id: assetCacheId,
            fragment: assetFragments.details,
            fragmentName: "AllAssetsDetailsFragment",
            data: data && {
              ...data,
              loans: data.loans.filter(l => l.id !== objToRemove.id),
            },
          });
        } catch (err) {
          console.error({err})
        }
      }
    },
    onCompleted,
  });
  return useCallback((id) => {
    return removeLoan({
      variables: {id},
    });
  }, [removeLoan]);
}

export function useCreateLoan({onError, onCompleted}:{onError: (error: ApolloError) => void, onCompleted?: (data: CreateLoanMutation) => void}) {
  const [ createLoan ] = useMutation<CreateLoanMutation>(queries.create, {
    onError,
    update: (cache, res) => {
      if (res?.data?.createLoan?.data) {
        const objToAdd = res.data.createLoan.data;

        const assetCacheId = cache.identify(typify(objToAdd.asset))
        try {
          const data = cache.readFragment<AllAssetsDetailsFragmentFragment>({
            id: assetCacheId,
            fragmentName: "AllAssetsDetailsFragment",
            fragment: assetFragments.details,
          });
          cache.writeFragment({
            id: assetCacheId,
            fragment: assetFragments.details,
            fragmentName: "AllAssetsDetailsFragment",
            data: data && {
              ...data,
              loans: [...data.loans, objToAdd],
            },
          });
        } catch (err) {
          console.error({err})
        }
      }
    },
    onCompleted,
  });
  return useCallback((loan: LoanCreate) => {
    return createLoan({
      variables: {input: loan},
    });
  }, [createLoan]);
}
