import React, {FunctionComponent, useState} from 'react';
import styled from "styled-components";

import {fontFamily, fontSize, space, Button, Fieldset, Input, Label, Title} from "@scriba/ui-lib";

import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useMutation} from "@apollo/client";
import {
  InitiateRecoverPasswordMutation, InitiateRecoverPasswordMutationVariables,
} from "../generated/graphql";
import users from "../queries/users";

const InputWithUpperLabel = styled.div`
  display: grid;
  grid-row-gap: ${space('xs')}px;
`

const ErrorWrapper = styled('div')`
  grid-column: 2 / -1;
  padding: 0 ${space('sm')}px;
  font-family: ${fontFamily('sansSerif')};
  font-size: ${fontSize('label')}px;
  color: tomato;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: ${space('xxl')}px;
  margin-right: ${space('xxl')}px;
  padding-right: ${space('xxl')}px;
`

const LeftBox = styled.div`
  flex: 3;
`

const RightBox = styled.div`
  flex: 1;
`

export const ForgotPasswordPage:FunctionComponent = () => {
  const {t} = useTranslation(['common', 'auth', 'form']);
  const {handleSubmit, register, errors} = useForm<{email: string}>();
  const [isSubmitted, setSubmitted] = useState(false);

  const [initiateRecoverPassword] = useMutation<InitiateRecoverPasswordMutation, InitiateRecoverPasswordMutationVariables>(
    users.initiateRecoverPassword,
    {
      onError: (err) => {
        console.error(err);
        toast.error(t('auth:password_recover.submit.error'));
      }
    }
  )

  const fieldRequiredErrorMsg = t('form:field.required');

  const submitTokenCreation = async ({email}: {email: string}) => {
    await initiateRecoverPassword({variables: {email}});
    toast.success(t('auth:password_recover.submitted.toast'))
    setSubmitted(true);
  }

  return (
    <Container>
      <LeftBox />
      <RightBox>
        {isSubmitted ? (
          <Title title={t('auth:password_recover.submitted.message')} level={1} />
        ) : (
          <form onSubmit={handleSubmit(submitTokenCreation)}>
            <Fieldset>
              <InputWithUpperLabel>
                <Title title={t('auth:password_recover.title')} level={5} />
                <Title title={t('auth:password_recover.message')} level={4} />
              </InputWithUpperLabel>

              <InputWithUpperLabel>
                <Label label={t('auth:field.email.label')} />
                <Input placeholder={t('auth:field.email.placeholder')}
                       name="email"
                       ref={register({required: fieldRequiredErrorMsg})}
                />
                {!!errors["email"] &&
                <ErrorWrapper>
                  {errors["email"].message}
                </ErrorWrapper>
                }
              </InputWithUpperLabel>

              <Button type="submit" label={t('auth:password_recover.submit.button')} />

            </Fieldset>
          </form>
        )}
      </RightBox>
    </Container>
  );
}
