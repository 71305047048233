import React from 'react';
import styled from 'styled-components';
import {Link, useLocation} from "react-router-dom";

import {color, fontSize, getSidebarWidth, space, surface} from "@scriba/ui-lib";
import ButtonLink from "./ButtonLink";

import Logo from "../Logo";
import Text from "@scriba/ui-lib/dist/components/Text";
import {useTranslation} from "react-i18next";
import {borderRadius} from "@scriba/ui-lib/dist/themes/accessors";

const StyledSideNav = styled('nav')`
    box-sizing: border-box;
    position: fixed;
    height: 100%;
    width: ${getSidebarWidth()};
    background-color: ${surface('sheet')};
`;

const NavBlock = styled('ul')`
  box-sizing: border-box;
  list-style: none;
  padding-inline-start: ${space('lg')}px;
  display: flex;
  flex-direction: column;
  margin: 0;

  & + & {
    margin-top: ${space('xl')}px;
  }
`

const BaseNavigationItem = styled('li')`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`

const ClickableNavigationItem = styled(BaseNavigationItem)<{ current: boolean }>`
display: flex;
  a {
    box-sizing: border-box;
    text-decoration: none;
    ${(p) => (p.current ? "font-weight: bold;" : "")}
  }

  & + & {
    margin-top: ${space("md")}px;
  }
`;

const StyledLink = styled(Link)`
  box-sizing: border-box;
  padding-top: ${space("xs")}px;
  padding-bottom: ${space("xs")}px;
  padding-left: ${space('md')}px;
  width: 100%;
  display: flex;

  a {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  span {
    width: 100%;
  }

  &::after {
    box-sizing: border-box;
    display: block;
    flex-shrink: 0;
    height: 100%;
    content: " ";
    width: ${space("sm")}px;
    background: ${color("main", "base")};
    border-radius: ${borderRadius("xxl")}px 0 0 ${borderRadius("xxl")}px;
    opacity: 0;
    visibility: hidden;
  }

  &:hover::after {
    opacity: 1;
    visibility: visible;
  }
`;

const SideBarButtonLink = styled(ButtonLink)`
  box-sizing: border-box;
  font-size: ${fontSize("label")}px;
  text-align: left;
  padding-top: ${space('sm')}px;
  padding-bottom: ${space('sm')}px;
  margin-right: ${space('md')}px;
  width: 100%;
`

const NavigationItem = ({path, label, button} : {path: string, label: string, button?: boolean}) => {
  const location = useLocation();
  const {t} = useTranslation('navigation');

  return (
    <ClickableNavigationItem current={location.pathname === path} data-pendo-id="clickable-navigation-item">
      {button ? (
        <SideBarButtonLink to={path} label={t(label)} iconName="add" size="sm"/>
      ) : (
        <StyledLink to={path}>
          <Text color="contrast">{t(label)}</Text>
        </StyledLink>
      )}
    </ClickableNavigationItem>
  )
}

const SideBar = () => {
  return (
    <StyledSideNav data-pendo-id="primary-nav">
      <StyledLink to="/">
        <Logo/>
      </StyledLink>
      <NavBlock data-pendo-id="primary-nav__dashboard">
        <NavigationItem path="/" label="navbar.dashboard.label" />
      </NavBlock>
      <NavBlock data-pendo-id="primary-nav__assets">
        <NavigationItem path="/assets" label="navbar.assets.label" />
        <NavigationItem path="/assets/other/new" label="navbar.assets.create.label" button/>
      </NavBlock>
      <NavBlock data-pendo-id="primary-nav__objectives">
        <NavigationItem path="/objectives" label="navbar.objective.list.label" />
        <NavigationItem path="/objectives/new" label="navbar.objective.create.label" button/>
      </NavBlock>
      <NavBlock data-pendo-id="primary-nav__movements">
        <NavigationItem path="/movements" label="navbar.movements.list.label" />
        <NavigationItem path="/movements/new" label="navbar.movements.create.label" button/>
      </NavBlock>
      <NavBlock data-pendo-id="primary-nav__borrowing-capacity">
        <NavigationItem path="/borrowingCapacity" label="navbar.borrowingCapacity.label"/>
      </NavBlock>
    </StyledSideNav>
  )
}

export default SideBar;
