import React, { FunctionComponent, useEffect, useState } from "react";
import { Area, AreaChart, Legend, ResponsiveContainer, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import { DataKey } from "recharts/types/util/types";
import { Payload, Formatter } from "recharts/types/component/DefaultLegendContent";
import {ContentType} from 'recharts/types/component/Tooltip';
import type {ValueType, NameType} from 'recharts/types/component/DefaultTooltipContent';
import { colorMaps } from "@scriba/ui-lib/dist/themes/colors/colorMaps";
import { OtherAssetSubTypes } from "@scriba/common";
import { LegendItem } from "./LegendItem";

const ChartColors: Record<OtherAssetSubTypes, string> = {
  bankAccount: colorMaps.teal.dark,
  stocks: "#506DA5",
  realty: "#CB5E4D",
  financialInsurance: "#9EA121",
  pension: colorMaps.teal.light,
  crypto: "#7D99C2",
  unlistedStocks: "#E39185",
  professionalAssets: "#CCCE54",
  artwork: colorMaps.teal.lighter,
  collectionVehicle: "#A5CBE0",
  other: "#FBBBB1",
  //last available color: "#E8EA8F"
};

export type ChartEventDataType<T> = Payload & {
  dataKey?: DataKey<T>;
};

export type ChartDataKeys = OtherAssetSubTypes;

export type ChartEventType = (data: ChartEventDataType<string | number>) => void;

export type ChartDataItem = Record<string, number | string | null>;
export type ChartData = ChartDataItem[];
export interface ChartProps {
  data: ChartData;
  onAreaClick?: (areaName: string) => void;
  onLegendClick?: (data: { dataKey: string }) => void;
  legendFormatter?: Formatter;
  tooltipFormatter?: Formatter;
  CustomTooltip?: ContentType<ValueType, NameType>;
  tickFormatterY?: (value: any, index: number) => string;
  referenceLineY?: {
    value: number;
    label: string;
  };
  filters: DataKey<string>[]
}

export type AreaOpacity = Record<string, number>;

const getAreaNames = (data: ChartData) => {
  return Object.keys(data[0]).filter((key) => key !== "name");
};

const setAllOpacities: (areasList: string[], opacity: number) => AreaOpacity = (areasList, opacity) => {
  return areasList.reduce((opacities, areaName) => ({ ...opacities, [areaName]: opacity }), {});
};

export const StackedAreaChart: FunctionComponent<ChartProps> = ({
  data,
  onAreaClick,
  onLegendClick,
  legendFormatter,
  tooltipFormatter,
  CustomTooltip,
  tickFormatterY,
  referenceLineY,
  filters,
}) => {
  const areaNames = getAreaNames(data);
  const [opacity, setOpacity] = useState<AreaOpacity>({});

  useEffect(() => {
    const areaNames = getAreaNames(data);
    const opacities = areaNames.reduce((opacities, areaName) => ({ ...opacities, [areaName]: 0.8 }), {});
    setOpacity(opacities);
  }, [data]);

  const handleLegendMouseEnter: ChartEventType = ({ dataKey }) => {
    highlightArea(dataKey as string);
  };

  const highlightArea: (dataKey: string) => void = (dataKey) => {
    const reducedOpacities = setAllOpacities(Object.keys(opacity), 0.5);
    setOpacity({ ...reducedOpacities, [dataKey]: 0.8 });
  };

  const handleLegendClick = (data: { dataKey: string }) => {
    onLegendClick && onLegendClick(data);
  };

  const handleAreaClick: (areaName: string) => void = (areaName) => {
    onAreaClick && onAreaClick(areaName);
  };

  const resetOpacities = () => {
    setOpacity(setAllOpacities(Object.keys(opacity), 0.8));
  };

  return (
    <ResponsiveContainer width="100%" height="100%" debounce={1}>
      <AreaChart data={data}>
        {areaNames.map((areaName) => {
          const color = ChartColors[areaName as OtherAssetSubTypes];

          return (
            <Area
              stackId="1"
              dataKey={areaName}
              fill={color}
              stroke={color}
              key={areaName}
              type="natural"
              opacity={opacity[areaName]}
              fillOpacity={opacity[areaName]}
              onMouseEnter={() => highlightArea(areaName)}
              onMouseLeave={() => resetOpacities()}
              onClick={() => handleAreaClick(areaName)}
              animationDuration={600}
            />
          );
        })}
        <XAxis dataKey="name" />
        <YAxis  tickFormatter={tickFormatterY} width={120}/>
        <Tooltip
          formatter={tooltipFormatter}
          content={CustomTooltip}
        />
        <Legend
          verticalAlign="top"
          align="left"
          layout="vertical"
          wrapperStyle={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
          content={({ payload }) => {
            if (!payload) {
              return null;
            }
            return payload.map((item, index) => {
              const isActive = !filters.includes(item.value);
              return (
                <LegendItem
                  key={`item-${index}`}
                  {...item}
                  onClick={() => handleLegendClick({ dataKey: item.value as string })}
                  onMouseEnter={() => handleLegendMouseEnter?.(item)}
                  onMouseLeave={resetOpacities}
                  formatter={legendFormatter}
                  index={index}
                  isActive={isActive}
                />
              );
            });
          }}
        />
        {referenceLineY && <ReferenceLine y={referenceLineY.value} label={referenceLineY.label} stroke="red" />}
      </AreaChart>
    </ResponsiveContainer>
  );
};
