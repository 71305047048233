import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { buildClientSchema, IntrospectionQuery } from "graphql";
import { withScalars } from "apollo-link-scalars";
import {onError} from "@apollo/client/link/error";
import rg4js from "raygun4js";

import introspectionResult from "./generated/graphql.schema.json";
import dayjs from "dayjs";

export const backEndUri = process.env.REACT_APP_BACKEND_URI ? process.env.REACT_APP_BACKEND_URI : 'http://localhost:4000';
export const i18nDebug = false//process.env.NODE_ENV === 'development';
export const i18nCache = process.env.REACT_APP_I18N_CACHE && process.env.REACT_APP_I18N_CACHE === 'true';
export const i18nVersion = process.env.REACT_APP_I18N_VERSION;
const raygunRumApiKey = process.env.REACT_APP_RAYGUN_RUM_APIKEY;
export const raygunRum = !!raygunRumApiKey

if (raygunRumApiKey) {
  rg4js('apiKey', raygunRumApiKey);
  rg4js('enableCrashReporting', true);
  rg4js('enablePulse', true);
}

const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery)

const scalarsLink:ApolloLink = withScalars({
  schema,
  typesMap: {
    Date: {
      serialize: (parsed: Date) => dayjs(parsed).format("YYYY-MM-DD"),
      parseValue: (raw: string | number | null): Date | null => {
        return raw ? new Date(raw) : null;
      }
    }
  },
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    scalarsLink,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: `${backEndUri}/graphql`,
      credentials: 'include'
    })
  ]),
  cache: new InMemoryCache({
    possibleTypes: {
      Asset: ["OtherAsset"],
      Scenario: ["AskForMoneyScenario", "BuyStocksScenario", "BuyRealEstateScenario", "SaveMoreScenario"]
    },
    typePolicies: {
      Query: {
        fields: {
          assets: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          movements: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      OtherAsset: {
        fields: {
          loans: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          movements: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        }
      },
    },
  })
});
