import React, {FunctionComponent, useState} from "react";
import {useLocation, useParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {useMutation, useQuery} from "@apollo/client";

import {Fieldset, Sheet, Title} from "@scriba/ui-lib";

import ObjectiveInputForm from "../components/forms/ObjectiveInputForm";
import BuildingObjectiveComputationForm from "../components/forms/BuildingObjectiveComputationForm";
import {
  GetObjectiveQuery,
  GetObjectiveQueryVariables,
  ObjectiveDetailsFragmentFragment,
  ObjectiveType,
  UpdateObjectiveMutation,
  UpdateObjectiveMutationVariables
} from "../generated/graphql";
import objectives from "../queries/objective";
import {objectiveIconName} from "../services/objectives";
import AmountObjectiveComputationForm from "../components/forms/AmountObjectiveComputationForm";

type EditBuildingObjectiveState = {
  step: 0 | 1 | 2;
}

export const EditObjectivePage: FunctionComponent = () => {
  const {t} = useTranslation(['objectives', 'common']);
  const { state } = useLocation<EditBuildingObjectiveState>();
  const [step, setStep] = useState<0 | 1 | 2>(!!state ? state.step : 0);
  const [obj, setObj] = useState<ObjectiveDetailsFragmentFragment>();
  let { objectiveId } = useParams<{objectiveId: string}>();
  const previous = (step: 0 | 1 | 2, objective?: ObjectiveDetailsFragmentFragment) => {
    setStep(step);
  };
  const { data } = useQuery<GetObjectiveQuery, GetObjectiveQueryVariables>(objectives.get, {variables: { id: objectiveId }});
  const [updateObjective] = useMutation<UpdateObjectiveMutation, UpdateObjectiveMutationVariables>(
    objectives.update,
    {
      onError: (error) => {
        toast.error(t('objectives:update.error.toast'));
      },
    }
  )
  if (!data?.objective) return null;
  const iconName = objectiveIconName(data.objective);
  const ObjectiveComputationForm = data.objective.subType === ObjectiveType.amount ? AmountObjectiveComputationForm : BuildingObjectiveComputationForm;

  if (step === 2 && obj === undefined) {
    setObj(data.objective);
    setStep(2);
  }
  return (
    <Fieldset>
      <Title iconName={iconName} level={3} title={t(`objectives:create.${data.objective.subType}.title`)}/>

      <Sheet>
        {(step === 0 || step === 1) ? (
            <ObjectiveInputForm
              objective = {data.objective}
              onSubmit={async (input) => {
                if (step === 0) {
                  setStep(1);
                } else {
                  const {__typename, currency, scenarios, ...parsedObj} = input;
                  const res = await updateObjective({
                    variables: {input: {
                        currencyCode: data!.objective!.currency.code,
                        ...parsedObj,
                      }}
                  });
                  if(!res.data || !res.data.updateObjective.data) {
                    toast.error(t('objectives:new.error.toast'));
                  } else {
                    setObj(res.data.updateObjective.data);
                    setStep(2);
                  }
                }
              }}
              step = {step}
              previous = {previous}
            />
          ) : (
            <ObjectiveComputationForm objective={obj!} previous={previous} saveObjectiveButton/>
          )}
      </Sheet>

    </Fieldset>
  );
}
