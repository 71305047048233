import {FunctionComponent} from "react";
import styled from "styled-components";

import { space, getGridSize} from "@scriba/ui-lib";
import {ListObjectivesQuery} from "../generated/graphql";

import MyObjectiveCard from "../components/MyObjectiveCard"
import AddObjectiveCard from "../components/AddObjectiveCard"
import { useQuery } from "@apollo/client";
import objectives from "../queries/objective";

const ScenariosContainer = styled('div')`
  padding-top: ${space('xl')}px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${getGridSize(42)}, 1fr));
  row-gap: ${space('xxl')}px;
  column-gap: ${space('xl')}px;
  min-height: ${getGridSize(42)};
`;

export const MyObjectivesList: FunctionComponent = () => {
  const { data } = useQuery<ListObjectivesQuery>(objectives.list, {fetchPolicy: "cache-and-network"});
  return (
    <ScenariosContainer>
        {!!data && data.objectives.map(objective =>
            <MyObjectiveCard key={objective.id} objective={objective} />
        )}
        <AddObjectiveCard data-pendo-id="add-objective" />
    </ScenariosContainer>
  );
}
