import {FunctionComponent, useState} from "react";
import { toast } from "react-toastify";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Text, borderRadius, Button, color, fixFirefoxFlexScroll, fontFamily, fontSize, space, textColor, Title } from "@scriba/ui-lib";

import { ObjectiveDetailsFragmentFragment, UpdateObjectiveMutation, UpdateObjectiveMutationVariables } from "../../generated/graphql";
import objectives from "../../queries/objective";
import {formatMonetaryAmount} from "../../services/format";
import { AutoNumericInput } from "../AutoNumericInput";
import {HelpTooltip} from "@scriba/ui-lib/dist/components/HelpTooltip";
import HighlightedText from "../HighlightedText";
import { monthsBetween } from "@scriba/common";

const Group = styled.div`
  ${fixFirefoxFlexScroll()}
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: ${space('md')}px;
  border-radius: ${borderRadius('md')}px;
  background-color: ${color('secondary', 'darker')};
  color: ${textColor('contrast')};
  font-family: ${fontFamily('sansSerif')};
  font-size: ${fontSize('base')}px;
  margin: ${space('md')}px;
  padding-right: ${space('xxl')}px;
  padding-left: ${space('xxl')}px;
  padding-top: ${space('xxl')}px;
  padding-bottom: ${space('xxl')}px;
`;

const OFText = styled(Text)`
  color: ${textColor('contrast')};
`;

const LabelAmounts = styled(Text)`
  color: ${color('secondary', 'lightest')};
  padding-bottom: ${space('sm')}px;
`;

const AmountGroupL = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  padding-right: ${space('xxl')}px;
  padding-top: ${space('md')}px;
  padding-bottom: ${space('md')}px;
`;

const AmountGroupR = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-left: ${space('xxl')}px;
  padding-top: ${space('md')}px;
  padding-bottom: ${space('md')}px;
  border-left: 2px solid ${textColor('main')};
`;

const PButton = styled(Button)`
  margin-top: ${space('xl')}px;
`;

const DivPicker = styled.div`
  margin-top: ${space('xl')}px;
  margin-bottom: ${space('xl')}px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
`;

const DiffSizedTexts = styled.div`
  margin-top: ${space('xl')}px;
  margin-bottom: ${space('xl')}px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
`;

const PercentageInput = styled(AutoNumericInput)`
  width: 6em;
  margin-left: ${space('sm')}px;
  margin-right: ${space('sm')}px;

`;

const SubGroupDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  `;

const AmountDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  `;

interface ObjectiveInputFormProps {
  objective: ObjectiveDetailsFragmentFragment
  previous: Function
  saveObjectiveButton?: Boolean
}

const BuildingObjectiveComputationForm: FunctionComponent<ObjectiveInputFormProps> = ({objective, previous, saveObjectiveButton}) => {
  const {t} = useTranslation(['objectives', 'common', 'form']);
  const history = useHistory();
  const ofpDefault = objective?.ownFundsPercentage ?? 0.2;
  const [percentage, setPercentage] = useState<number>(ofpDefault);
  const fees = (objective.amount || 0) * (objective?.taxesPerc || 0);
  const futureAvailableCash = objective.futureAvailableCash || 0;
  const currencyCode = objective?.currency.code || 'EUR';
  const requiredAmount = objective.amount * percentage + fees;
  const targetedAmount = objective.amount - requiredAmount + fees;
  const objectiveDelay = monthsBetween(new Date(), objective.objectiveDate);
  const missingAmount = Math.max(0, requiredAmount - objective.futureAvailableCash)

  const [updateObjective] = useMutation<UpdateObjectiveMutation, UpdateObjectiveMutationVariables>(
    objectives.update,
    {
      onError: (error) => {
        toast.error(t('objectives:new.error.toast'));
      },
    }
  );


  return (
    <Group>

      <div>
        <Button
          iconName='chevron-left'
          color='secondary'
          onClick={() => previous(1, objective)}
        />
      </div>

      <SubGroupDiv>
        <Title level={4} title={t('objectives:result.page.title')} />

        <DivPicker>
          <OFText>{t('objectives:field.ownFunds1.label')}</OFText>
          <PercentageInput
            value={percentage}
            placeholder={"0%"}
            options={{
              decimalCharacter: ",",
              decimalCharacterAlternative: ".",
              suffixText: "%",
              digitGroupSeparator: " ",
              rawValueDivisor: 100,
            }}
            onChange={(_, value) => {
              if(value !== undefined && value !== null)
                setPercentage(value)
            }}
          />
          <OFText>{t('objectives:field.ownFunds2.label')}</OFText>
          <HelpTooltip text={t('objectives:field.ownFunds.tooltip')} />
        </DivPicker>

        <DivPicker>
          <OFText>{t('objectives:field.computation.name.label')}</OFText>
          <HighlightedText>{objective.name}</HighlightedText>
          <OFText>{t('objectives:field.computation.toBuy.label')}</OFText>
        </DivPicker>

        <DiffSizedTexts>
          <Title level={3} title={formatMonetaryAmount(objective.amount + fees, currencyCode)} color={"main"} />
          <HighlightedText>
            {t('objectives:field.computation.fees.label', {feesAmount: formatMonetaryAmount(fees, currencyCode)})}
          </HighlightedText>
        </DiffSizedTexts>

        <AmountDiv>
          <AmountGroupL>
            <LabelAmounts>{t('objectives:field.computation.amountRequired.label')}</LabelAmounts>
            <Title level={3} title={formatMonetaryAmount(requiredAmount, currencyCode)}/>
          </AmountGroupL>

          <AmountGroupR>
            <LabelAmounts>{t('objectives:field.computation.amountTargeted.label')}</LabelAmounts>
            <Title level={3} title={formatMonetaryAmount(targetedAmount, currencyCode)} />
          </AmountGroupR>
        </AmountDiv>

        <AmountDiv>
          <OFText>
            {t('objectives:field.computation.summary.label', {
              nbMonths: objectiveDelay,
              currentOwnFund: formatMonetaryAmount(futureAvailableCash, currencyCode),
              missingCash: formatMonetaryAmount(missingAmount, currencyCode)
            })}
          </OFText>
        </AmountDiv>

        <PButton
          type="submit"
          onClick={async (_) => {
            const {ownFundsPercentage, currency, scenarios, __typename, ...partialObj} = objective;
            const updatedObj = {
              ...partialObj,
              ownFundsPercentage: percentage,
              currencyCode: currency.code,
              id: objective.id
            };
            const res = await updateObjective({
              variables: {input: updatedObj},
            });
            if(!res.data || !res.data.updateObjective.data) {
              toast.error(t('objectives:new.error.toast'));
            } else {
              history.push(`/objectives/${objective.id}/scenarios`)
            }
          }}
          label={saveObjectiveButton ? t('objectives:button.saveObjective.label') : t('objectives:button.seeScenarios.label')}
        />
      </SubGroupDiv>
    </Group>
  );
}

export default BuildingObjectiveComputationForm;
