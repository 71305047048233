import {Suspense} from 'react';
import styled from "styled-components";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import {surface, textColor} from "@scriba/ui-lib";

import {LoginPage} from "../pages/LoginPage";
import {AuthenticatedRoute} from "./AuthenticatedRoute";
import {useAuth} from "../providers/AuthProvider";
import { useTidio } from '../providers/Tidio';
import routes from "../routes";
import {SignUpPage} from "../pages/SignUpPage";
import {ForgotPasswordPage} from "../pages/ForgotPasswordPage";
import {ResetPasswordPage} from "../pages/ResetPasswordPage";
import NavigationStructure from "./navigation/NavigationStructure";
import {useTranslation} from "react-i18next";

const Background = styled.div`
  width: 100%;
  color: ${textColor('main')};
  background: ${surface('page')};
`

function Main() {
  const { loading, user } = useAuth();
  const { t } = useTranslation();
  const formattedTitle = (path: string) =>
    t(`navigation:route.breadcrumb.label.${path.replaceAll(":", "_")}`, {
      username: user?.firstName,
    });
  useTidio();
  if (loading) return <div>Loading...</div>
  return (
    <Background>
      <ToastContainer />
      <Suspense fallback={"loading..."}>
        <Switch>
          {routes.map(({path, component: Component, titleInNavBar=false, withBackButton=true}, idx) => (
            <AuthenticatedRoute key={idx} exact path={path}>
              <NavigationStructure
                pageTitle={
                  titleInNavBar
                    ? formattedTitle(path)
                    : undefined
                }
                withBackButton={withBackButton}
              >
                <Component />
              </NavigationStructure>
            </AuthenticatedRoute>
          ))}
          <Route path={"/login"}>
            <LoginPage />
          </Route>
          <Route path={"/signUp"}>
            <SignUpPage />
          </Route>
          <Route path={"/forgotPassword"}>
            <ForgotPasswordPage />
          </Route>
          <Route path={"/resetPassword/:tokenId"}>
            <ResetPasswordPage />
          </Route>
          <Redirect to={"/login"} />
        </Switch>
      </Suspense>
    </Background>
  );
}

export default Main;
