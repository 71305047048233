import React, {FunctionComponent} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Title} from "@scriba/ui-lib";

import MovementInputForm from "../components/forms/MovementInputForm";
import movements, {fragments} from "../queries/movements";
import {
  GetMovementQuery,
  GetMovementQueryVariables,
  UpdateMovementMutation,
  UpdateMovementMutationVariables
} from "../generated/graphql";

export const EditMovementPage: FunctionComponent = () => {
  const {t} = useTranslation(['movements', 'common']);
  const history = useHistory();
  let { movementId } = useParams<{movementId: string}>();
  const { data } = useQuery<GetMovementQuery, GetMovementQueryVariables>(movements.get, {variables: { id: movementId }});

  const [updateMovement] = useMutation<UpdateMovementMutation, UpdateMovementMutationVariables>(
    movements.update,
    {
      update: async (cache, res) => {
        cache.modify({
          id: movementId,
          fields: {
            movements(existingAssetRefs = [], { readField }) {
              const newAssetRef = cache.writeFragment({
                data: res.data!.updateMovement.data,
                fragment: fragments.details,
              });
              return [...existingAssetRefs, newAssetRef];
            }
          }
        });
      },
    }
  )

  if (!data?.movement) return null;
  const movement = data.movement;

  return (
    <>
      <Title uppercase title={t('movements:other.edit.page.title')} level={3} />
      <MovementInputForm
        movement={movement}
        onSubmit={async (input) => {
          const {asset, ...remainder} = input
          await updateMovement({
            variables: {
              input: {
                id: movementId,
                ...remainder,
                assetId: asset.id
              }
            }
          });
          history.push('/');
        }}
      />
    </>
  )
}
