import React from 'react';
import _ from "lodash";
import {Controller, ControllerRenderProps, FieldError, FieldValues, UseFormMethods} from "react-hook-form";

import {InputRow} from '@scriba/ui-lib';

import {InputFieldProps} from "./index";
import {InputState} from "react-hook-form/dist/types";
import {useTranslation} from "react-i18next";

interface ControlledInputFieldProps<T extends FieldValues, V> extends InputFieldProps<T, V> {
  render: (field: ControllerRenderProps, state: InputState) => React.ReactElement
}

const ControlledInputField = function<T extends FieldValues, V>({defaultValue, label, name, formMethods, render, required, tooltip, registerOptions = {}, inline = false}: ControlledInputFieldProps<T, V>) {
  const {t} = useTranslation('form');
  // The forced cast is caused by what seem to be a bug in react-hook-form, but it's not really problematic here
  const { errors, control } = formMethods as unknown as UseFormMethods<Record<string, any>>;
  const error = _.get(errors, name) as FieldError | undefined;

  const fieldRequiredErrorMsg = t('field.required');
  const fullOptions = required ? {required: fieldRequiredErrorMsg, ...registerOptions} : registerOptions;
  const controller = (
    <Controller
      defaultValue={defaultValue}
      rules={fullOptions}
      name={name}
      control={control}
      render={render}
    />
  )
  if (inline)
    return controller
  return(
    <InputRow
      label={label}
      error={error?.message}
      required={required}
      tooltip={tooltip}
      renderInput={() => controller}
    />
  )
}

export default ControlledInputField;
