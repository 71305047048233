import React, {FunctionComponent, useEffect} from 'react';
import styled from "styled-components";

import {fontFamily, fontSize, space, Button, Fieldset, Input, Label, Title} from "@scriba/ui-lib";

import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useMutation} from "@apollo/client";
import {
  ChangePasswordMutation, ChangePasswordMutationVariables, UserQuery,
} from "../generated/graphql";
import users from "../queries/users";
import {useHistory, useParams} from "react-router-dom";

const InputWithUpperLabel = styled.div`
  display: grid;
  grid-row-gap: ${space('xs')}px;
`

const ErrorWrapper = styled('div')`
  grid-column: 2 / -1;
  padding: 0 ${space('sm')}px;
  font-family: ${fontFamily('sansSerif')};
  font-size: ${fontSize('label')}px;
  color: tomato;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: ${space('xxl')}px;
  margin-right: ${space('xxl')}px;
  padding-right: ${space('xxl')}px;
`

const LeftBox = styled.div`
  flex: 3;
`

const RightBox = styled.div`
  flex: 1;
`

export const ResetPasswordPage:FunctionComponent = () => {
  const {t} = useTranslation(['common', 'auth', 'form']);
  const { tokenId } = useParams<{tokenId: string}>();
  const {
    handleSubmit,
    register,
    errors,
    trigger,
    getValues,
    watch
  } = useForm<{ password: string, passwordConfirmation: string }>();
  const history = useHistory();

  const [changePassword] = useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    users.changePassword,
    {
      update: (cache, res) => {
        if (!!res.data?.changePassword?.data) {
          const userData = res.data.changePassword.data;
          cache.writeQuery<UserQuery>({
            query: users.getUser,
            data: { user: userData },
          })
        }
      },
      onError: (err) => {
        console.error(err);
        toast.error(t('auth:password_recover.changePassword.error'));
      }
    }
  )
  const password = watch("password");
  useEffect(() => {
    if(!!password && getValues('passwordConfirmation')) {
      trigger('passwordConfirmation').then();
    }
  }, [trigger, getValues, password]);

  const fieldRequiredErrorMsg = t('form:field.required');

  const submitChangePassword = async ({password}: {password: string}) => {
    const {data} = await changePassword({variables: {tokenId, password}});
    if(data?.changePassword?.error) {
      toast.error(t('auth:password_recover.changePassword.error'));
    } else {
      toast.success(t('auth:password_recover.changePassword.success'));
      history.replace('/');
    }
  }

  const validatePasswordEquals = () => {
    const password = getValues("password");
    const passwordConfirmation = getValues("passwordConfirmation");
    return password === passwordConfirmation ? undefined : t('auth:field.password.dontmatch.error')
  }

  return (
    <Container>
      <LeftBox />
      <RightBox>
        <form onSubmit={handleSubmit(submitChangePassword)}>
          <Fieldset>
            <InputWithUpperLabel>
              <Title title={t('auth:password_change.title')} level={5} />
              <Title title={t('auth:password_change.message')} level={4} />
            </InputWithUpperLabel>

            <InputWithUpperLabel>
              <Label label={t(`auth:field.password.label`)} />
              <Input placeholder={t(`auth:field.password.placeholder`)}
                     name="password"
                     type="password"
                     ref={register({required: fieldRequiredErrorMsg})}
              />
              {!!errors["password"] &&
              <ErrorWrapper>
                {errors["password"]?.message}
              </ErrorWrapper>
              }
            </InputWithUpperLabel>

            <InputWithUpperLabel>
              <Label label={t(`auth:field.passwordConfirmation.label`)} />
              <Input placeholder={t(`auth:field.passwordConfirmation.placeholder`)}
                     name="passwordConfirmation"
                     type="password"
                     ref={register({required: fieldRequiredErrorMsg, validate: validatePasswordEquals})}
              />
              {!!errors["passwordConfirmation"] &&
              <ErrorWrapper>
                {errors["passwordConfirmation"]?.message}
              </ErrorWrapper>
              }
            </InputWithUpperLabel>

            <Button type="submit" label={t('auth:password_change.button.label')} />

          </Fieldset>
        </form>
      </RightBox>
    </Container>
  );
}
