import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {useHistory} from "react-router-dom";

import {Button, Card, space, Text, Title} from "@scriba/ui-lib";
import {monthsBetween, Scenario, ScenarioType} from "@scriba/common";

import {scenarioIconName} from "../../services/objectives";
import {Objective} from "../../generated/graphql";
import {formatMonetaryAmount} from "../../services/format";

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-top: ${space('xxl')}px;
  flex-grow: 1;
  justify-content: end;
`

const DetailsContainer = styled('div')`
  padding-top: ${space('md')}px;
  display: flex;
  flex-direction: column;
`

type ScenarioCardProps = {
  scenario: Pick<Scenario, 'id' | 'scenarioType' | 'amount'>;
  objective: Pick<Objective, 'id' | 'name' | 'subType' | 'amount' | 'objectiveDate' | 'availableCash' | 'futureAvailableCash' | 'ownFundsPercentage' | 'currency'>;
}

const ScenarioCard = ({scenario, objective}: ScenarioCardProps) => {
  const {t} = useTranslation('objectives');
  const history = useHistory();
  const currency = objective.currency
  const nbMonths = monthsBetween(new Date(), objective.objectiveDate);
  const amount = scenario.amount || 0

  return (
    <Card
      onClick={() => history.push(`/objectives/${objective.id}/scenarios/${scenario.id}/${scenario.scenarioType.toLowerCase()}`)}
      footer={<Button label={t('scenario.card.chooseButton.label')} />}
      iconName={scenarioIconName(scenario)}
      contrast='high'
    >
      <Title title={t(`scenario.card.title.${scenario.scenarioType}`)} level={3}/>
      <ContentContainer>
        <Text color="main">
          {t('scenario.card.amount.label', {months: nbMonths})}
        </Text>
        <Title color="main" level={1} title={formatMonetaryAmount(amount || 0, currency.code, 0)} />
      </ContentContainer>
    </Card>
  )
}

export default ScenarioCard;
