import { FunctionComponent } from "react";
import { Card, Text, Title } from "@scriba/ui-lib";
import { IconNames } from "@scriba/ui-lib";
import { formatMonetaryAmount } from "../services/format";

export type ObjectiveOverviewCardProps = {
  iconName: IconNames;
  title: string;
  amount: number;
  currencyCode: string;
};

export const ObjectiveOverviewCard: FunctionComponent<ObjectiveOverviewCardProps> = ({
  iconName,
  title,
  amount,
  currencyCode,
}) => {
  return (
    <Card color={"main"} iconName={iconName}>
      <Text>{title}</Text>
      <Title title={formatMonetaryAmount(amount, currencyCode)} level={2} />
    </Card>
  );
};
