import React, {FunctionComponent, useEffect} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

import {Sheet, Title, Fieldset, InputRow, Button} from "@scriba/ui-lib";

import {
  GetObjectiveQuery,
  GetObjectiveQueryVariables,
  GetScenarioQuery,
  GetScenarioQueryVariables,
  UpdateScenarioMutation,
  UpdateScenarioMutationVariables,
} from "../generated/graphql";
import {ObjectiveOverviewBar} from "../components/ObjectiveOverviewBar";
import scenarios from "../queries/scenarios";
import objectives from "../queries/objective";
import FieldsAndButton from "../components/FieldsAndButton";
import MonetaryAmountInputField from "../components/fields/MonetaryAmountInputField";
import {toast} from "react-toastify";
import {monthsBetween} from "@scriba/common/dist/services/computations";
import Group from "../components/Group";

export const ObjectiveScenariosSaveMorePage: FunctionComponent = () => {
  const {t} = useTranslation(['objectives', 'form']);
  const history = useHistory();
  let { scenarioId, objectiveId } = useParams<{scenarioId: string, objectiveId: string}>();
  const { data } = useQuery<GetScenarioQuery, GetScenarioQueryVariables>(scenarios.get, {variables: { id: scenarioId }});
  const { data: objectiveData } = useQuery<GetObjectiveQuery, GetObjectiveQueryVariables>(objectives.get, {variables: { id: objectiveId }});
  const formMethods = useForm<{ amount: number | null, monthlyAmount: number | null }>({
    defaultValues: {
      amount: null,
      monthlyAmount: null,
    }
  });
  const [updateScenario] = useMutation<UpdateScenarioMutation, UpdateScenarioMutationVariables>(
    scenarios.update,
    {
      onError: (error) => {
        console.error(error)
        toast.error(t('objectives:scenario.update.error.toast'));
      },
    }
  );

  const {reset, getValues} = formMethods;
  useEffect(() => {
    if (!data?.scenario || !objectiveData?.objective) return; // loading
    const nbMonths = monthsBetween(new Date(), objectiveData.objective.objectiveDate);
    reset({
      ...getValues(),
      amount: data.scenario.amount || null,
      monthlyAmount: data.scenario.amount ? (data.scenario.amount || 0) / nbMonths : null,
    });
  }, [reset, getValues, data?.scenario, objectiveData?.objective]);

  if (!data?.scenario || !objectiveData?.objective) return null;
  const objective = objectiveData.objective;
  const nbMonths = monthsBetween(new Date(), objective.objectiveDate);

  const fields = (
    <Fieldset>
      <MonetaryAmountInputField
        currency={objective.currency}
        label={t('scenario.saveMore.amount.label', {nbMonths})}
        name={"amount"}
        required={true}
        onChange={v => {
          console.log({v});
          formMethods.setValue('monthlyAmount', (v || 0) / nbMonths)
        }}
        formMethods={formMethods} />

      <MonetaryAmountInputField
        currency={objective.currency}
        label={t('scenario.saveMore.monthlyAmount.label')}
        name={"monthlyAmount"}
        required={true}
        onChange={v => formMethods.setValue('amount', (v || 0) * nbMonths)}
        formMethods={formMethods} />
    </Fieldset>
  )

  const bottomButton = (
    <InputRow
      label={t('common:other.requiredFields')}
      required={false}
      renderInput={() => (
        <Button type="submit" label={t('form:button.save.label')}/>
      )}
    />
  );
  return (
    <Fieldset>
      <Title title={t('scenario.overview.page.title')} level={3} />
      <Sheet>
        <Fieldset>
          <ObjectiveOverviewBar objective={objective}  />
          <Group>
            <form onSubmit={formMethods.handleSubmit(async(values) => {
              await updateScenario({variables: {input: {id: scenarioId, amount: values.amount}}});
              history.push(`/objectives/${objectiveId}/scenarios`);
            })}>
              <FieldsAndButton fields={fields} button={bottomButton}/>
            </form>
          </Group>
        </Fieldset>
      </Sheet>
    </Fieldset>
  );
}
