import { ReactElement } from "react";
import styled, {ThemeProps, DefaultTheme} from "styled-components";
import {space} from "@scriba/ui-lib";


const padded = (props: ThemeProps<DefaultTheme>) => `{
  flex-direction: column;
  justify-content: space-between;
  padding-right: ${space('sm')(props)}px;
}`;

const Container = styled.div`
  display: flex;
  flex-direction: raw;
  justify-content: space-between;
  align-items: flex-start;
  div ${padded};
  span ${padded};
`;

const Number2 = styled.div`
  margin-top: ${space('xl')}px;
`;

type OneTextTwoNumbersProps = {
  text1: ReactElement;
  number1: ReactElement;
  number2: ReactElement;
}

const OneTextTwonNumbers = ({text1, number1, number2}: OneTextTwoNumbersProps) => {

  return (
    <Container>
      {text1}

      <div>
          <div>
            {number1}
          </div>
          <Number2>
            {number2}
          </Number2>
      </div>
    </Container>
  )
}

export default OneTextTwonNumbers;
