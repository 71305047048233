import React from "react";
import {useTranslation} from "react-i18next";
import { useHistory } from 'react-router-dom';

import {Button} from "@scriba/ui-lib";

import {useAuth} from "../../providers/AuthProvider";

export const LogoutButton = () => {
  const {t} = useTranslation('auth');
  const { authenticated, logout } = useAuth();
  const history = useHistory();

  if (!authenticated) return null;

  return (
    <Button label={t('signout.button.label')}
            appearance="solid"
            color="secondary"
            iconName="logout"
            iconPosition="before"
            size="md"
            onClick={async () => {
              await logout();
              history.push('/');
            }} />
  )
}
