"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textColor = exports.surface = exports.color = void 0;
var theme_accessor_1 = require("../theme-accessor");
var color = function (colorName, colorVariant) {
    return theme_accessor_1.themeAccessor(['colors', colorName, colorVariant]);
};
exports.color = color;
var surface = function (surfaceName) {
    return theme_accessor_1.themeAccessor(['surfaces', surfaceName]);
};
exports.surface = surface;
var textColor = function (colorName) {
    return theme_accessor_1.themeAccessor(['textColors', colorName]);
};
exports.textColor = textColor;
