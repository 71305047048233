import React, {
  ChangeEvent,
  FunctionComponent, InputHTMLAttributes,
  SyntheticEvent,
  useEffect,
  useRef
} from "react"
import AutoNumeric, {Options} from "autonumeric"

import {Input} from '@scriba/ui-lib';

type EventName = "onChange" | "onFocus" | "onBlur" | "onKeyPress" | "onKeyUp" | "onKeyDown";

interface AutoNumericInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  type?: "text" | "tel" | "hidden";
  value?: string | number;
  options?: Options
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: number | null | undefined) => void
}

export const AutoNumericInput: FunctionComponent<AutoNumericInputProps> = (props) => {
  const {value, options, onChange, ...otherProps} = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const autonumericRef = useRef<AutoNumeric | null>(null);

  const getValue = () => autonumericRef.current?.getNumber();

  useEffect(() => {
    if (inputRef.current) {
      autonumericRef.current = new AutoNumeric(inputRef.current, value, options);
    }
  }, [inputRef]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (autonumericRef.current) {
      if (value !== getValue()) {
        autonumericRef.current?.set(value || null);
      }
    }
  }, [value]);

  useEffect(() => {
    // Anything in here is fired on component mount.
    return () => {
      // Anything in here is fired on component unmount.
      autonumericRef.current?.remove();
    }
  }, [])

  function callEventHandler<E extends SyntheticEvent>(event: E, eventName: EventName, v?: number | null) {
    const eventHandler = props[eventName] as ((event: E, value?: number | null) => void) | undefined;
    if (!eventHandler) return
    eventHandler(event, v)
  }

  return (
    <Input
      ref={inputRef}
      onChange={e => callEventHandler(e, "onChange", getValue())}
      onFocus={e => callEventHandler(e, "onFocus")}
      onBlur={e => callEventHandler(e, "onBlur")}
      onKeyPress={e => callEventHandler(e, "onKeyPress")}
      onKeyUp={e => callEventHandler(e, "onKeyUp")}
      onKeyDown={e => callEventHandler(e, "onKeyDown")}
      {...otherProps}
    />
  )
}
