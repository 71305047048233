"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LightTheme = void 0;
var colorMaps_1 = require("./colors/colorMaps");
var CommonTheme_1 = require("./CommonTheme");
var teal = colorMaps_1.colorMaps.teal, grey = colorMaps_1.colorMaps.grey;
exports.LightTheme = CommonTheme_1.createTheme({
    name: "Light",
    colors: {
        main: teal,
        secondary: grey,
    },
    surfaces: {
        page: "#f9f9f9",
        sheet: "#ececcf",
    },
    textColors: {
        contrast: '#000',
        match: '#fff',
        main: teal.base,
        secondary: grey.base,
        neutral: grey.dark,
    }
});
