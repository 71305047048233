import React from "react";
import {FieldError, FieldValues} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {InputRow, Select} from '@scriba/ui-lib';

import {InputFieldProps} from "./index";

type ReactOptionElement = React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>;

export interface SelectInputFieldProps<T extends FieldValues> extends InputFieldProps<T, readonly string[]> {
  options?: ReactOptionElement[];
}

const valueFromSelectElement = (element: HTMLSelectElement) => {
  const selection = element.selectedOptions;
  let values:string[] = [];
  for (let i = 0 ; i < selection.length ; i++) {
    values = [...values, selection[i].value]
  }
  return values;
}

const SelectInputField = function <T extends FieldValues>({label, name, formMethods, options, required, tooltip, onChange, registerOptions = {}, inline=false, ...otherProps}: SelectInputFieldProps<T>) {
  const {t} = useTranslation('form');
  const { errors, register } = formMethods;
  const error = errors[name] as FieldError | undefined;

  const fieldRequiredErrorMsg = t('field.required');
  const fullOptions = required ? {required: fieldRequiredErrorMsg, ...registerOptions} : registerOptions;
  const input = (
    <Select name={name}
            {...otherProps}
            onChange={(e) => {
              if(onChange) {
                onChange(valueFromSelectElement(e.target));
              }
            }}
            ref={register(fullOptions)}>
      <option value="" selected={true} disabled={required}>{label}</option>
      {options}
    </Select>
  )
  if (inline) return input;
  return (
    <InputRow
      label={label}
      error={error?.message}
      required={required}
      tooltip={tooltip}
      renderInput={() => input}
    />
  )
}

export default SelectInputField;
