import React, {InputHTMLAttributes} from "react";
import {FieldError, FieldValues} from "react-hook-form";

import {InputRow, Input} from '@scriba/ui-lib';

import {InputFieldProps} from "./index";
import {useTranslation} from "react-i18next";

export interface TextInputFieldProps<T extends FieldValues> extends InputFieldProps<T, string | number | ReadonlyArray<string>>, Omit<InputHTMLAttributes<any>, 'onChange'> {
  name: string;
  required: boolean;
}

const TextInputField = function <T extends FieldValues>({label, name, formMethods, tooltip, onChange, registerOptions = {}, required, ...otherProps}: TextInputFieldProps<T>) {
  const {t} = useTranslation('form');
  const { errors, register } = formMethods;
  const error = errors[name] as FieldError | undefined;

  const fieldRequiredErrorMsg = t('field.required');
  const fullOptions = required ? {required: fieldRequiredErrorMsg, ...registerOptions} : registerOptions;
  return (
    <InputRow
      label={label}
      error={error?.message}
      required={required}
      tooltip={tooltip}
      renderInput={() => (
        <Input placeholder={label}
               name={name}
               {...otherProps}
                onChange={e => {
                  if (onChange) {
                    onChange(e.target.value)
                  }
                }}
               ref={register(fullOptions)}
        />
      )}
    />
  )
}

export default TextInputField;
