"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.currencySymbolPosition = exports.symbolFromCurrencyCode = void 0;
var symbolFromCurrencyCode = function (currencyCode, locale) {
    var _a;
    var nf = Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode });
    return (_a = nf
        .formatToParts(1)
        .find(function (p) { return p.type === 'currency'; })) === null || _a === void 0 ? void 0 : _a.value;
};
exports.symbolFromCurrencyCode = symbolFromCurrencyCode;
var currencySymbolPosition = function (currencyCode, locale) {
    var nf = Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode });
    var matchingIdx = nf
        .formatToParts(1)
        .findIndex(function (p) { return p.type === 'currency'; });
    if (matchingIdx < 0)
        return undefined;
    if (matchingIdx === 0)
        return 'prefix';
    return 'suffix';
};
exports.currencySymbolPosition = currencySymbolPosition;
