"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTheme = exports.commonTheme = exports.gridSize = void 0;
var typeScale_1 = require("./typography/typeScale");
var fonts_1 = require("./typography/fonts");
var layout_1 = require("./layout");
exports.gridSize = 8;
exports.commonTheme = {
    gridSize: exports.gridSize,
    spacers: {
        xs: exports.gridSize / 2,
        sm: exports.gridSize,
        md: 3 * exports.gridSize / 2,
        lg: 2 * exports.gridSize,
        xl: 3 * exports.gridSize,
        xxl: 9 * exports.gridSize / 2,
    },
    iconSizes: layout_1.ICON_SIZES,
    sidebarWidth: exports.gridSize * 24,
    borderWidths: {
        sm: 1,
        md: 2,
        lg: 4,
    },
    borderRadiuses: {
        sm: 6,
        md: 12,
        lg: 18,
        xl: 24,
        xxl: 32,
    },
    fontSizes: typeScale_1.typeScale,
    fontFamilies: {
        sansSerif: fonts_1.systemStack,
    },
};
var createTheme = function (themeOptions) { return (__assign(__assign({}, exports.commonTheme), themeOptions)); };
exports.createTheme = createTheme;
