import {ApolloCache, gql, useMutation} from "@apollo/client";
import {ApolloError} from "@apollo/client/errors";
import {useCallback} from "react";

import {
  ListIncomesQuery,
  IncomeDetailsFragmentFragment,
  RemoveIncomeMutation,
  CreateIncomeMutationMutation,
  IncomeCreate,
} from "../generated/graphql";
import {typify} from "@scriba/common/dist/services/helpers";

export const fragments = {
  details: gql`
    fragment IncomeDetailsFragment on Income {
      id
      incomeType
      amount
      currency {
        code
        rateToEuro
      }
      periodicity
    }
  `,
}

const queries = {
  list: gql`
    query ListIncomes {
      incomes {
        ...IncomeDetailsFragment
      }
    }
    ${fragments.details}
  `,
  create: gql`
    mutation CreateIncomeMutation($input: IncomeCreate!) {
      createIncome(input: $input) {
        data {
          ...IncomeDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  update: gql`
    mutation UpdateIncome($input: IncomeUpdate!) {
      updateIncome(input: $input) {
        data {
          ...IncomeDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  remove: gql`
    mutation RemoveIncome($id: ID!) {
      removeIncome(id: $id) {
        data {
          ...IncomeDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
}

export default queries;

export const removeIncomeFromList = <T>(cache: ApolloCache<T>, filter: (income: IncomeDetailsFragmentFragment) => boolean) => {
  try {
    const data = cache.readQuery<ListIncomesQuery>({query: queries.list});
    cache.writeQuery({
      query: queries.list,
      data: data && {
        incomes: data.incomes.filter(filter),
      },
    });
  } catch (err) {
    console.error({err})
  }
};

export function useRemoveIncome({onError, onCompleted}:{onError: (error: ApolloError) => void, onCompleted: (data: RemoveIncomeMutation) => void}) {
  const [ removeIncome ] = useMutation(queries.remove, {
    onError,
    update: (cache, res) => {
      if (res?.data?.removeIncome?.data) {
        const objToRemove = res.data.removeIncome.data;
        removeIncomeFromList(cache, a => a.id !== objToRemove.id);
      }
    },
    onCompleted,
  });
  return useCallback((id) => {
    return removeIncome({
      variables: {id},
    });
  }, [removeIncome]);
}

export function useCreateIncome({onError, onCompleted}:{onError: (error: ApolloError) => void, onCompleted?: (data: CreateIncomeMutationMutation) => void}) {
  const [ createIncome ] = useMutation<CreateIncomeMutationMutation>(queries.create, {
    onError,
    update: (cache, res) => {
      if (res?.data?.createIncome?.data) {
        const objToAdd = res.data.createIncome.data;
        try {
          const data = cache.readQuery<ListIncomesQuery>({query: queries.list});
          const cacheId = cache.identify(typify(objToAdd))
          cache.writeFragment({
            id: cacheId,
            fragment: fragments.details,
            fragmentName: "IncomeDetailsFragment",
            data: objToAdd,
          });
          cache.writeQuery({
            query: queries.list,
            data: data && {
              incomes: [...data.incomes, objToAdd],
            },
          });
        } catch (err) {
          console.error({err})
        }
      }
    },
    onCompleted,
  });
  return useCallback((income: IncomeCreate) => {
    return createIncome({
      variables: {input: income},
    });
  }, [createIncome]);
}
