import React, {FunctionComponent} from 'react';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

import {Movement, MovementPeriod} from "@scriba/common";

import MovementInputForm from "../components/forms/MovementInputForm";
import {useCreateMovement} from "../queries/movements";
import {Title} from '@scriba/ui-lib';

export const CreateMovementPage: FunctionComponent = () => {
  const {t} = useTranslation(['movements', 'common']);
  const history = useHistory();
  const createMovement = useCreateMovement({
    onError: (err) => {
      console.error(err);
      toast.error(t('assets:loan.new.error.toast'));
    },
  })

  const defaultMovement:Partial<Movement> = {
    startDate: new Date(),
    period: MovementPeriod.once,
  }

  return (
    <>
      <Title uppercase title={t('movements:other.create.page.title')} level={3} />
      <MovementInputForm
        movement={defaultMovement}
        onSubmit={async (input) => {
          const {asset, ...remainder} = input
          await createMovement({
            ...remainder,
            currencyCode: "EUR", //TODO
            assetId: asset.id,
          });
          history.push('/');
        }}
      />
    </>
  )
}
