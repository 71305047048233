"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortBySubType = exports.otherAssetSubTypesList = exports.OtherAssetSubType = void 0;
var OtherAssetSubType;
(function (OtherAssetSubType) {
    OtherAssetSubType["bankAccount"] = "bankAccount";
    OtherAssetSubType["stocks"] = "stocks";
    OtherAssetSubType["realty"] = "realty";
    OtherAssetSubType["financialInsurance"] = "financialInsurance";
    OtherAssetSubType["pension"] = "pension";
    OtherAssetSubType["crypto"] = "crypto";
    OtherAssetSubType["unlistedStocks"] = "unlistedStocks";
    OtherAssetSubType["professionalAssets"] = "professionalAssets";
    OtherAssetSubType["artwork"] = "artwork";
    OtherAssetSubType["collectionVehicle"] = "collectionVehicle";
    OtherAssetSubType["other"] = "other";
})(OtherAssetSubType = exports.OtherAssetSubType || (exports.OtherAssetSubType = {}));
exports.otherAssetSubTypesList = Object.keys(OtherAssetSubType);
var sortBySubType = function (asset1, asset2) {
    var asset1Index = exports.otherAssetSubTypesList.indexOf(asset1.subType);
    var asset2Index = exports.otherAssetSubTypesList.indexOf(asset2.subType);
    return asset1Index - asset2Index;
};
exports.sortBySubType = sortBySubType;
