import React, {FunctionComponent, useEffect} from "react";
import {RegisterOptions, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {toast} from "react-toastify";
import {useMutation} from "@apollo/client";

import {isEmail} from "@scriba/common";
import {fontFamily, fontSize, space, Button, Input, Fieldset, Label, Title} from "@scriba/ui-lib";

import ButtonLink from "../navigation/ButtonLink";
import {
  SignUpMutation,
  SignUpMutationVariables,
  UserQuery
} from "../../generated/graphql";
import users from "../../queries/users";

type SignUpInputs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

interface SignUpFormProps {
  onSignUp: () => Promise<void>
}

const InputWithUpperLabel = styled.div`
  display: grid;
  grid-row-gap: ${space('xs')}px;
`

const ErrorWrapper = styled('div')`
  grid-column: 2 / -1;
  padding: 0 ${space('sm')}px;
  font-family: ${fontFamily('sansSerif')};
  font-size: ${fontSize('label')}px;
  color: tomato;
`;

const SignUpFrom: FunctionComponent<SignUpFormProps> = (props) => {
  const {onSignUp} = props;
  const {t} = useTranslation(['common', 'auth', 'form']);
  const {handleSubmit, register, errors, getValues, trigger, watch} = useForm<SignUpInputs>();
  const fieldRequiredErrorMsg = t('form:field.required');
  const [signup] = useMutation<SignUpMutation, SignUpMutationVariables>(
    users.signup,
    {
      update: (cache, res) => {
        if (!!res.data?.signup?.data) {
          const userData = res.data.signup.data;
          cache.writeQuery<UserQuery>({
            query: users.getUser,
            data: { user: userData },
          })
        }
      }
    }
  )
  const emailValidationError = t(`form:email.field.validation.error`);
  const password = watch("password");
  useEffect(() => {
    if(!!password && getValues('passwordConfirmation')) {
      trigger('passwordConfirmation').then();
    }
  }, [trigger, getValues, password]);
  const signUpWithForm = async ({passwordConfirmation, ...input}: SignUpInputs) => {
    const {data} = await signup({variables: input})
    if (data?.signup.data) {
      await onSignUp();
    } else if (data?.signup?.error) {
      toast.error(t(`auth:signUp.error.${data.signup.error}`));
    }
  }

  const fieldForName = (name: keyof SignUpInputs, isPassword: boolean = false, rules:RegisterOptions={}) => (
    <InputWithUpperLabel>
      <Label label={t(`auth:field.${name}.label`)} />
      <Input placeholder={t(`auth:field.${name}.placeholder`)}
             name={name}
             {...(isPassword ? {type: "password"} : {})}
             ref={register({required: fieldRequiredErrorMsg, ...rules})}
      />
      {!!errors[name] &&
        <ErrorWrapper>
          {errors[name]?.message}
        </ErrorWrapper>
      }
    </InputWithUpperLabel>
  )

  const validatePasswordEquals = () => {
    const password = getValues("password");
    const passwordConfirmation = getValues("passwordConfirmation");
    return password === passwordConfirmation ? undefined : t('auth:field.password.dontmatch.error')
  }

  return (
      <form onSubmit={handleSubmit(signUpWithForm)}>
        <Fieldset>
          <InputWithUpperLabel>
            <Title title={t('auth:login.form.welcome.title')} level={5} />
            <Title title={t('auth:signUp.form.title')} level={4} />
          </InputWithUpperLabel>

          {fieldForName('lastName')}
          {fieldForName('firstName')}
          {fieldForName('email', false, {validate: (v) => isEmail(v) ? undefined : emailValidationError})}
          {fieldForName('password', true, {}, )}
          {fieldForName('passwordConfirmation', true, {validate: validatePasswordEquals})}

          <Button type="submit" label={t('auth:signUp.button.label')} />

          <InputWithUpperLabel>
            <Title title={t('auth:signUp.form.login.title')} level={5} />
            <ButtonLink to={'/login'} label={t('auth:signUp.form.login.button')} appearance={"outline"} />
          </InputWithUpperLabel>


        </Fieldset>
      </form>
  );

}

export default SignUpFrom;
