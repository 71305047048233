import {FunctionComponent} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

import { Text, Button, color, space, textColor, Title } from "@scriba/ui-lib";

import {formatMonetaryAmount} from "../../services/format";
import { ObjectiveDetailsFragmentFragment } from "../../generated/graphql";
import Group from "../Group";

const LabelAmounts = styled(Text)`
  color: ${color('secondary', 'lightest')};
  padding-bottom: ${space('sm')}px;
`;

const AmountGroupL = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  padding-right: ${space('xxl')}px;
  padding-top: ${space('md')}px;
  padding-bottom: ${space('md')}px;
`;

const AmountGroupR = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-left: ${space('xxl')}px;
  padding-top: ${space('md')}px;
  padding-bottom: ${space('md')}px;
  border-left: 2px solid ${textColor('main')};
`;

const PButton = styled(Button)`
  margin-top: ${space('xl')}px;
`;

const SubGroupDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  `;

const AmountDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  `;

interface ObjectiveInputFormProps {
  objective: ObjectiveDetailsFragmentFragment;
  previous: Function
  saveObjectiveButton?: Boolean
}

const AmountObjectiveComputationForm: FunctionComponent<ObjectiveInputFormProps> = ({objective, previous, saveObjectiveButton=false}) => {
  const {t} = useTranslation(['objectives', 'common', 'form']);
  const history = useHistory();
  const totalAmount = objective.amount || 0;
  const futureAvailableCash = objective.futureAvailableCash || 0;
  const currencyCode = objective.currency.code || 'EUR';
  const requiredAmount = totalAmount;
  const missingAmount = totalAmount - futureAvailableCash;

  return (
    <Group>
      <div>
        <Button
          iconName='chevron-left'
          color='secondary'
          onClick={() => previous(1, objective)}
        />
      </div>

      <SubGroupDiv>
        <Title level={4} title={t('objectives:result.page.title')} />

        <AmountDiv>
          <AmountGroupL>
            <LabelAmounts children={(<>{t('objectives:result.totalRequiredAmount.label')}</>)} />
            <Title level={3} title={formatMonetaryAmount(requiredAmount, currencyCode)}/>
          </AmountGroupL>

          <AmountGroupR>
            <LabelAmounts children={(<>{t('objectives:result.missingAmount.label')}</>)} />
            <Title level={3} title={formatMonetaryAmount(missingAmount, currencyCode)} />
          </AmountGroupR>
        </AmountDiv>

        <PButton type="submit"
                 onClick={() => history.push(`/objectives/${objective.id}/scenarios`)}
                 label={saveObjectiveButton ? t('objectives:button.saveObjective.label') : t('objectives:button.seeScenarios.label')}
        />
      </SubGroupDiv>
    </Group>
  );
}

export default AmountObjectiveComputationForm;
