import {FunctionComponent, useState, useEffect} from "react";
import {useForm, useFieldArray} from "react-hook-form";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {toast} from "react-toastify";
import {useMutation} from "@apollo/client";
import {otherAssetSubTypesList} from "@scriba/common"

import {
  Button,
  color,
  InputRow,
  Fieldset,
  Sheet,
  space,
  SwitchGroup,
  TableFooter,
  TableFooterButton,
  Title,
} from "@scriba/ui-lib";
import {
  grossValue,
  Loan,
  OtherAssetSubType,
} from "@scriba/common";

import {FormInput} from "./types";
import { StyledForm } from "./StyledForm";
import { BottomButton } from "./BottomButton";
import { LoanFieldsets } from "./LoanFieldsets";
import TextInputField from "../fields/TextInputField";
import SelectInputField from "../fields/SelectInputField";
import MonetaryAmountInputField from "../fields/MonetaryAmountInputField";
import DatePickerField from "../fields/DatePickerField";
import PercentageInputField from "../fields/PercentageInputField";

import loans, {useCreateLoan, useRemoveLoan} from "../../queries/loans";
import {
  OtherAssetDetailsFragmentFragment,
  UpdateLoanMutation,
  UpdateLoanMutationVariables
} from "../../generated/graphql";
import {ComputingAsset} from "@scriba/common/dist/models/assets";

export type OtherAssetInputs = {
  name: string;
  subType: OtherAssetSubType;
  currentValue?: number | null;
  acquisitionValue?: number | null;
  acquisitionDate: Date;
  evolutionRate?: number | null;
  sharePercentage?: number | null;
  currencyCode: string;
  loans?: Loan[]
};

type OtherAssetSubTypeListType = keyof typeof OtherAssetSubType;

const evolutionRates: Record<OtherAssetSubTypeListType, number> = {
  artwork: 0,
  bankAccount: 0,
  collectionVehicle: 0,
  crypto: 0,
  unlistedStocks: 0,
  financialInsurance: 0.015,
  pension: 0.015,
  professionalAssets: 0,
  realty: 0.025,
  stocks : 0.025,
  other : 0,
}

interface OtherAssetInputFormProps extends FormInput<OtherAssetInputs> {
  asset?: Partial<OtherAssetDetailsFragmentFragment>
}

const LoansGroup = styled('div')`
  margin-top: ${space('xl')}px;
`

const StyledTableFooter = styled(TableFooter)`
  margin-top: ${space('xl')}px;
  border-color: ${color('secondary', 'darker')};
`

const OtherAssetInputFrom: FunctionComponent<OtherAssetInputFormProps> = (props) => {
  const {onSubmit, asset} = props;
  const {t} = useTranslation(['assets', 'common', 'form']);
  const formMethods = useForm<OtherAssetInputs>({
    defaultValues: {
      ...asset,
      ...(asset?.id ? {currentValue: grossValue(asset as ComputingAsset)} : {}),
    }
  });
  const defaultLoan = {
    amount: null,
    yearlyRate: null,
    monthlyAmount: null,
    subscriptionDate: null,
    sharePercentage: 1,
    loanSource: "other",
    loanType: "other",
    repayment: "equal_monthly",
  };
  const {handleSubmit, control, formState, watch, setValue} = formMethods;
  const { dirtyFields } = formState;
  const { fields: loanFields, append: appendLoan, remove: removeLoanFromFields } = useFieldArray({
    keyName: "fieldId",
    control,
    name: "loans"
  });
  const [hasLoans, setHasLoans] = useState(loanFields && loanFields.length > 0);
  const removeLoanFromGql = useRemoveLoan ({
      onError: () => {
        toast.error(t('assets:loan.remove.error.toast'));
      },
      onCompleted: () => {
        toast.success(t('assets:loan.remove.success.toast'));
      }
    }
  )

  const deleteLoan = (id: string, index: number) => async () => {
    if (loanFields.length === 1) {
      setHasLoans(false);
    }
    if (!!id) {
      await removeLoanFromGql(id);
    }
    removeLoanFromFields(index);
  }

  const createLoan = useCreateLoan({
    onError: (err) => {
      console.error(err);
      toast.error(t('assets:loan.new.error.toast'));
    },
  });
  const [updateLoan] = useMutation<UpdateLoanMutation, UpdateLoanMutationVariables>(
    loans.update,
    {
      onError: (err) => {
        console.error(err);
        toast.error(t('assets:loan.update.error.toast'));
      },
    }
  )
  const currency = {code: 'EUR', rateToEuro: 1}; //TODO to replace with a picker when we handle multiple currencies

  const updateLoansBeforeSubmit = async ({loans, ...asset}: OtherAssetInputs) => {
    const dirtyLoans = dirtyFields.loans;
    const assetId = props.asset?.id
    const isLoanDirty = (idx: number) => !dirtyLoans ? false : Object.values(dirtyLoans[idx] || {}).includes(true)
    const isLoanNew = (idx: number) => !loanFields[idx].id;

    if(!!assetId) {
      // Asset is not new, we should update/create loans there
      await Promise.all(loans?.map(async(loan, idx) => {
        const isNew = isLoanNew(idx);
        const isDirty = isLoanDirty(idx);
        if(isNew) {
          await createLoan({...loan, assetId});
        } else if (isDirty) {
          await updateLoan({variables: {input: {...loan, id: loanFields[idx].id!}}})
        }
      }) || [Promise.resolve()])
    }
    return onSubmit({
      ...asset,
      currencyCode: currency.code,
      ...(hasLoans ? {loans} : {}),
    });
  }

  const selectedSubType = watch('subType');
  useEffect(() => {
    const defaultEvolutionRate = evolutionRates[selectedSubType] || 0;
    const evolutionRate = asset?.evolutionRate || defaultEvolutionRate;

    setValue('evolutionRate', evolutionRate);
  }, [selectedSubType, setValue, asset]);

  return (
    <Sheet>
      <Title level={4} title={t('assets:other.form.title')} />
      <StyledForm onSubmit={handleSubmit(updateLoansBeforeSubmit)}>
        <Fieldset>

          <SelectInputField
            label={t('assets:field.subtype.label')}
            name="subType"
            formMethods={formMethods}
            required={true}
            options={otherAssetSubTypesList.map(subType => (
              <option key={subType} value={subType}>{t(`assets:other.subType.${subType}`)}</option>
            ))}
          />

          <TextInputField
            label={t('assets:field.name.label')}
            name="name"
            formMethods={formMethods}
            required={true}
          />

          <MonetaryAmountInputField
            currency={currency}
            label={t('assets:field.acquisitionValue.label')}
            name="acquisitionValue"
            tooltip={t('assets:field.acquisitionValue.tooltip')}
            formMethods={formMethods}
            registerOptions={{
              validate: (val) => {
                const currentValue = formMethods.getValues('currentValue');
                if (currentValue === undefined ||  currentValue === null) {
                  return val || t('assets:field.current.acquisition.value.required')
                }
                return undefined;
              }
            }}
            required={false}
          />

          <MonetaryAmountInputField
            currency={currency}
            label={t('assets:field.currentValue.label')}
            name="currentValue"
            tooltip={t('assets:field.currentValue.tooltip')}
            formMethods={formMethods}
            registerOptions={{
              validate: (val) => {
                const acquisitionValue = formMethods.getValues('acquisitionValue');
                if (acquisitionValue === undefined ||  acquisitionValue === null) {
                  return val || t('assets:field.current.acquisition.value.required')
                }
                return undefined;
              }
            }}
            required={false}
          />

          <DatePickerField
            label={t('assets:field.acquisitionDate.label')}
            name="acquisitionDate"
            tooltip={t('assets:field.acquisitionDate.tooltip')}
            formMethods={formMethods}
            required={false}
          />

          <PercentageInputField
            label={t('assets:field.evolutionRate.label')}
            name="evolutionRate"
            tooltip={t('assets:field.evolutionRate.tooltip')}
            formMethods={formMethods}
            required={false}
            defaultValue={asset?.evolutionRate || evolutionRates[selectedSubType] || null}
          />

          <PercentageInputField
            label={t('assets:field.sharePercentage.label')}
            name="sharePercentage"
            tooltip={t('assets:field.sharePercentage.tooltip')}
            formMethods={formMethods}
            required={false}
          />

          <InputRow
            label={t('assets:field.loan.switch.label')}
            required={false}
            renderInput={() => (
              <SwitchGroup>
                <Button
                  label={t('common:switch.label.yes')}
                  appearance={hasLoans ? 'solid' : 'transparent'}
                  onClick={() => {
                    setHasLoans(true);
                    if(!loanFields || loanFields.length === 0) {
                      appendLoan(defaultLoan);
                    }
                  }}
                />
                <Button
                  label={t('common:switch.label.no')}
                  appearance={hasLoans ? 'transparent' : 'solid'}
                  onClick={() => setHasLoans(false)}
                />
              </SwitchGroup>
            )}
          />
        </Fieldset>

        {hasLoans && (
          <LoansGroup>
          {loanFields.map((loanField, idx) => {
            return (
              <>
                <LoanFieldsets
                  key={loanField.fieldId}
                  loan={loanField}
                  idx={idx}
                  formMethods={formMethods}
                  currency={currency}
                  deleteLoan={deleteLoan}
                />
                {idx + 1 === loanFields.length ? (
                  <StyledTableFooter>
                    <TableFooterButton
                      onClick={() => appendLoan(defaultLoan)}
                      label={t("assets:new.loan.button.label")}
                      iconColorVariant="darker"
                    />
                  </StyledTableFooter>
                ) : null}
              </>
            );
          })}
          </LoansGroup>
        )}

        <BottomButton>
          <Button type="submit" label={t('form:button.submit.label')} />
        </BottomButton>
      </StyledForm>
    </Sheet>
  );

}

export default OtherAssetInputFrom;
