import {FunctionComponent} from "react";
import styled from "styled-components";

import { fixFirefoxFlexScroll, getSidebarWidth, space } from "@scriba/ui-lib";

import SideBar from "./SideBar";
import NavigationBar from "./NavigationBar";

const RightContainer = styled('div')`
  display: flex;
  ${fixFirefoxFlexScroll()};
  height: 100%;
  flex-direction: column;
  margin-left: ${getSidebarWidth()};
`

const MainContainer = styled('div')`
  display: flex;
  ${fixFirefoxFlexScroll()};
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  padding: ${space('xxl')}px;
`

interface NavigationStructureProps {
  pageTitle?: string,
  withBackButton?: boolean,
}

const NavigationStructure: FunctionComponent<NavigationStructureProps> = ({pageTitle, withBackButton, children }) => {
  return (
    <>
      <SideBar/>
      <RightContainer>
        <NavigationBar pageTitle={pageTitle} withBackButton={withBackButton}/>
        <MainContainer>
          {children}
        </MainContainer>
      </RightContainer>
    </>
  )
}

export default NavigationStructure;
