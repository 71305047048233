import {FunctionComponent} from "react";
import {useQuery} from "@apollo/client";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import styled from "styled-components";

import {Currency, netValue, otherAssetSubTypesList} from '@scriba/common';
import {
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
  TableFooter,
  TableFooterButton,
  ValueCardMain,
} from "@scriba/ui-lib";

import assets from "../queries/assets";
import {formatMonetaryAmount} from "../services/format";
import {ListAssetsQuery,} from "../generated/graphql";
import {HelpTooltip} from "@scriba/ui-lib/dist/components/HelpTooltip";

const RightAlignTd = styled(Td)`
  text-align: right;
`

const RightAlignTh = styled(Th)`
  text-align: right;
`

export const CollapsedAssetsList: FunctionComponent = () => {
  const {t} = useTranslation(['assets', 'common']);
  const history = useHistory();
  const { data } = useQuery<ListAssetsQuery>(assets.list);
  const collapsed : {[id: string]: {netValue: number, netValue10y: number, netValue20y: number, currency: Pick<Currency, 'code' | 'rateToEuro'>};} = {};

  if (data) {
    data.assets.forEach(asset => {
      const subType = asset.subType
      if (! (subType in collapsed)) {
        collapsed[subType] = {netValue: 0, netValue10y: 0, netValue20y: 0, currency: asset.currency};
      }
      collapsed[subType].netValue += netValue(asset);
      const rate = collapsed[subType].currency.rateToEuro; // TODO currency 'EUR'
      collapsed[subType].netValue10y += netValue(asset, dayjs().add(10, 'years').toDate()) * rate;
      collapsed[subType].netValue20y += netValue(asset, dayjs().add(20, 'years').toDate()) * rate;
    })
  }

  return (
    <>
      {!!data && (
        <div>
          <Table style={{ tableLayout: "fixed" }}>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <THead>
              <Tr>
                <Th>{t("assets:table.header.subType")}</Th>
                <RightAlignTh>
                  {t("assets:table.header.netValue")}&nbsp;
                  <HelpTooltip text={t("assets:table.header.netValue.tooltip")} />
                </RightAlignTh>
                <RightAlignTh>
                  {t("assets:table.header.netValue.10years")}&nbsp;
                  <HelpTooltip text={t("assets:table.header.netValue.10years.tooltip")} />
                </RightAlignTh>
                <RightAlignTh>{t("assets:table.header.netValue.20years")}</RightAlignTh>
              </Tr>
            </THead>
            <TBody>
              {otherAssetSubTypesList.map((subType) => {
                return subType in collapsed ? (
                  <Tr key={subType}>
                    <Td>
                      <ValueCardMain label={t(`assets:other.subType.${subType}`)} />
                    </Td>
                    <RightAlignTd>{formatMonetaryAmount(collapsed[subType].netValue, "EUR", 0)}</RightAlignTd>
                    <RightAlignTd>{formatMonetaryAmount(collapsed[subType].netValue10y, "EUR", 0)}</RightAlignTd>
                    <RightAlignTd>{formatMonetaryAmount(collapsed[subType].netValue20y, "EUR", 0)}</RightAlignTd>
                  </Tr>
                ) : null;
              })}
            </TBody>
          </Table>
          <TableFooter>
            <TableFooterButton
              label={t("assets:new.other.button.label")}
              onClick={() => history.push("/assets/other/new")}
              data-pendo-id="add-asset"
            />
          </TableFooter>
        </div>
      )}
    </>
  );
}
