"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MovementPeriod = void 0;
var MovementPeriod;
(function (MovementPeriod) {
    MovementPeriod["month"] = "month";
    MovementPeriod["week"] = "week";
    MovementPeriod["year"] = "year";
    MovementPeriod["once"] = "once";
})(MovementPeriod = exports.MovementPeriod || (exports.MovementPeriod = {}));
