import React from 'react';
import {FieldValues} from "react-hook-form";
import i18next from "i18next";

import {Currency, symbolFromCurrencyCode, currencySymbolPosition} from "@scriba/common";

import {InputFieldProps} from "./index";
import ControlledInputField from "./ControlledInputField";
import {AutoNumericInput} from "../AutoNumericInput";

export interface MonetaryAmountInputFieldProps<T extends FieldValues> extends InputFieldProps<T, number | null | undefined> {
  currency: Pick<Currency, 'code'>,
}

const MonetaryAmountInputField = function<T extends FieldValues>(props: MonetaryAmountInputFieldProps<T>) {
  const { label, currency, disabled } = props;
  const currencySymbol = symbolFromCurrencyCode(currency.code, i18next.language);
  const symbolPosition = currencySymbolPosition(currency.code, i18next.language);
  return (
    <ControlledInputField
      render={({ onChange, value }) => (
        <AutoNumericInput
          value={value}
          placeholder={label}
          options={{
            currencySymbol,
            decimalCharacter: ",",
            decimalCharacterAlternative: ".",
            currencySymbolPlacement: symbolPosition === 'prefix' ? 'p' : 's',
            digitGroupSeparator: " ",
            emptyInputBehavior: "null",
          }}
          onChange={(event, value)=> {
            onChange(value);
            if(props.onChange) {
              props.onChange(value);
            }
          }}
          disabled={disabled}
        />
      )}
      {...props}
    />
  )
}

export default MonetaryAmountInputField;
