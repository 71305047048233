import {FunctionComponent} from "react";
import {useQuery} from "@apollo/client";
import styled from "styled-components";
import {useHistory, useParams, Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { sum } from "lodash";

import {
  borderRadius,
  borderWidth,
  Button,
  color,
  getGridSize,
  Icon,
  space,
  Sheet,
  Text,
  textColor,
  Title,
} from "@scriba/ui-lib";

import objectives, { useRemoveObjective } from "../queries/objective";
import {
  GetObjectiveQuery,
  GetObjectiveQueryVariables,
} from "../generated/graphql";
import { formatMonetaryAmount } from "../services/format";
import {ObjectiveOverviewBar} from "../components/ObjectiveOverviewBar";
import { ObjectiveOverviewCard } from "../components/ObjectiveOverviewCard";
import ScenarioCard from "../components/scenarios/ScenarioCard";
import { toast } from "react-toastify";
import {sortScenarios} from "../services/objectives";
import {useConfirmation} from "../providers/ConfirmDialogProvider";

const CardsContainer = styled(Sheet)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${getGridSize(30)}, 1fr));
  column-gap: ${space('xl')}px;
  row-gap: ${space('xxl')}px;
`;

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${space('lg')}px;
  padding-bottom: ${space('lg')}px;
`;

const StyledObjectiveOverviewBar = styled(ObjectiveOverviewBar)``;



const ObjectiveOverviewSheet = styled(Sheet)`
  display: grid;
  row-gap: ${space('xxl')}px;
  column-gap: ${space('xl')}px;
  grid-template-columns: repeat(3, 1fr);

  ${StyledObjectiveOverviewBar} {
    grid-column: 1/-1;
    grid-row: 1;
  }
`;

const OverviewCaption = styled(Text)`
  grid-column: 1/-1;
  grid-row: 2;
  text-align: center;
`;

const ObjectiveOverviewFooter = styled("div")`
  display: flex;
  justify-content: flex-end;
  grid-column: 1/-1;
`;

const IconWrapper = styled('div')`
  color: ${color('main', 'base')};
  border: ${borderWidth('md')}px dashed currentColor;
  border-radius: ${borderRadius("xxl")}em;
  fill: currentColor;
`

const Divider = styled('div')`
  display: flex;
  flex-direction: column;
  padding-top: ${space("xxl")}px;
  padding-bottom: ${space("xxl")}px;
  align-items: center;
  justify-content: center;
`;

const ScenariosCaption = styled(Text)`
  padding-top: ${space("md")}px;
  grid-column: 1 / -1;
  text-align: center;
`
const StyledLink = styled(Link)`
  color: ${textColor("contrast")};
`;

export const ObjectiveScenariosOverviewPage: FunctionComponent = () => {
  const {t} = useTranslation('objectives');
  let { objectiveId } = useParams<{objectiveId: string}>();
  const history = useHistory();
  const { data } = useQuery<GetObjectiveQuery, GetObjectiveQueryVariables>(objectives.get, {variables: { id: objectiveId }});
  const confirm = useConfirmation();
  const removeObjective = useRemoveObjective({
    onError: () => {
      toast.error(t('objectives:remove.error.toast'));
    },
    onCompleted: () => {
      toast.success(t('objectives:remove.success.toast'));
      history.push("/")
    },
  });
  if (!data?.objective) return null;
  const f = (amount: number) => formatMonetaryAmount(amount, objective.currency.code);
  const objective = data.objective;
  const ownFundsPercentage = objective.ownFundsPercentage ?? 1;
  const loanAmount = objective.amount*(1 - ownFundsPercentage);
  const sortedScenarios = [...(objective.scenarios ?? [])];
  const fees = objective.taxesPerc || 0;
  const totalAmount = objective.amount*(fees + 1);
  const currentAmount = objective.futureAvailableCash + sum(objective.scenarios?.map(s => s.amount));
  const ownFundsToObtain = totalAmount - loanAmount;

  sortedScenarios.sort(sortScenarios);

  return (
    <>
      <TitleBar>
        <Title title={t("scenario.overview.page.title")} level={3} />
        <Button
          iconName={"delete"}
          label={t("common:objectives.card.delete.title")}
          onClick={async (a) => {
            confirm({
              title: t("objectives:remove.confirmation.title", { objectiveName: objective.name }),
              content: t("objectives:remove.confirmation.content"),
            }).then(() => {
              removeObjective(objective.id);
              history.push("/");
            });
          }}
        />
      </TitleBar>
      <ObjectiveOverviewSheet>
        <StyledObjectiveOverviewBar objective={objective} />
        {objective.subType === "building" && (
          <>
            {loanAmount !== undefined && loanAmount !== null ? (
              <ObjectiveOverviewCard
                iconName={"account-balance"}
                title={t("scenario.loan.card.title")}
                amount={loanAmount}
                currencyCode={objective.currency.code}
              />
            ) : null}
            <OverviewCaption>
              {t("objectives:overview.caption", {
                totalAmount: f(totalAmount),
                loanAmount: f(loanAmount),
                ownFundsAmount: f(ownFundsToObtain),
              })}
            </OverviewCaption>
            <ObjectiveOverviewCard
              iconName={"euro"}
              title={t("scenario.ownFundsToGet.card.title")}
              amount={ownFundsToObtain}
              currencyCode={objective.currency.code}
            />
            <ObjectiveOverviewCard
              iconName={"euro"}
              title={t("scenario.ownFunds.card.title")}
              amount={currentAmount}
              currencyCode={objective.currency.code}
            />
          </>
        )}
        <ObjectiveOverviewFooter>
          <StyledLink to={`/objectives/${objective.id}/edit`}>{t("objectives:overview.edit")}</StyledLink>
        </ObjectiveOverviewFooter>
      </ObjectiveOverviewSheet>
      <Divider>
        <IconWrapper>
          <Icon name="add" size="lg" />
        </IconWrapper>
      </Divider>
      <CardsContainer>
        <ScenariosCaption>{t("objectives:scenario.caption")}</ScenariosCaption>
        {sortedScenarios.map((scenario) => (
          <ScenarioCard key={scenario.id} scenario={scenario} objective={objective} />
        ))}
      </CardsContainer>
    </>
  );
}
