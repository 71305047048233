import * as React from "react";
import { ConfirmDialog, ConfirmationOptions } from "../components/ConfirmDialog";
import {FunctionComponent} from "react";

const ConfirmationServiceContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject);

export const useConfirmation = () => React.useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider: FunctionComponent = ({ children }) => {
  const [ confirmationState, setConfirmationState ] = React.useState<ConfirmationOptions | null >(null);

  const awaitingPromiseRef = React.useRef<{ resolve: () => void, reject: () => void }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      {confirmationState && (
        <ConfirmDialog
          show={true}
          proceed={handleSubmit}
          dismiss={handleClose}
          {...confirmationState}
        />
      )}
    </>
  );
};
