import React from "react";
import {useTranslation, Trans} from "react-i18next";
import styled from "styled-components";
import _ from "lodash";

import {
  Icon,
  ProgressBar,
  Title,
  Text,
  ValueCard,
  borderRadius,
  color,
  textColor,
  space,
  fontSize
} from "@scriba/ui-lib";

import {ObjectiveDetailsFragmentFragment} from "../generated/graphql";
import {objectiveIconName} from "../services/objectives";
import {formatDate, formatMonetaryAmount} from "../services/format";
import {useHistory} from "react-router-dom";

type ObjectiveOverviewBarProps = {
  objective: ObjectiveDetailsFragmentFragment;
  className?: string;
}


const BarContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${space('md')}px;
  padding-right: ${space('md')}px;
  border-radius: ${borderRadius('md')}px;
  background-color: ${color('secondary', 'darker')};
  fill: ${textColor('contrast')};
  cursor: pointer;
`

const ProgressBarContainer = styled('div')`
  flex: 1;
`

const ProgressBarTitleText = styled(Text)`
  flex: 1;
  color: ${textColor('main')};
  text-align: center;
  font-size: ${fontSize('caption')}px;
`

const ObjectiveAmount = styled(Text)`
  color: ${textColor('main')};
  margin-left: 0.5ch;
  font-weight: bold;
  font-size: ${fontSize("h3")}px;
`

const ProgressBarTitle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${space('sm')}px;
`

const ObjectiveIcon = styled(Icon)`
  flex-shrink: 0;
`;

export const ObjectiveOverviewBar = ({objective, className}: ObjectiveOverviewBarProps) => {
  const {t} = useTranslation('objectives');
  const history = useHistory();
  const loanAmount = objective.amount*(1-objective.ownFundsPercentage);
  const fees = objective.taxesPerc || 0
  const totalAmount = objective.amount*(fees + 1);
  const currentAmount = objective.futureAvailableCash + _.sum(objective.scenarios?.map(s => s.amount));
  const amountToObtain = totalAmount - currentAmount - loanAmount;
  const objectivePercentage = Math.min(Math.floor((currentAmount/(totalAmount - loanAmount))*100), 100)

  return (
    <BarContainer className={className} onClick={() => history.push(`/objectives/${objective.id}/edit`)}>
      <ObjectiveIcon name={objectiveIconName(objective)} />

      <ValueCard label={t(`overview.target.label.${objective.subType}`)}>
        <Title
          title={objective.name}
          level={2}
          color='main'
        />
      </ValueCard>

      <ProgressBarContainer>
        <ProgressBarTitle>
          <ProgressBarTitleText>
            <Trans
              t={t}
              i18nKey="overview.progressbar.label"
              values={{amount: formatMonetaryAmount(currentAmount, objective.currency.code, 0)}}
              components={{ bold: <ObjectiveAmount /> }}
            />
          </ProgressBarTitleText>
          <div/>
        </ProgressBarTitle>
        <ProgressBar percentage={objectivePercentage} />
      </ProgressBarContainer>

      {(amountToObtain > 0) && (
        <ValueCard
          label={t("overview.amountToObtain.label")}
        >
          <Title
            title={formatMonetaryAmount(amountToObtain, objective.currency.code)}
            level={2}
            color='main'
          />
        </ValueCard>
      )}

      <ValueCard
        label={t("overview.objective.amount.label")}
      >
        <Title
          title={formatMonetaryAmount(totalAmount, objective.currency.code, 0)}
          level={2}
        />
      </ValueCard>

      <ValueCard
        label={t("overview.date.label")}
      >
        <Title
          title={formatDate(objective.objectiveDate)}
          level={2}
        />
      </ValueCard>

    </BarContainer>
  )
}
