"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpensePeriodicity = exports.ExpenseType = void 0;
var ExpenseType;
(function (ExpenseType) {
    ExpenseType["housing"] = "housing";
    ExpenseType["loan"] = "loan";
    ExpenseType["other"] = "other";
})(ExpenseType = exports.ExpenseType || (exports.ExpenseType = {}));
var ExpensePeriodicity;
(function (ExpensePeriodicity) {
    ExpensePeriodicity["month"] = "month";
    ExpensePeriodicity["year"] = "year";
})(ExpensePeriodicity = exports.ExpensePeriodicity || (exports.ExpensePeriodicity = {}));
