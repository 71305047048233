import React, {FunctionComponent} from 'react';
import {useHistory} from "react-router-dom";

import {Button, ButtonProps} from "@scriba/ui-lib";

interface ButtonLinkProps extends ButtonProps {
  to: string,
}

const ButtonLink: FunctionComponent<ButtonLinkProps> = ({to, ...otherProps}) => {
  const history = useHistory();

  return (
    <Button {...otherProps} onClick={() => history.push(to)} />
  )
}

export default ButtonLink;
