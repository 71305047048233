import styled from "styled-components";
import {space, Fieldset, fixFirefoxFlexScroll, borderRadius, color, fontFamily, fontSize, textColor} from "@scriba/ui-lib";

const innerDiv = `{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
}`;

export const GroupDiv = styled.div`
`;

const Group = styled.div`
  ${fixFirefoxFlexScroll()}
  display: flex;
  flex-direction: column;
  padding: ${space('xxl')}px;
  border-radius: ${borderRadius('md')}px;
  background-color: ${color('secondary', 'darker')};
  color: ${textColor('contrast')};
  font-family: ${fontFamily('sansSerif')};
  font-size: ${fontSize('base')}px;
  > ${Fieldset} {
    > ${GroupDiv} ${innerDiv};
  };
  > ${GroupDiv} ${innerDiv};
`;


export default Group;
