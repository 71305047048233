import {FunctionComponent} from 'react';

import {Title} from "@scriba/ui-lib";

import {useTranslation} from "react-i18next";
import { MovementsList } from '../components/MovementsList';

export const MovementsListPage: FunctionComponent = () => {
  const {t} = useTranslation(['movements']);
  return (
    <>
      <Title uppercase level={2} title={t('movements:other.list.title')} />
      <MovementsList/>
    </>
  )
}
