import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

import {Button, Card, Title} from "@scriba/ui-lib";

import {objectiveIconName} from "../services/objectives";
import {ObjectiveType} from "../generated/graphql";

type ObjectiveCardProps = {
  objectiveType: ObjectiveType
}

const ObjectiveCard = ({objectiveType}: ObjectiveCardProps) => {
  const {t} = useTranslation('objectives');
  const history = useHistory();

  return (
    <Card
      onClick={() => history.push(`/objectives/${objectiveType}/new`)}
      footer={<Button label={t('objective.card.chooseButton.label')} />}
      iconName={objectiveIconName({subType: objectiveType})}
    >
      <Title title={t(`objective.card.title.${objectiveType}`)} level={3}/>
    </Card>
  )
}

export default ObjectiveCard;
