import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Fieldset, Title, space } from "@scriba/ui-lib";
import { UseFormMethods } from "react-hook-form";
import { LoanWarrantyAsset, LoanSource, LoanType, LoanWarranty, RepaymentType } from "@scriba/common";
import type { OtherAssetInputs } from "./OtherAssetInputForm";
import Group from "../Group";
import MonetaryAmountInputField from "../fields/MonetaryAmountInputField";
import PercentageInputField from "../fields/PercentageInputField";
import DatePickerField from "../fields/DatePickerField";
import SelectInputField from "../fields/SelectInputField";

export interface LoanFieldsetsProps {
  formMethods: UseFormMethods<OtherAssetInputs>;
  idx: number;
  loan: any;
  currency: {
    code: string;
    rateToEuro: number;
  };
  deleteLoan: (id: string, index: number) => () => void;
}

const LoanTitleLine = styled("div")`
  display: flex;
  align-items: center;
`;

const LoanGroup = styled(Group)`
  margin-top: ${space("lg")}px;
`;

const LoanFieldset = styled(Fieldset)`
  & + & {
    padding-top: ${space("xl")}px;
  }
`;

export const LoanFieldsets: FunctionComponent<LoanFieldsetsProps> = ({
  currency,
  deleteLoan,
  formMethods,
  idx,
  loan,
}) => {
  const { t } = useTranslation("assets");
  const warrantyValue = formMethods.watch(`loans[${idx}].loanWarranty`);
  const [isAssetWarranty, setIsAssetWarranty] = useState(false);
  useEffect(() => {
    setIsAssetWarranty(warrantyValue === LoanWarranty.asset);
  }, [warrantyValue]);

  return (
    <LoanGroup>
      <LoanFieldset>
        <LoanTitleLine>
          <Title title={t("assets:field.loan.title", { idx: idx + 1 })} level={5} />
          <Button
            appearance="transparent"
            iconName="delete"
            color="main"
            size="md"
            onClick={deleteLoan(loan.id, idx)}
          />
        </LoanTitleLine>

        <MonetaryAmountInputField
          currency={currency}
          label={t("assets:field.loan.amount")}
          name={`loans[${idx}].amount`}
          required={true}
          formMethods={formMethods}
          defaultValue={loan.amount}
        />

        <PercentageInputField
          label={t("assets:field.loan.yearlyRate")}
          name={`loans[${idx}].yearlyRate`}
          required={true}
          formMethods={formMethods}
          defaultValue={loan.yearlyRate}
        />

        <MonetaryAmountInputField
          currency={currency}
          label={t("assets:field.loan.monthlyAmount")}
          name={`loans[${idx}].monthlyAmount`}
          required={true}
          formMethods={formMethods}
          defaultValue={loan.monthlyAmount}
        />

        <DatePickerField
          label={t("assets:field.loan.subscriptionDate")}
          name={`loans[${idx}].subscriptionDate`}
          required={true}
          formMethods={formMethods}
          defaultValue={loan.subscriptionDate}
        />

        <PercentageInputField
          label={t("assets:field.loan.sharePercentage")}
          name={`loans[${idx}].sharePercentage`}
          required={true}
          formMethods={formMethods}
          defaultValue={loan.sharePercentage}
        />
      </LoanFieldset>
      <LoanFieldset>
        <SelectInputField
          label={`${t(`field.loan.source`, { ns: "assets" })}`}
          name={`loans[${idx}].loanSource`}
          required={true}
          formMethods={formMethods}
          defaultValue={loan.loanSource}
          options={Object.keys(LoanSource).map((source) => (
            <option key={source} value={source}>
              {t(`loan.source.${source}`, { ns: "assets" })}
            </option>
          ))}
        />
        <SelectInputField
          label={`${t(`field.loan.type`, { ns: "assets" })}`}
          name={`loans[${idx}].loanType`}
          required={true}
          formMethods={formMethods}
          defaultValue={loan.loanType}
          options={Object.keys(LoanType).map((type) => (
            <option key={type} value={type}>
              {t(`loan.type.${type}`, { ns: "assets" })}
            </option>
          ))}
        />
        <SelectInputField
          label={`${t(`field.loan.repayment`, { ns: "assets" })}`}
          name={`loans[${idx}].repayment`}
          required={true}
          formMethods={formMethods}
          defaultValue={loan.repayment}
          options={Object.keys(RepaymentType).map((repayment) => (
            <option key={repayment} value={repayment}>
              {t(`loan.repayment.${repayment}`, { ns: "assets" })}
            </option>
          ))}
        />
      </LoanFieldset>

      <LoanFieldset>
        <SelectInputField
          label={`${t(`field.loan.warranty`, { ns: "assets" })}`}
          name={`loans[${idx}].loanWarranty`}
          required={false}
          formMethods={formMethods}
          defaultValue={loan.loanWarranty}
          options={Object.keys(LoanWarranty).map((warranty) => (
            <option key={warranty} value={warranty}>
              {t(`loan.warranty.${warranty}`, { ns: "assets" })}
            </option>
          ))}
        />
        {isAssetWarranty && (
          <SelectInputField
            label={`${t(`field.loan.warrantyAsset`, { ns: "assets" })}`}
            name={`loans[${idx}].loanWarrantyAsset`}
            required={false}
            formMethods={formMethods}
            defaultValue={loan.loanWarrantyAsset}
            options={Object.keys(LoanWarrantyAsset).map((warranty) => (
              <option key={warranty} value={warranty}>
                {t(`loan.loanWarrantyAsset.${warranty}`, { ns: "assets" })}
              </option>
            ))}
          />
        )}
      </LoanFieldset>
    </LoanGroup>
  );
};
