import {useTranslation} from "react-i18next";

import {Card, color, borderRadius, borderWidth, space, } from "@scriba/ui-lib";
import styled from "styled-components";
import {useHistory} from "react-router-dom";

import { TableFooterButton } from "@scriba/ui-lib";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius('md')}px;
  border-style: dashed;
  border-color: ${color('secondary', 'dark')};
  border-width: ${borderWidth('sm')};
  flex-grow: 1;
  height: 100%;
`;

const AddCard = styled(Card)`
  padding: ${space('xl')}px;
`

const AddObjectiveCard = () => {
  const {t} = useTranslation('objectives');
  const history = useHistory();

  return (
    <AddCard onClick={() => history.push("/objectives/new")}>
      <Container>
        <TableFooterButton label={t("common:objectives.list.page.link.label")} />
      </Container>
    </AddCard>
  );
}

export default AddObjectiveCard;
