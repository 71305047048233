"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typeScale = void 0;
exports.typeScale = {
    h1: 28,
    h2: 25,
    h3: 22,
    h4: 20,
    h5: 18,
    h6: 16,
    base: 14,
    label: 13,
    caption: 11,
};
