import i18next from "i18next";
import dayjs from "dayjs";

export const monetaryAmountFormatter = (currencyCode?: string, precision?: number, fixedDecimalScale: boolean = true) => (
  new Intl.NumberFormat(i18next.language, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
    ...(precision !== undefined ? {
      maximumFractionDigits: precision,
      minimumFractionDigits: fixedDecimalScale ? precision : 0,
    } : {}),
  })
)

export const formatMonetaryAmount = (value: number, currencyCode: string, precision: number = 2): string => {
  return monetaryAmountFormatter(currencyCode, precision).format(value);
}

export const percentageFormatter = (precision?: number, fixedDecimalScale: boolean = true) => (
  new Intl.NumberFormat(i18next.language, {
    style: 'percent',
    currencyDisplay: 'symbol',
    ...(precision !== undefined ? {
      maximumFractionDigits: precision,
      minimumFractionDigits: fixedDecimalScale ? precision : 0,
    } : {}),
  })
)

export const formatPercentage = (value: number, precision: number = 2): string => {
  return percentageFormatter(precision).format(value);
}

export const formatDate = (date: Date): string => {
  return dayjs(date).format('DD/MM/YYYY');
}
