"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textColor = exports.surface = exports.space = exports.iconSize = exports.getGridSize = exports.fontSize = exports.fontFamily = exports.color = exports.borderWidth = exports.borderRadius = void 0;
var colors_accessors_1 = require("./colors/colors-accessors");
Object.defineProperty(exports, "color", { enumerable: true, get: function () { return colors_accessors_1.color; } });
Object.defineProperty(exports, "surface", { enumerable: true, get: function () { return colors_accessors_1.surface; } });
Object.defineProperty(exports, "textColor", { enumerable: true, get: function () { return colors_accessors_1.textColor; } });
var layout_1 = require("./layout");
Object.defineProperty(exports, "getGridSize", { enumerable: true, get: function () { return layout_1.getGridSize; } });
var typography_accessors_1 = require("./typography/typography-accessors");
Object.defineProperty(exports, "fontSize", { enumerable: true, get: function () { return typography_accessors_1.fontSize; } });
Object.defineProperty(exports, "fontFamily", { enumerable: true, get: function () { return typography_accessors_1.fontFamily; } });
var borderWidth = layout_1.sizeAccessor(['borderWidths']);
exports.borderWidth = borderWidth;
var borderRadius = layout_1.sizeAccessor(['borderRadiuses']);
exports.borderRadius = borderRadius;
var space = layout_1.sizeAccessor(['spacers']);
exports.space = space;
var iconSize = layout_1.sizeAccessor(['iconSizes']);
exports.iconSize = iconSize;
