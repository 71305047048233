"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontFamily = exports.fontSize = void 0;
var theme_accessor_1 = require("../theme-accessor");
var ramda_1 = require("ramda");
var fontSize = function (fontSizeName) {
    return theme_accessor_1.themeAccessor(['fontSizes', fontSizeName]);
};
exports.fontSize = fontSize;
var fontFamily = function (fontFamilyName) {
    return function (props) {
        return ramda_1.path(['theme', 'fontFamilies', fontFamilyName], props).join(', ');
    };
};
exports.fontFamily = fontFamily;
