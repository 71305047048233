import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {ApolloError} from "@apollo/client/errors";

import {fragments as loansFragments} from "../loans";
import {fragments as movementsFragments, removeMovementFromList} from "../movements";
import assets, {addAssetToQuery, fragments as assetFragments, removeAssetFromQuery} from "./index";
import {typify} from "@scriba/common/dist/services/helpers";
import {CreateOtherAssetMutation, OtherAssetCreate, RemoveOtherAssetMutation} from "../../generated/graphql";

export const fragments = {
  details: gql`
    fragment OtherAssetDetailsFragment on OtherAsset {
      id
      name
      subType
      acquisitionValue
      currentValue
      currency {
        code
        rateToEuro
      }
      acquisitionDate
      valueDate
      evolutionRate
      sharePercentage
      loans {
        ...LoanDetailsFragment
      }
      movements {
        ...MovementDetailsFragment
      }
    }
    ${loansFragments.details}
    ${movementsFragments.details}
  `,
}

const queries = {
  create: gql`
    mutation CreateOtherAsset($input: OtherAssetCreate!) {
      createOtherAsset(input: $input) {
        data {
          ...OtherAssetDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  update: gql`
    mutation UpdateOtherAsset($input: OtherAssetUpdate!) {
      updateOtherAsset(input: $input) {
        data {
          ...OtherAssetDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  remove: gql`
    mutation RemoveOtherAsset($id: ID!) {
      removeOtherAsset(id: $id) {
        data {
          ...OtherAssetDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
}

export default queries;

export function useRemoveOtherAsset({onError, onCompleted}:{onError: (error: ApolloError) => void, onCompleted: (data: RemoveOtherAssetMutation) => void}) {
  const [ removeOtherAsset ] = useMutation(queries.remove, {
    onError,
    update: (cache, res) => {
      if (res?.data?.removeOtherAsset?.data) {
        const objToRemove = res.data.removeOtherAsset.data;
        removeAssetFromQuery(cache, assets.list, objToRemove)
        removeMovementFromList(cache, m => m.asset.id !== objToRemove.id)
      }
    },
    onCompleted,
  });
  return useCallback((id) => {
    return removeOtherAsset({
      variables: {id},
    });
  }, [removeOtherAsset]);
}

export function useCreateOtherAsset({onError, onCompleted}:{onError: (error: ApolloError) => void, onCompleted?: (data: CreateOtherAssetMutation) => void}) {
  const [ createOtherAsset ] = useMutation<CreateOtherAssetMutation>(queries.create, {
    onError,
    update: (cache, res) => {
      if (res?.data?.createOtherAsset?.data) {
        const objToAdd = res.data.createOtherAsset.data;
        const assetCacheId = cache.identify(typify(objToAdd))
        cache.writeFragment({
          id: assetCacheId,
          fragment: assetFragments.details,
          fragmentName: "AllAssetsDetailsFragment",
          data: objToAdd,
        });
        addAssetToQuery(cache, assets.list, objToAdd, (allAssets) => allAssets);
      }
    },
    onCompleted,
  });
  return useCallback((asset: OtherAssetCreate) => {
    return createOtherAsset({
      variables: {input: asset},
    });
  }, [createOtherAsset]);
}
