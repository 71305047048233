import {FunctionComponent} from "react";
import styled from "styled-components";

import {space, Title} from "@scriba/ui-lib";

import {ObjectiveType} from "../generated/graphql";
import ObjectiveCard from "../components/ObjectiveCard"
import PlaceHolderObjectiveCard from "../components/PlaceholderObjectiveCard";
import {useTranslation} from "react-i18next";

const ScenariosContainer = styled('div')`
  padding-top: ${space('xl')}px;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
  row-gap: ${space('xl')}px;
`

export const CreateObjectivePage: FunctionComponent = () => {
  const {t} = useTranslation('objectives');
  return (
    <>
      <Title uppercase level={2} title={t('objectives.create.title')} />
      <ScenariosContainer>
        {Object.values(ObjectiveType).map(oType =>
          <ObjectiveCard objectiveType={oType} />
        )}
        <PlaceHolderObjectiveCard title={t('objective.card.title.placeholder')} />
      </ScenariosContainer>
    </>
  );
}
