import styled from "styled-components";
import {textColor, space} from "@scriba/ui-lib";
import { FunctionComponent } from "react";

type HighlightedTextProps = {
  bold?: boolean
  textAlign?: string
}

const Container = styled('span')<HighlightedTextProps>`
  color: ${textColor('main')};
  margin-left: ${space('md')}px;
  margin-right: ${space('md')}px;
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  text-align: ${props => props.textAlign};
`;

const HighlightedText: FunctionComponent<HighlightedTextProps> = ({bold=true, textAlign='start', children}) => {

  return (
    <Container bold={bold} textAlign={textAlign}>
      {children}
    </Container>
  )
}
export default HighlightedText;
