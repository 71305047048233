"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DarkTheme = void 0;
var CommonTheme_1 = require("./CommonTheme");
var colorMaps_1 = require("./colors/colorMaps");
var teal = colorMaps_1.colorMaps.teal, charcoal = colorMaps_1.colorMaps.charcoal, grey = colorMaps_1.colorMaps.grey;
exports.DarkTheme = CommonTheme_1.createTheme({
    name: "Dark",
    colors: {
        main: teal,
        secondary: charcoal,
    },
    surfaces: {
        page: '#1f272e',
        sheet: charcoal.dark,
    },
    textColors: {
        contrast: '#fff',
        match: '#000',
        main: teal.base,
        secondary: charcoal.dark,
        neutral: grey.dark,
    },
});
