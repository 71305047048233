import { useMutation, useQuery } from "@apollo/client";
import { Button, Fieldset, InputRow, Sheet, SwitchGroup, Title } from "@scriba/ui-lib";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DatePickerField from "../components/fields/DatePickerField";
import MonetaryAmountInputField from "../components/fields/MonetaryAmountInputField";
import PercentageInputField from "../components/fields/PercentageInputField";
import FieldsAndButton from "../components/FieldsAndButton";
import { ObjectiveOverviewBar } from "../components/ObjectiveOverviewBar";
import {
  AskForMoneyScenarioFragmentFragment, GetObjectiveQuery,
  GetObjectiveQueryVariables,
  GetScenarioQuery,
  GetScenarioQueryVariables,
  UpdateScenarioMutation,
  UpdateScenarioMutationVariables
} from "../generated/graphql";
import objectives from "../queries/objective";
import scenarios from "../queries/scenarios";
import Group from "../components/Group";
import SeparationDiv from "../components/SeparationDiv";


type AskForMoneyInputs = {
  amount: number | null;
  loanAmount: number | null,
  donationAmount: number | null,
  payOffDate: Date | null,
  interestRate: number | null,
}

export const ObjectiveScenariosAskForMoneyPage: FunctionComponent = () => {
  const {t} = useTranslation(['objectives', 'form']);
  const history = useHistory();
  let { scenarioId, objectiveId } = useParams<{scenarioId: string, objectiveId: string}>();
  const { data } = useQuery<GetScenarioQuery, GetScenarioQueryVariables>(scenarios.get, {variables: { id: scenarioId }});
  const { data: objectiveData } = useQuery<GetObjectiveQuery, GetObjectiveQueryVariables>(objectives.get, {variables: { id: objectiveId }});

  const formMethods = useForm<AskForMoneyInputs>({
    defaultValues: {
      amount: null,
      loanAmount: null,
      donationAmount: null,
      payOffDate: null,
      interestRate: null,
    }
  });
  const [updateScenario] = useMutation<UpdateScenarioMutation, UpdateScenarioMutationVariables>(
    scenarios.update,
    {
      onError: (error) => {
        console.error(error)
        toast.error(t('objectives:scenario.update.error.toast'));
      },
    }
  );
  const [hasInterestRate, setHasInterestRate] = useState(false);

  const {reset, getValues} = formMethods;
  useEffect(() => {
    if (!data?.scenario) return; // loading
    const scen = data.scenario as AskForMoneyScenarioFragmentFragment;
    setHasInterestRate(scen.interestRate !== null);
    reset({
      ...getValues(),
      amount: data.scenario.amount || null,
      loanAmount: scen.loanAmount || null,
      donationAmount: scen.donationAmount || null,
      payOffDate: scen.payOffDate || null,
      interestRate: scen.interestRate || null,
    });
  }, [reset, getValues, data?.scenario]);

  if (!data?.scenario || !objectiveData?.objective) return null;
  const objective = objectiveData.objective;

  const donationFields = (
      <MonetaryAmountInputField
        currency={objective.currency}
        label={t('scenario.askForMoney.amount.label')}
        name={"donationAmount"}
        required={false}
        formMethods={formMethods} />
  )

  const loanFields = (
    <>
        <MonetaryAmountInputField
          currency={objective.currency}
          label={t('scenario.askForMoney.loanAmount.label')}
          name={"loanAmount"}
          required={false}
          formMethods={formMethods} />
        <DatePickerField
          label={t('scenario.askForMoney.payOffDate.label')}
          name="payOffDate"
          formMethods={formMethods}
          required={false}
        />
        <InputRow
          label={t('scenario.askForMoney.interestRate.switch.label')}
          required={false}
          renderInput={() => (
            <SwitchGroup>
              <Button
                label={t('common:switch.label.yes')}
                appearance={hasInterestRate ? 'solid' : 'transparent'}
                onClick={() => {
                  setHasInterestRate(true);
                }}
              />
              <Button
                label={t('common:switch.label.no')}
                appearance={hasInterestRate ? 'transparent' : 'solid'}
                onClick={() => setHasInterestRate(false)}
              />
            </SwitchGroup>
          )}
          />
        {hasInterestRate && (
          <PercentageInputField
            label={t('scenario.askForMoney.interestRate.label')}
            name="interestRate"
            formMethods={formMethods}
            required={false}
          />
        )}
    </>
  )
  const fields = (
    <Fieldset>
      {donationFields}

      <SeparationDiv/>

      {loanFields}
    </Fieldset>
  )

  const bottomButton = (
    <InputRow
      label={t('common:other.requiredFields')}
      required={false}
      renderInput={() => (
        <Button type="submit" label={t('form:button.save.label')}/>
      )}
    />
  );
  return (
    <Fieldset>
      <Title title={t('scenario.overview.page.title')} level={3} />
      <Sheet>
        <Fieldset>
          <ObjectiveOverviewBar objective={objective}  />
          <Group>
            <form onSubmit={formMethods.handleSubmit(async(values) => {
              const rLoanAmount = values.loanAmount || 0;
              const rDonationAmount = values.donationAmount || 0;
              await updateScenario({variables: {input: {id: scenarioId,
                    amount: rLoanAmount + rDonationAmount,
                    donationAmount: values.donationAmount,
                    loanAmount: values.loanAmount,
                    payOffDate: values.payOffDate,
                    interestRate: values.interestRate
                  }}});
              history.push(`/objectives/${objectiveId}/scenarios`);
            })}>
              <FieldsAndButton fields={fields} button={bottomButton}/>
            </form>
          </Group>
        </Fieldset>
      </Sheet>
    </Fieldset>
  );
}
