"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoanWarrantyAsset = exports.LoanWarranty = exports.RepaymentType = exports.LoanSource = exports.LoanType = void 0;
var LoanType;
(function (LoanType) {
    LoanType["cash"] = "cash";
    LoanType["investment"] = "investment";
    LoanType["mortgage"] = "mortgage";
    LoanType["other"] = "other";
})(LoanType = exports.LoanType || (exports.LoanType = {}));
var LoanSource;
(function (LoanSource) {
    LoanSource["bank"] = "bank";
    LoanSource["other"] = "other";
    LoanSource["private"] = "private";
})(LoanSource = exports.LoanSource || (exports.LoanSource = {}));
var RepaymentType;
(function (RepaymentType) {
    RepaymentType["equal_monthly"] = "equal_monthly";
    RepaymentType["fixed_principal"] = "fixed_principal";
    RepaymentType["bullet"] = "bullet";
})(RepaymentType = exports.RepaymentType || (exports.RepaymentType = {}));
var LoanWarranty;
(function (LoanWarranty) {
    LoanWarranty["asset"] = "asset";
    LoanWarranty["bank"] = "bank";
    LoanWarranty["personal"] = "personal";
})(LoanWarranty = exports.LoanWarranty || (exports.LoanWarranty = {}));
var LoanWarrantyAsset;
(function (LoanWarrantyAsset) {
    LoanWarrantyAsset["bankAccountPledge"] = "bankAccountPledge";
    LoanWarrantyAsset["mortgage"] = "mortgage";
    LoanWarrantyAsset["other"] = "other";
    LoanWarrantyAsset["securitiesAccountPledge"] = "securitiesAccountPledge";
})(LoanWarrantyAsset = exports.LoanWarrantyAsset || (exports.LoanWarrantyAsset = {}));
