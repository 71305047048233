import React from 'react';
import {FieldValues} from "react-hook-form";

import {InputFieldProps} from "./index";
import ControlledInputField from "./ControlledInputField";
import {AutoNumericInput} from "../AutoNumericInput";

const PercentageInputField = function<T extends FieldValues>(props: InputFieldProps<T, number | null | undefined>) {
  const { label } = props;
  return (
    <ControlledInputField
      render={({ onChange, value }) => (
        <AutoNumericInput
          value={value}
          placeholder={label}
          options={{
            decimalCharacter: ",",
            decimalCharacterAlternative: ".",
            suffixText: "%",
            digitGroupSeparator: " ",
            rawValueDivisor: 100,
            emptyInputBehavior: "null",
          }}
          onChange={(event, value)=> {
            onChange(value);
            if(props.onChange) {
              props.onChange(value);
            }
          }}
        />
      )}
      {...props}
    />
  )
}

export default PercentageInputField;
