import {ApolloCache, gql, useMutation} from "@apollo/client";
import {ApolloError} from "@apollo/client/errors";
import {useCallback} from "react";

import {fragments as scenarioFragments} from './scenarios';

import {
  ListObjectivesQuery,
  ObjectiveDetailsFragmentFragment,
  RemoveObjectiveMutation
} from "../generated/graphql";

export const fragments = {
  details: gql`
    fragment ObjectiveDetailsFragment on Objective {
      id
      name
      subType
      amount
      objectiveDate
      availableCash
      futureAvailableCash
      ownFundsPercentage
      taxesPerc
      scenarios {
        ...ScenarioDetailsFragment
      }
      currency {
        code
        rateToEuro
      }
    }
    ${scenarioFragments.details}
  `,
}

const queries = {
  list: gql`
    query ListObjectives {
      objectives {
        ...ObjectiveDetailsFragment
      }
    }
    ${fragments.details}
  `,
  get: gql`
    query GetObjective($id: ID!) {
      objective(id: $id) {
        ...ObjectiveDetailsFragment
      }
    }
    ${fragments.details}
  `,
  create: gql`
    mutation CreateObjectiveMutation($input: ObjectiveCreate!) {
      createObjective(input: $input) {
        data {
          ...ObjectiveDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  update: gql`
    mutation UpdateObjective($input: ObjectiveUpdate!) {
      updateObjective(input: $input) {
        data {
          ...ObjectiveDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  remove: gql`
    mutation RemoveObjective($id: ID!) {
      removeObjective(id: $id) {
        data {
          ...ObjectiveDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
}

export default queries;

export const removeObjectiveFromList = <T>(cache: ApolloCache<T>, filter: (objective: ObjectiveDetailsFragmentFragment) => boolean) => {
  try {
    const data = cache.readQuery<ListObjectivesQuery>({query: queries.list});
    cache.writeQuery({
      query: queries.list,
      data: data && {
        objectives: data.objectives.filter(filter),
      },
    });
  } catch (err) {
    console.error({err})
  }
};

export function useRemoveObjective({onError, onCompleted}:{onError: (error: ApolloError) => void, onCompleted: (data: RemoveObjectiveMutation) => void}) {
  const [ removeObjective ] = useMutation(queries.remove, {
    onError,
    update: (cache, res) => {
      if (res?.data?.removeObjective?.data) {
        const objToRemove = res.data.removeObjective.data;
        removeObjectiveFromList(cache, a => a.id !== objToRemove.id);
      }
    },
    onCompleted,
  });
  return useCallback((id) => {
    return removeObjective({
      variables: {id},
    });
  }, [removeObjective]);
}
