import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { borderRadius, color, space, Text } from "@scriba/ui-lib";

const Container = styled("div")`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${borderRadius("md")}px;
  background-color: #12514640;
`;

const StyledLink = styled(Link)`
  color: ${color("main", "base")};
`;

const StyledParagraph = styled("p")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledText = styled(Text)`
  padding-top: ${space("sm")}px;
`;

export const ChartEmptySpace: FunctionComponent = () => {
  const { t } = useTranslation("home");

  return (
    <Container>
      <StyledParagraph>
        <StyledLink to="/assets/other/new">{t("chart.emptyspace.cta", { ns: "home" })}</StyledLink>
        <StyledText>{t("chart.emptyspace.text", { ns: "home" })}</StyledText>
      </StyledParagraph>
    </Container>
  );
};
