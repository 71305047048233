import {Home} from "./pages/Home";
import {CreateOtherAssetPage} from "./pages/CreateOtherAssetPage";
import {EditOtherAssetPage} from "./pages/EditOtherAssetPage";
import {CreateMovementPage} from "./pages/CreateMovementPage";
import {EditMovementPage} from "./pages/EditMovementPage";
import {CreateObjectivePage} from "./pages/CreateObjectivePage";
import {AssetsListPage} from "./pages/AssetsListPage";
import {CreateBuildingObjectivePage} from "./pages/CreateBuildingObjectivePage";
import {CreateAmountObjectivePage} from "./pages/CreateAmountObjectivePage";
import {EditObjectivePage} from "./pages/EditObjectivePage";
import {ObjectiveScenariosOverviewPage} from "./pages/ObjectiveScenariosOverviewPage";
import {ObjectiveScenariosAskForMoneyPage} from "./pages/ObjectiveScenariosAskForMoneyPage";
import {ObjectiveScenariosSaveMorePage} from "./pages/ObjectiveScenariosSaveMorePage";
import {ObjectiveScenariosBuyRealEstatePage} from "./pages/ObjectiveScenariosBuyRealEstatePage";
import {ObjectiveScenariosBuyStocks} from "./pages/ObjectiveScenariosBuyStocks";
import {ObjectivesListPage} from './pages/ObjectivesListPage';
import {MovementsListPage} from './pages/MovementsListPage';
import {BorrowingCapacityPage} from "./pages/BorrowingCapacityPage";


const routes = [
  {
    path: '/',
    component: Home,
    titleInNavBar: true,
    withBackButton: false,
  },
  {
    path: '/assets',
    component:AssetsListPage
  },
  {
    path: '/assets/other/new',
    component: CreateOtherAssetPage
  },
  {
    path: '/assets/other/:assetId/edit',
    component: EditOtherAssetPage,
  },
  {
    path: '/movements/new',
    component: CreateMovementPage,
  },
  {
    path: '/movements/:movementId/edit',
    component: EditMovementPage,
  },
  {
    path: '/objectives',
    component: ObjectivesListPage,
  },
  {
    path: '/movements',
    component: MovementsListPage,
  },
  {
    path: '/objectives/new',
    component: CreateObjectivePage,
  },
  {
    path: '/objectives/building/new',
    component: CreateBuildingObjectivePage,
    withBackButton: false,
  },
  {
    path: '/objectives/amount/new',
    component: CreateAmountObjectivePage,
    withBackButton: false,
  },
  {
    path: '/objectives/:objectiveId/edit',
    component: EditObjectivePage,
  },
  {
    path: '/objectives/:objectiveId/scenarios',
    component: ObjectiveScenariosOverviewPage,
  },
  {
    path: '/objectives/:objectiveId/scenarios/:scenarioId/askForMoney',
    component: ObjectiveScenariosAskForMoneyPage,
  },
  {
    path: '/objectives/:objectiveId/scenarios/:scenarioId/saveMore',
    component: ObjectiveScenariosSaveMorePage,
  },
  {
    path: '/objectives/:objectiveId/scenarios/:scenarioId/buyrealestate',
    component: ObjectiveScenariosBuyRealEstatePage,
  },
  {
    path: '/objectives/:objectiveId/scenarios/:scenarioId/buystocks',
    component: ObjectiveScenariosBuyStocks,
  },
  {
    path: '/borrowingCapacity',
    component: BorrowingCapacityPage,
  },
];

export default routes;
