import React, {FunctionComponent, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";

import {Button, Fieldset, InputRow, Select, Sheet, space, SwitchGroup, textColor, Title} from "@scriba/ui-lib";
import {BaseAsset, MovementPeriod} from "@scriba/common";

import {FormInput} from "./types";
import { StyledForm } from "./StyledForm";
import { BottomButton } from "./BottomButton";
import SelectInputField from "../fields/SelectInputField";
import MonetaryAmountInputField from "../fields/MonetaryAmountInputField";
import DatePickerField from "../fields/DatePickerField";
import assets from "../../queries/assets";
import {ListAssetsQuery, MovementDetailsFragmentFragment} from "../../generated/graphql";
import {AutoNumericInput} from "../AutoNumericInput";
import styled from "styled-components";

type MovementInputs = {
  currencyCode: string
  asset: BaseAsset
  amount: number
  startDate: Date
  nbOccurrence: number
  period: MovementPeriod
  endDate: Date | null
};

interface MovementInputFormProps extends FormInput<MovementInputs> {
  movement?: Partial<MovementDetailsFragmentFragment>
}

const ColoredOption = styled('option')`
  color: ${textColor('main')};
`
const FormSheet = styled(Sheet)`
  margin-top: ${space('lg')}px;
  padding: ${space('xl')}px;
`;

const MovementInputFrom: FunctionComponent<MovementInputFormProps> = ({onSubmit, movement}) => {
  const {t} = useTranslation(['movements', 'common', 'form']);
  const fieldRequiredErrorMsg = t('form:field.required');
  const formMethods = useForm<MovementInputs>({
    defaultValues: {
      ...movement,
      period: movement?.period || MovementPeriod.once,
      nbOccurrence: movement?.nbOccurrence !== undefined ? movement.nbOccurrence : 1,
    }
  });
  const [isRecurring, setIsRecurring] = useState(movement?.period ? movement?.period !== MovementPeriod.once : false);
  const { data } = useQuery<ListAssetsQuery>(assets.list);
  const allAssets = !!data  ? (
    data.assets.map(asset => ({
      id: asset.id, name: asset.name
    }))) : (movement?.asset ? [{id: movement.asset.id, name: movement.asset.name}] : [])
  const [currency, setCurrency] = useState({code: 'EUR'})
  const assetId = formMethods.watch('asset.id');
  useEffect(() => {
    if (assetId) {
      const asset = data?.assets.find(a => a.id === assetId);
      if (asset?.currency) {
        setCurrency(asset.currency);
      }
    }
  }, [assetId, data?.assets])
  return (
    <FormSheet>
      <Title level={4} title={t('movements:other.form.title')} />
      <StyledForm onSubmit={formMethods.handleSubmit(onSubmit)}>

        <Fieldset>
          <SelectInputField
            label={t('movements:field.asset.label')}
            name="asset.id"
            formMethods={formMethods}
            tooltip={t('movements:field.asset.tooltip')}
            required={true}
            options={allAssets.map(asset => (
                <option key={asset.id} value={asset.id}>{asset.name}</option>
            ))}
          />

          <MonetaryAmountInputField
            currency={currency}
            label={t('movements:field.amount.label')}
            name="amount"
            formMethods={formMethods}
            required={true}
          />

          <DatePickerField
            label={isRecurring ? t('movements:field.startDate.label') : t('movements:field.date.label')}
            name="startDate"
            formMethods={formMethods}
            required={true}
          />

          <InputRow
            label={t('movements:field.recurring.switch.label')}
            required={false}
            renderInput={() => (
              <SwitchGroup>
                <Button
                  label={t('common:switch.label.yes')}
                  appearance={isRecurring ? 'solid' : 'transparent'}
                  disabled={isRecurring}
                  onClick={() => {
                    setIsRecurring(true);
                    formMethods.setValue('period', null);
                  }}
                />
                <Button
                  label={t('common:switch.label.no')}
                  appearance={isRecurring ? 'transparent' : 'solid'}
                  disabled={!isRecurring}
                  onClick={() => {
                    setIsRecurring(false);
                    formMethods.setValue('nbOccurrence', 1);
                    formMethods.setValue('period', MovementPeriod.once);
                  }}
                />
              </SwitchGroup>
            )}
          />

          {isRecurring ? (
            <>

            <InputRow
              label={t('movements:field.frequency.label')}
              error={(formMethods.errors.nbOccurrence || formMethods.errors.period)?.message}
              required={true}
              renderInput={() => (
                <SwitchGroup>
                  <Controller
                    rules={{required: fieldRequiredErrorMsg}}
                    name="nbOccurrence"
                    control={formMethods.control}
                    render={({ onChange, value }) => (
                      <AutoNumericInput
                        value={value}
                        placeholder={t('movements:field.nbOccurrence.label')}
                        options={{
                          decimalCharacter: ",",
                          decimalCharacterAlternative: ".",
                          digitGroupSeparator: " ",
                          decimalPlaces: 0,
                          wheelStep: 1,
                        }}
                        onChange={(event, value)=> onChange(value)}
                      />
                    )}
                  />
                  <Select name="period" ref={formMethods.register({required: fieldRequiredErrorMsg})}>
                    <ColoredOption disabled={true} value="">{t('movements:field.period.label')}</ColoredOption>
                    {Object.keys(MovementPeriod).filter(p => p !== 'once').map(p => (
                      <ColoredOption key={p} value={p}>{'/ ' + t(`movements:other.period.${p}`)}</ColoredOption>
                    ))}
                  </Select>
                </SwitchGroup>
              )}
            />

              <DatePickerField
                label={t('movements:field.endDate.label')}
                name="endDate"
                formMethods={formMethods}
                required={false}
                registerOptions={{validate: (value) => (!value || new Date(value).getTime() > new Date(formMethods.getValues('startDate')).getTime()) ? true : t('movements:other.date.error')!}}
              />

            </>
          ) : (
            <>
              <input type="hidden" name="nbOccurrence" ref={formMethods.register} />
              <input type="hidden" name="period" ref={formMethods.register} />
            </>
          )}

        </Fieldset>
        <BottomButton>
          <Button type="submit" label={t('form:button.submit.label')} />
        </BottomButton>
      </StyledForm>
    </FormSheet>
  );

}

export default MovementInputFrom;
