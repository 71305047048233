import {FunctionComponent, useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {useFieldArray, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import { toast } from "react-toastify";

import {space, InputRow, Sheet, TableFooter, Title, Fieldset, Button, TableFooterButton} from "@scriba/ui-lib";
import {computeYearlyNetIncome, Expense, ExpensePeriodicity, Income, IncomePeriodicity} from "@scriba/common";

import {
  Currency,
  ExpenseType,
  IncomeType,
  Loan,
  ListAssetsQuery,
  ListExpensesQuery,
  ListExpensesQueryVariables,
  ListIncomesQuery,
  ListIncomesQueryVariables,
  OtherAssetDetailsFragmentFragment,
  UpdateExpenseMutation,
  UpdateExpenseMutationVariables,
  UpdateIncomeMutation,
  UpdateIncomeMutationVariables, UpdateUserMutation, UpdateUserMutationVariables,
} from "../generated/graphql";
import Group from "../components/Group";
import MonetaryAmountInputField from "../components/fields/MonetaryAmountInputField";
import PercentageInputField from "../components/fields/PercentageInputField";
import SelectInputField from "../components/fields/SelectInputField";
import SeparationDiv from "../components/SeparationDiv";
import HighlightedTextBorder from "../components/HighlightedTextBorder";
import {formatMonetaryAmount} from "../services/format";
import {useAuth} from "../providers/AuthProvider";
import incomes, {useCreateIncome, useRemoveIncome} from "../queries/incomes";
import expenses, {useCreateExpense, useRemoveExpense} from "../queries/expenses";
import {computeBorrowingCapacity} from "@scriba/common/dist/services/computations";
import users from "../queries/users";
import assets from "../queries/assets";


type BorrowingCapacityInputs = {
  maxDebtRatio: number | null
  incomes: Income[]
  expenses: Expense[]
}

const EntityLine = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h5 {
    width: 15%;
  }
`

const SummaryFields = styled(Fieldset)`
  row-gap: ${space("xxl")}px;
  input {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -10px;
    margin-bottom: -10px;
  }
`

type BCLoan = Pick<Loan, "id" | "currency" | "monthlyAmount" | "sharePercentage"> & {asset: OtherAssetDetailsFragmentFragment}

export const BorrowingCapacityPage: FunctionComponent = () => {
  const {t} = useTranslation(['form', 'common', 'borrowing']);
  const history = useHistory();
  const {user} = useAuth()
  const { data: incomesData } = useQuery<ListIncomesQuery, ListIncomesQueryVariables>(incomes.list);
  const { data: expensesData } = useQuery<ListExpensesQuery, ListExpensesQueryVariables>(expenses.list);
  const { data } = useQuery<ListAssetsQuery>(assets.list, {fetchPolicy: "cache-and-network"});
  const loans = data !== undefined ? data.assets.reduce<BCLoan[]>((accumulator, asset) => [...accumulator, ...asset.loans.map(l => ({...l, asset}))], []) : [];

  const formMethods = useForm<BorrowingCapacityInputs>({
    defaultValues: {
      maxDebtRatio: user?.maxDebtRatio || 0.3,
      incomes: [],
    }
  });

  const formMethodsDefaultCharge = useForm<BorrowingCapacityInputs>({});


  const currency = {code: 'EUR', rateToEuro: 1}; //TODO to replace with a picker when we handle multiple currencies
  const {handleSubmit, control, formState} = formMethods;
  const {setValue} = formMethods;
  const { dirtyFields } = formState;

  // Incomes
  const defaultIncome = {
    incomeType: null,
    amount: null,
    periodicity: null,
  };
  const { fields: incomeFields, append: appendIncome, remove: removeIncomeFromFields } = useFieldArray({
    keyName: "fieldId",
    control,
    name: "incomes"
  });
  useEffect(() => {
    if (!incomesData?.incomes) return; // loading
    setValue("incomes", incomesData.incomes);
  }, [setValue, incomesData?.incomes]);
  const incomesValues = formMethods.watch('incomes').map(i => ({...i, currency})); //TODO use correct currency from currencyId;
  const [incomesToDelete, setIncomesToDelete] = useState<string[]>([]);
  const removeIncomeFromGql = useRemoveIncome ({
      onError: () => {
        toast.error(t('borrowing:income.remove.error.toast'));
      },
      onCompleted: () => {}
    }
  )

  const createIncome = useCreateIncome({
    onError: (err) => {
      console.error(err);
      toast.error(t('borrowing:income.new.error.toast'));
    },
  });
  const [updateIncome] = useMutation<UpdateIncomeMutation, UpdateIncomeMutationVariables>(
    incomes.update,
    {
      onError: (err) => {
        console.error(err);
        toast.error(t('borrowing:income.update.error.toast'));
      },
    }
  )

  // Expenses
  const defaultExpense = {
    expenseType: null,
    amount: null,
    periodicity: null,
  };
  const { fields: expenseFields, append: appendExpense, remove: removeExpenseFromFields } = useFieldArray({
    keyName: "fieldId",
    control,
    name: "expenses"
  });
  useEffect(() => {
    if (!expensesData?.expenses) return; // loading
    setValue("expenses", expensesData.expenses);
  }, [setValue, expensesData?.expenses]);
  const expensesValues = formMethods.watch('expenses').map(e => ({...e, currency})); //TODO use correct currency from currencyId
  const [expensesToDelete, setExpensesToDelete] = useState<string[]>([]);
  const removeExpenseFromGql = useRemoveExpense ({
      onError: () => {
        toast.error(t('borrowing:expense.remove.error.toast'));
      },
      onCompleted: () => {}
    }
  )

  const createExpense = useCreateExpense({
    onError: (err) => {
      console.error(err);
      toast.error(t('borrowing:expense.new.error.toast'));
    },
  });
  const [updateExpense] = useMutation<UpdateExpenseMutation, UpdateExpenseMutationVariables>(
    expenses.update,
    {
      onError: (err) => {
        console.error(err);
        toast.error(t('borrowing:expense.update.error.toast'));
      },
    }
  )

  const [updateUser] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    users.update,
    {
      onError: (err) => {
        console.error(err);
        toast.error(t('borrowing:borrowing.capacity.update.error.toast'));
      },
    }
  )
  // Would be prettier to add it to the expenses but couldn't do so without saving them with the others
  const convertToExpense = (loan: BCLoan, currency: Currency) => {
    if (loan === null || loan === undefined || loan.currency === null || loan.currency === undefined)
      return 0;
    if (currency === null || currency === undefined)
      return 0;
    return (loan.monthlyAmount * loan.currency.rateToEuro * loan.sharePercentage * 12) / currency.rateToEuro;
  }

  const maxDebtRatio = formMethods.watch('maxDebtRatio');

  const encodedLoansValues = loans.reduce((acc, loan) => acc + convertToExpense(loan, currency), 0);
  const maxDebtRatioDefined = maxDebtRatio ?? 1;
  const encodedLoansImpact = encodedLoansValues * maxDebtRatioDefined

  const netValue = computeYearlyNetIncome(
    incomesValues,
    expensesValues,
    currency
  );
  let borrowingCapacity = computeBorrowingCapacity(
    incomesValues,
    expensesValues,
    currency,
    maxDebtRatio || undefined,
  );
  borrowingCapacity = borrowingCapacity !== undefined ? borrowingCapacity - encodedLoansImpact : undefined;

  const updateAllAndSubmit  = async ({expenses, incomes, maxDebtRatio}: BorrowingCapacityInputs) => {
    //Update incomes
    const dirtyIncomes = dirtyFields.incomes;
    const isIncomeDirty = (idx: number) => !dirtyIncomes ? false : Object.values(dirtyIncomes[idx] || {}).includes(true)
    const isIncomeNew = (idx: number) => !incomeFields[idx].id;

    await Promise.all(incomes?.map(async(income, idx) => {
      const isNew = isIncomeNew(idx);
      const isDirty = isIncomeDirty(idx);
      if(isNew) {
        await createIncome({...income, currencyCode: currency.code}); //TODO remove when currency is picked in the form
      } else if (isDirty) {
        await updateIncome({variables: {input: {...income, id: incomeFields[idx].id!}}})
      }
    }) || [Promise.resolve()])
    await Promise.all(incomesToDelete.map(async (incomeId) => removeIncomeFromGql(incomeId)));

    //Update expenses
    const dirtyExpenses = dirtyFields.expenses;
    const isExpenseDirty = (idx: number) => !dirtyExpenses ? false : Object.values(dirtyExpenses[idx] || {}).includes(true)
    const isExpenseNew = (idx: number) => !expenseFields[idx].id;

    await Promise.all(expenses?.map(async(expense, idx) => {
      const isNew = isExpenseNew(idx);
      const isDirty = isExpenseDirty(idx);
      if(isNew) {
        await createExpense({...expense, currencyCode: currency.code}); //TODO remove when currency is picked in the form
      } else if (isDirty) {
        await updateExpense({variables: {input: {...expense, id: expenseFields[idx].id!}}})
      }
    }) || [Promise.resolve()])
    await Promise.all(expensesToDelete.map(async (expenseId) => removeExpenseFromGql(expenseId)));

    if (dirtyFields.maxDebtRatio || !user!.maxDebtRatio) {
      await updateUser({variables: {input: {id: user!.id, maxDebtRatio}}});
    }

    toast.success(t('borrowing:borrowing.capacity.update.success'));
    history.push("/");
  }

  return (
    <>
      <Title uppercase level={3} title={t('borrowing:borrowing.capacity.form.title')} />
      <Sheet>
        <form onSubmit={handleSubmit(updateAllAndSubmit)}>
          <Fieldset>

            <Group>
              <Fieldset>
              <Title level={5} title={t('borrowing:incomes.title')} />
              {incomeFields.map((incomeField, idx) => {
                return (
                  <EntityLine key={incomeField.fieldId}>
                    <Title title={t('borrowing:field.income.title', {idx: idx+1})} level={5} />

                    <SelectInputField
                      label={t('borrowing:field.income.type.label')}
                      name={`incomes[${idx}].incomeType`}
                      formMethods={formMethods}
                      required={true}
                      inline={true}
                      defaultValue={incomeField.incomeType}
                      options={Object.keys(IncomeType).map(incomeType => (
                        <option key={incomeType} value={incomeType}>{t(`borrowing:income.type.${incomeType}`)}</option>
                      ))}
                    />

                    <MonetaryAmountInputField
                      currency={currency}
                      label={t('borrowing:field.amount.label')}
                      name={`incomes[${idx}].amount`}
                      formMethods={formMethods}
                      required={true}
                      inline={true}
                      defaultValue={incomeField.amount}
                    />

                    <SelectInputField
                      label={t('borrowing:field.periodicity.label')}
                      name={`incomes[${idx}].periodicity`}
                      formMethods={formMethods}
                      required={true}
                      inline={true}
                      defaultValue={incomeField.periodicity}
                      options={Object.keys(IncomePeriodicity).map(periodicity => (
                        <option key={periodicity} value={periodicity}>{t(`form:periodicity.${periodicity}.label`)}</option>
                      ))}
                    />

                    <Button appearance='transparent' iconName='delete' color='main' size="md" onClick={async () => {
                      const incomeId = incomeField.id;
                      if (incomeId) {
                        setIncomesToDelete([...incomesToDelete, incomeId]);
                      }
                      removeIncomeFromFields(idx)
                    }} />
                  </EntityLine>
                )
              })}
              <TableFooter >
                <TableFooterButton
                  onClick={() => appendIncome(defaultIncome)}
                  label={t('borrowing:new.income.button.label')}
                />
              </TableFooter>
              </Fieldset>
            </Group>

            <Group>
              <Fieldset>
                <Title level={5} title={t('borrowing:expenses.title')} />
                {expenseFields.map((expenseField, idx) => {
                  return (
                    <EntityLine key={expenseField.fieldId}>
                      <Title title={t('borrowing:field.expense.title', {idx: idx+1})} level={5} />

                      <SelectInputField
                        label={t('borrowing:field.expense.type.label')}
                        name={`expenses[${idx}].expenseType`}
                        formMethods={formMethods}
                        required={true}
                        inline={true}
                        defaultValue={expenseField.expenseType}
                        options={Object.keys(ExpenseType).map(expenseType => (
                          <option key={expenseType} value={expenseType}>{t(`borrowing:expense.type.${expenseType}`)}</option>
                        ))}
                      />

                      <MonetaryAmountInputField
                        currency={currency}
                        label={t('borrowing:field.amount.label')}
                        name={`expenses[${idx}].amount`}
                        formMethods={formMethods}
                        required={true}
                        inline={true}
                        defaultValue={expenseField.amount}
                      />

                      <SelectInputField
                        label={t('borrowing:field.periodicity.label')}
                        name={`expenses[${idx}].periodicity`}
                        formMethods={formMethods}
                        required={true}
                        inline={true}
                        defaultValue={expenseField.periodicity}
                        options={Object.keys(ExpensePeriodicity).map(periodicity => (
                          <option key={periodicity} value={periodicity}>{t(`form:periodicity.${periodicity}.label`)}</option>
                        ))}
                      />

                      <Button appearance='transparent' iconName='delete' color='main' size="md" onClick={async () => {
                        const expenseId = expenseField.id;
                        if (expenseId) {
                          setExpensesToDelete([...expensesToDelete, expenseId]);
                        }
                        removeExpenseFromFields(idx)
                      }} />
                    </EntityLine>
                  )
                })}

                <SeparationDiv/>

                {loans.map((loan, idx) => {
                  return (
                    <EntityLine key={loan.id}>
                      <Title title={t('borrowing:field.expense.default.title', {asset: loan.asset.name})} level={5} />

                      <SelectInputField
                        label={t('borrowing:field.expense.type.label')}
                        name={`tmp[${idx}].expenseType`}
                        formMethods={formMethodsDefaultCharge}
                        required={false}
                        inline={true}
                        disabled={true}
                        defaultValue={[ExpenseType.loan]}
                        options={[(
                          <option key={ExpenseType.loan} value={ExpenseType.loan}>{t(`borrowing:expense.type.${ExpenseType.loan}`)}</option>
                        )]}
                      />

                      <MonetaryAmountInputField
                        currency={currency}
                        label={t('borrowing:field.amount.label')}
                        name={`tmp[${idx}].amount`}
                        formMethods={formMethodsDefaultCharge}
                        required={false}
                        inline={true}
                        disabled={true}
                        defaultValue={loan.monthlyAmount*loan.sharePercentage}
                      />

                      <SelectInputField
                        label={t('borrowing:field.periodicity.label')}
                        name={`tmp[${idx}].periodicity`}
                        formMethods={formMethodsDefaultCharge}
                        required={false}
                        inline={true}
                        disabled={true}
                        defaultValue={[ExpensePeriodicity.month]}
                        options={[(
                          <option key={ExpensePeriodicity.month} value={ExpensePeriodicity.month}>{t(`form:periodicity.${ExpensePeriodicity.month}.label`)}</option>
                        )]}
                      />

                      <Button appearance='transparent' disabled={true} iconName='lock' color='main' size="md" />

                    </EntityLine>
                  )
                })}
                <TableFooter>
                  <TableFooterButton
                    onClick={() => appendExpense(defaultExpense)}
                    label={t('borrowing:new.expense.button.label')}
                  />
                </TableFooter>
              </Fieldset>
            </Group>

            <Group>
              <SummaryFields>
                <Title title={t('borrowing:borrowing.capacity.section.title')} level={5} />
                <InputRow
                  renderInput={() => (
                    <HighlightedTextBorder>
                      {formatMonetaryAmount(netValue, currency.code)}
                    </HighlightedTextBorder>
                  )}
                  label={t('borrowing:yearly.net.value.sum.label')}
                  required={false}
                />
                <PercentageInputField
                  label={t('borrowing:field.maxDebtRatio.label')}
                  name="maxDebtRatio"
                  required={true}
                  formMethods={formMethods}
                  tooltip={t('borrowing:field.maxDebtRatio.tooltip')}
                />
                <InputRow
                  renderInput={() => (
                    <HighlightedTextBorder>
                      {borrowingCapacity ? formatMonetaryAmount(borrowingCapacity, currency.code) : "-"}
                    </HighlightedTextBorder>
                  )}
                  label={t('borrowing:borrowing.capacity.yearly.label')}
                  required={false}
                />
                <InputRow
                  renderInput={() => (
                    <HighlightedTextBorder>
                      {borrowingCapacity ? formatMonetaryAmount(borrowingCapacity/12, currency.code) : "-"}
                    </HighlightedTextBorder>
                  )}
                  label={t('borrowing:borrowing.capacity.monthly.label')}
                  required={false}
                />
              </SummaryFields>
            </Group>
          </Fieldset>
          <Button type="submit" label={t('form:button.submit.label')} />
        </form>
      </Sheet>
    </>
  );
}
