import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";

import {Title} from "@scriba/ui-lib";

import {MyObjectivesList} from "../components/MyObjectivesList";

export const ObjectivesListPage: FunctionComponent = () => {
  const {t} = useTranslation('objectives');
  return (
    <>
      <Title uppercase level={2} title={t('objectives.list.title')} />
      <MyObjectivesList />
    </>
  );
}
