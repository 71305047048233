import React, {FunctionComponent} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {toast} from "react-toastify";

import {fontFamily, fontSize, space, Button, Input, Fieldset, Label, Title} from "@scriba/ui-lib";

import {useAuth} from "../../providers/AuthProvider";
import ButtonLink from "../navigation/ButtonLink";

type LoginInputs = {
  email: string;
  password: string;
};

interface LoginFormProps {
  onLogin: () => Promise<void>
}

const InputWithUpperLabel = styled.div`
  display: grid;
  grid-row-gap: ${space('xs')}px;
`

const ErrorWrapper = styled('div')`
  grid-column: 2 / -1;
  padding: 0 ${space('sm')}px;
  font-family: ${fontFamily('sansSerif')};
  font-size: ${fontSize('label')}px;
  color: tomato;
`;

const PasswordFieldLabel = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LoginFrom: FunctionComponent<LoginFormProps> = (props) => {
  const {onLogin} = props;
  const {t} = useTranslation(['common', 'auth', 'form']);
  const {handleSubmit, register, errors, setError} = useForm<LoginInputs>();
  const { login } = useAuth();
  const fieldRequiredErrorMsg = t('form:field.required');

  const loginWithForm = async ({email, password}: LoginInputs) => {
    const res = await login(email, password);
    if (res?.data?.login.data) {
      await onLogin();
    } else if (res?.data?.login?.error) {
      toast.error(t(`auth:login.error.${res.data.login.error}`));
      setError('password', { message: t(`auth:login.error.${res.data.login.error}`)})
    }
  }

  return (
      <form onSubmit={handleSubmit(loginWithForm)}>
        <Fieldset>
          <InputWithUpperLabel>
            <Title title={t('auth:login.form.welcome.title')} level={5} />
            <Title title={t('auth:login.form.title')} level={4} />
          </InputWithUpperLabel>

          <InputWithUpperLabel>
            <Label label={t('auth:field.email.label')} />
            <Input placeholder={t('auth:field.email.placeholder')}
                   name="email"
                   ref={register({required: fieldRequiredErrorMsg})}
            />
            {!!errors["email"] &&
            <ErrorWrapper>
              {errors["email"].message}
            </ErrorWrapper>
            }
          </InputWithUpperLabel>

          <InputWithUpperLabel>
            <PasswordFieldLabel>
              <Label label={t('auth:field.password.label')} />
              <ButtonLink to={'/forgotPassword'} appearance={"transparent"} label={t('auth:password_recover.button.label')}/>
            </PasswordFieldLabel>
            <Input placeholder={t('auth:field.password.placeholder')}
                   name="password"
                   type="password"
                   ref={register({required: fieldRequiredErrorMsg})}
            />
            {!!errors["password"] &&
            <ErrorWrapper>
              {errors["password"].message}
            </ErrorWrapper>
            }
          </InputWithUpperLabel>

          <Button type="submit" label={t('auth:login.button.label')} />

          <InputWithUpperLabel>
            <Title title={t('auth:login.form.signup.title')} level={5} />
            <ButtonLink to={'/signUp'} label={t('auth:login.form.signup.button')} appearance={"outline"} />
          </InputWithUpperLabel>

        </Fieldset>
      </form>
  );

}

export default LoginFrom;
