import {gql} from "@apollo/client";

export const fragments = {
  details: gql`
    fragment ScenarioDetailsFragment on Scenario {
      id
      amount
      scenarioType
      objective {
        id
      }
      ...BuyRealEstateScenarioFragment
      ...BuyStocksScenarioFragment
      ...AskForMoneyScenarioFragment
    }

    fragment BuyRealEstateScenarioFragment on BuyRealEstateScenario {
      investedOwnFunds
      ownFundsPerc
      taxesPerc
      rentingRentability
      occupationRate
      loanDuration
      interestRate
      precompte
      agencyFees
      upkeep
      otherFees
      evolutionRate
      sellTaxes
      agencySellFees
     }

    fragment BuyStocksScenarioFragment on BuyStocksScenario {
      rentabilityChoice
      investedAmount
      stockIndex 
    }

    fragment AskForMoneyScenarioFragment on AskForMoneyScenario {
      loanAmount
      donationAmount
      payOffDate
      interestRate
    }
  `,
}

const queries = {
  get: gql`
    query GetScenario($id: ID!) {
      scenario(id: $id) {
        ...ScenarioDetailsFragment
      }
    }
    ${fragments.details}
  `,
  update: gql`
    mutation UpdateScenario($input: ScenarioUpdate!) {
      updateScenario(input: $input) {
        data {
          ...ScenarioDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `
}

export default queries;
