import React from 'react';
import { matchPath } from "react-router";
import {useHistory, useLocation, useParams} from "react-router-dom";

import { Breadcrumbs } from "@scriba/ui-lib";
import {useTranslation} from "react-i18next";
import routes from "../../routes";

function AutoBreadcrumbs() {
  const {t} = useTranslation('navigation');
  const history = useHistory();
  const location = useLocation();
  const params: { [name: string]: string } = useParams();

  const breadcrumbs = routes
    .filter(({path}) => !!matchPath(location.pathname, {path, exact: false}))
    .map(({path}) => {
      const isSelected = !!matchPath(location.pathname, {path, exact: true});
      const linkPath = path.replaceAll(
        /:([^/]+)/g,
        (_, paramName: string) => params[paramName]
      )
      return {
        label: t(`route.breadcrumb.label.${path.replaceAll(':','_')}`),
        isSelected,
        ...(isSelected ? {} : {onSelect: () => history.push(linkPath)})
      }
    });

  return (
    <Breadcrumbs breadcrumbs={breadcrumbs} />
  );

}

export default AutoBreadcrumbs;
