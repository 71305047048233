import React, { useContext, createContext } from "react";
import {FetchResult, useMutation, useQuery} from "@apollo/client";

import {User} from "@scriba/common";

import users from "../queries/users";
import {LoginMutation, LoginMutationVariables, LogoutMutation, UserQuery} from "../generated/graphql";

interface AuthContextType {
  loading: boolean,
  authenticated: boolean,
  user?: User,
  login: (email: string, password:string) => Promise<FetchResult<LoginMutation>>,
  logout: () => Promise<unknown>,
}

const authContext = createContext<AuthContextType>({
  loading: true,
  authenticated: false,
  login(_email: string, _password: string): Promise<FetchResult<LoginMutation>> {
    return Promise.reject("Authentication context not yet initialized.");
  },
  logout(): Promise<void> {
    return Promise.reject("Authentication context not yet initialized.");
  },
});

export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const { client, loading, error, data } = useQuery<UserQuery>(users.getUser);
  const [login] = useMutation<LoginMutation, LoginMutationVariables>(
    users.login,
    {
      update: (cache, res) => {
        if (!!res.data?.login?.data) {
          const userData = res.data.login.data;
          cache.writeQuery<UserQuery>({
            query: users.getUser,
            data: { user: userData },
          })
        }
      }
    }
  )
  const [logout] = useMutation<LogoutMutation>(
    users.logout,
    {
      update: async (cache, res) => {
        if (!!res.data?.logout) {
          await client.resetStore();
        }
      },
    }
  )

  const user = (!loading && !error) ? (data?.user || undefined) : undefined;

  return {
    loading,
    authenticated: !!user,
    user,
    login: async (email: string, password:string) => login({variables: {email, password}}),
    logout,
  };
}
