import React from 'react';
import {FieldValues} from "react-hook-form";

import {InputFieldProps} from "./index";
import ControlledInputField from "./ControlledInputField";
import {AutoNumericInput} from "../AutoNumericInput";

interface DecimalInputFieldProps<T extends FieldValues> extends InputFieldProps<T, number | null | undefined> {
  decimalPlaces?: number
}

const DecimalInputField = function<T extends FieldValues>({decimalPlaces=2, ...props}: DecimalInputFieldProps<T>) {
  const { label } = props;
  return (
    <ControlledInputField
      render={({ onChange, value }) => (
        <AutoNumericInput
          value={value}
          placeholder={label}
          options={{
            decimalPlaces: decimalPlaces,
            decimalCharacter: ",",
            decimalCharacterAlternative: ".",
            digitGroupSeparator: " ",
            emptyInputBehavior: "null",
          }}
          onChange={(event, value)=> {
            onChange(value);
            if(props.onChange) {
              props.onChange(value);
            }
          }}
        />
      )}
      {...props}
    />
  )
}

export default DecimalInputField;
